<script setup>
import { useCurrentAccount, useCurrentUser, useExperimentA57, usePendo, usePermissions } from '@/api';
import { usePlanTrial } from '@/module/planTrial';
import { getToday, useI18n, useLocalStorage } from '@/util';
import AppShellTrialWelcomeBannerIcon from './AppShellTrialWelcomeBannerIcon.svg';

defineOptions({
  inheritAttrs: false,
});

const { t, formatDate } = useI18n();
const user = useCurrentUser();
const account = useCurrentAccount();
const { isExpA57Variation, EXP_A57_COMMON_METRICS: commonMetrics } = useExperimentA57();
const { isSiteOwner } = usePermissions();
const showTrialWelcomeBanner = useLocalStorage('showTrialWelcomeBanner', isExpA57Variation.value);
const { trackPendoEvent } = usePendo();
const { planTrial } = usePlanTrial();

const industryCategoriesIDToMessage = {
  34: t(
    'The {pricePlanName} plan is perfect for accounting firms that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  39: t(
    'The {pricePlanName} plan is perfect for business consultants that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  28: t(
    'The {pricePlanName} plan is perfect for consultancies that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  27: t(
    'The {pricePlanName} plan is perfect for creative agencies that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  18: t(
    'The {pricePlanName} plan is perfect for software development companies that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  23: t(
    'The {pricePlanName} plan is perfect for engineering firms that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  29: t(
    'The {pricePlanName} plan is perfect for financial services companies that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  36: t(
    'The {pricePlanName} plan is perfect for IT services companies that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  35: t(
    'The {pricePlanName} plan is perfect for legal services firms that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  2: t(
    'The {pricePlanName} plan is perfect for marketing agencies that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  15: t(
    'The {pricePlanName} plan is perfect for professional services companies that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
  22: t(
    'The {pricePlanName} plan is perfect for real estate companies that want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  ),
};

const defaultMessage = t(
  'The {pricePlanName} plan is perfect for companies and teams that want to track billable time, manage team capacity and report on project performance.',
  { pricePlanName: 'Grow' },
);

const today = getToday();

const isTrialWelcomeBannerVisible = computed(() => {
  return (
    showTrialWelcomeBanner.value &&
    isSiteOwner.value &&
    account.value.paymentStatus !== 'paid' &&
    !planTrial.value &&
    formatDate(account.value.dateSignedUp, 'medium') === formatDate(today, 'medium')
  );
});

const bannerTitle = computed(() => {
  return t('Welcome, {userName}! Your {trialDaysRemaining} day {pricePlanName} trial starts today!', {
    userName: user.value.firstName,
    trialDaysRemaining: account.value.trialDaysRemaining,
    pricePlanName: 'Grow',
  });
});

const bannerMessage = computed(() => {
  return industryCategoriesIDToMessage[account.value.industryCategoryId] || defaultMessage;
});

function trackEvent() {
  trackPendoEvent({
    eventName: 'GROW_PROMO_EVENT',
    commonMetrics,
    metadata: {
      event_action: 'welcome_banner_dismissed',
    },
  });
}

function onCloseTrialWelcomeBanner() {
  trackEvent();
  showTrialWelcomeBanner.value = false;
}
</script>

<template>
  <LscAlert
    v-if="isTrialWelcomeBannerVisible"
    v-bind="$attrs"
    layout="banner"
    closable
    variant="info-subdued"
    :title="bannerTitle"
    :message="bannerMessage"
    @close="onCloseTrialWelcomeBanner"
  >
    <template #media>
      <AppShellTrialWelcomeBannerIcon />
    </template>
  </LscAlert>
</template>
