<script setup>
import { useCategoriesV1Loader, usePendo, usePermissions } from '@/api';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import ProjectJumpToDrawerCategoriesTreeView from './ProjectJumpToDrawerCategoriesTreeView.vue';

const { t } = useI18n();
const { showLegacyModal } = useLegacyBridge();
const { canAddProjects } = usePermissions();
const { trackPendoEvent } = usePendo();

const state = useCategoriesV1Loader({
  type: 'project',
  count: Infinity,
  params: {
    status: 'Active',
  },
});

function openProjectModal() {
  const source = 'jump_to_sidebar_menu_categories';

  trackPendoEvent({
    eventName: 'PROJECTS_EVENT',
    commonMetrics: ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'],
    metadata: {
      page_type: 'projects',
      event_action: 'add_project_clicked',
      event_category: 'advanced_discovery_event',
      source,
    },
  });

  showLegacyModal({
    modalName: 'addOrEditProjectWizard',
    params: { source },
  });
}
</script>

<template>
  <div class="h-full overflow-y-auto">
    <WidgetLoadingState :state="state" :loadingMessage="t('Loading categories')">
      <template #blank>
        <LscEmptyState
          v-if="state.meta.value.totalUncategorizedItems === 0"
          size="sm"
          class="h-full"
          :title="t('No projects yet')"
          :message="t('Projects will be listed in categories here.')"
        >
          <template #actions>
            <LscButton
              v-if="canAddProjects"
              variant="primary"
              size="md"
              prependIcon="lsi-add"
              @click.stop="openProjectModal"
            >
              {{ t('Add project') }}
            </LscButton>
          </template>
        </LscEmptyState>
        <ProjectJumpToDrawerCategoriesTreeView v-else :state="state" />
      </template>
      <template #default>
        <ProjectJumpToDrawerCategoriesTreeView :state="state" />
      </template>
    </WidgetLoadingState>
  </div>
</template>
