<script setup>
import { DateTime } from 'luxon';
import { useMilestonesV3Loader } from '@/api';
import { useI18n } from '@/util';
import ReportTableCustomReport from '../../table/ReportTableCustomReport.vue';
import { reportBuilderSampleData, reportBuilderSampleDataTotalRow } from '../constants.js';
import { useReportBuilder } from '../useReportBuilder.js';
import { useReportBuilderFilters } from '../useReportBuilderFilters.js';

const { t } = useI18n();
const { currentReport, reportColumns, reportTypes } = useReportBuilder();
const { filterParams } = useReportBuilderFilters();
const count = 10;
const pageSize = 10;
const state = useMilestonesV3Loader({
  params: computed(() => ({
    projectCompanyIds: '',
    assignedToTeamIds: '',
    assignedToUserIds: filterParams.value.userIds,
    dueAfter: currentReport.value?.startAt.toFormat('yyyy-MM-dd'),
    dueBefore: currentReport.value?.endAt.toFormat('yyyy-MM-dd'),
    include:
      'projects,projects.companies,projects.categories,users,completedBy,createdBy,deletedBy,updatedBy,tags,tasklists,tasklisttaskstats',
    includeArchivedProjects: filterParams.value.includeArchivedProjects,
    includeCompleted: filterParams.value.includeCompletedMilestones,
    includeProgress: true,
    includeToday: true,
    includeUpdate: true,
    projectIds: '',
    status: filterParams.value.status,
  })),
  count,
  pageSize,
});

const { items: milestones } = state;

const milestonesWithSampleData = computed(() => {
  return milestones.value?.map((milestone, index) => ({
    ...milestone,
    ...reportBuilderSampleData[index],
    dueDate: DateTime.fromISO(milestone.deadline),
    completedDate: DateTime.fromISO(milestone.completedOn),
    originalDueDate: DateTime.fromISO(milestone.originalDueDate),
    lastUpdated: DateTime.fromISO(milestone.lastChangedOn),
    numberOfActiveTasks: (milestone.tasklists && milestone.tasklists[0].stats.active) || 0,
    taskLists: milestone.tasklists,
    milestone: milestone.name,
  }));
});

const reportTypeColumn = {
  id: 'milestoneName',
  name: t('Milestone'),
  enabled: true,
  draggable: false,
  width: 200,
  align: 'left',
};

const tableColumns = computed({
  get() {
    return [reportTypeColumn, ...reportColumns.value];
  },
  set(_columns) {
    reportColumns.value = _columns.filter((column) => column.id !== 'milestoneName');
  },
});
</script>

<template>
  <WidgetLoadingState :state="state" :blankMessage="reportTypes[currentReport.type].blankMessage">
    <template #defaultOrLoading>
      <ReportTableCustomReport
        v-model:columns="tableColumns"
        :items="milestonesWithSampleData"
        :summaryRowData="reportBuilderSampleDataTotalRow"
        :showTotalRow="currentReport.summary"
      />
    </template>
  </WidgetLoadingState>
</template>
