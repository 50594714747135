<script setup>
import { useExperimentA2430 } from '@/api';
import { useI18n } from '@/util';

defineProps({
  /**
   * The data identifier of the button.
   */
  dataIdentifier: {
    type: String,
    required: true,
  },
  /**
   * The size of the button.
   * @type {PropType<'sm' | 'md' >}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value),
  },
  /**
   * The name of the feature for the tour.
   */
  featureName: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const { trackGuideBadgeClicked } = useExperimentA2430();
</script>
<template>
  <LscButton
    variant="plain-primary"
    :size="size"
    :data-identifier="dataIdentifier"
    prependIcon="lsi-play"
    @click="trackGuideBadgeClicked(dataIdentifier)"
  >
    {{ t('Take a tour of {featureName}', { featureName }) }}
  </LscButton>
</template>
