<script setup>
const props = defineProps({
  /**
   * The variant of the badge.
   * @type {PropType<'highlight' | 'neutral' | 'surface-emphasis' | 'contrast'>}
   */
  variant: {
    type: String,
    default: 'neutral',
    validator: (text) => ['highlight', 'neutral', 'surface-emphasis', 'contrast'].includes(text),
  },
  /**
   * The count of the badge.
   */
  count: {
    type: Number,
    default: 0,
  },
});

const badgeVariantStyleConfig = tv({
  base: 'inline-flex h-5 min-w-5 items-center justify-center rounded-full px-1 text-button-2',
  variants: {
    variant: {
      highlight: 'bg-surface-accent text-default',
      neutral: 'bg-surface-selected text-subtle',
      'surface-emphasis': 'bg-surface-emphasis-selected text-primary',
      contrast: 'bg-default text-subtle',
    },
  },
});

const classes = computed(() => badgeVariantStyleConfig(props));
const truncatedCount = computed(() => (props.count > 99 ? '99+' : props.count));
</script>

<template>
  <div :class="classes">
    <slot>{{ truncatedCount }}</slot>
  </div>
</template>
