<script setup>
import useVuelidate from '@vuelidate/core';
import { useI18n, useValidators } from '@/util';
import { FeedbackButton } from '@/module/feedback';
import { useReportTracking } from '../useReportTracking';
import ReportBuilderOptions from './options/ReportBuilderOptions.vue';
import ReportBuilderPreview from './preview/ReportBuilderPreview.vue';
import { provideReportBuilder } from './useReportBuilder.js';

const props = defineProps({
  report: {
    type: Object,
    default: () => ({}),
  },
  copyingReport: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['updated', 'close', 'handleCustomReportTrack']);

const { t } = useI18n();
const toast = useLsToast();
const { trackReportsEvent } = useReportTracking();
const { saveReport, hasColumns, currentReport } = provideReportBuilder(toRef(props, 'report'));
const { helpers, required } = useValidators();

currentReport.value.name = props.copyingReport ? `${props.report.name} duplicate` : props.report.name;

const duplicateNameError = shallowRef(false);

const rules = {
  currentReport: {
    name: {
      required: helpers.withMessage(t('Report name is required'), required),
      unique: helpers.withMessage(t('Report name already exists'), () => !duplicateNameError.value),
    },
  },
  hasColumns: {
    isTrue: helpers.withMessage(t('At least one column is required'), (value) => value === true),
  },
};

const v$ = useVuelidate(rules, { currentReport, hasColumns });

const isEditing = computed(() => Boolean(props.report.id) && !props.copyingReport);
const isSaving = shallowRef(false);

const actionText = computed(() => {
  return isEditing.value ? t('Update report') : t('Save report');
});

async function saveOrEditReport() {
  if (!isEditing.value) {
    emit('handleCustomReportTrack', 'custom_report_modal', 'save_custom_report_clicked', 'advanced_discovery_event');
  }

  v$.value.$touch();

  if (v$.value.$invalid) {
    return;
  }

  if (isSaving.value) {
    return;
  }

  isSaving.value = true;

  try {
    duplicateNameError.value = false;
    const response = await saveReport(props.copyingReport);

    if (response === false) {
      isSaving.value = false;
      return;
    }

    setTimeout(() => {
      if (isEditing.value) {
        toast.success(t('Regenerating custom report'));
        emit('updated');
      }

      emit('close');
      isSaving.value = false;
    }, 2000);
    trackReportsEvent('reports', 'custom_report_created', 'activation', 'custom_reports');
  } catch (error) {
    if (error.status === 409) {
      duplicateNameError.value = true;
      v$.value.$touch();
    }
    isSaving.value = false;
  }
}

function handleClose() {
  emit('close');
}

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}
</script>

<template>
  <LscDialogCard size="full" fullScreen :title="t('Custom report builder')" @close="handleClose">
    <template #prependHeaderRight>
      <FeedbackButton data-identifier="feedback-custom-reports-builder" />
    </template>
    <template #content>
      <section class="flex h-full w-full items-stretch overflow-hidden text-body-1">
        <ReportBuilderOptions
          class="border-r border-t border-separator"
          :validation="v$"
          @handleCustomReportTrack="handleCustomReportTrack"
        />
        <div class="flex w-full flex-1 flex-col overflow-hidden border-t border-separator bg-surface-default">
          <ReportBuilderPreview />

          <div class="flex shrink-0 self-end pb-6 pr-6">
            <LscButton :loading="isSaving" variant="primary" size="lg" @click="saveOrEditReport">
              {{ actionText }}
            </LscButton>
          </div>
        </div>
      </section>
    </template>
  </LscDialogCard>
</template>
