<script setup>
import useVuelidate from '@vuelidate/core';
import { useI18n, useValidators } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_TABLE,
  SAMPLE_USER_TASKS_DATA,
  STEP_ADD_CLIENT,
  STEP_ADD_TASKS,
  STEP_BOARD_COLUMNS,
  STEP_PROJECT_NAME,
  STEP_PROJECT_TYPE_SELECTION,
} from '../constants';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const {
  [STEP_ADD_TASKS]: stateData,
  [STEP_PROJECT_NAME]: projectNameState,
  [STEP_PROJECT_TYPE_SELECTION]: projectTypeSelectionState,
  [STEP_BOARD_COLUMNS]: boardColumnsState,
  [STEP_ADD_CLIENT]: addClientState,
} = props.state;

const { t } = useI18n();
const { helpers } = useValidators();

const localTaskListNames = ref(stateData?.tasksList ?? []);
const itemRefs = ref([]);

const activeView = shallowRef(
  stateData?.selectedView ??
    projectNameState?.selectedView ??
    projectTypeSelectionState?.selectedView ??
    PREVIEW_TAB_TABLE,
);

const projectName = (projectNameState?.name || projectTypeSelectionState.name) ?? '';
const hasContent = stateData?.tasksList.tasklists ?? localTaskListNames.value.some((task) => task !== '');
const boardColumnsFromState = boardColumnsState?.columns;
const boardColumnsFromTasksDataTemplate = SAMPLE_USER_TASKS_DATA.boardColumns.map((column) => column.name);
const placeholderTasks = [t('e.g. Write content'), t('e.g. Create mockups'), t('e.g. Create prototypes')];

const rules = {
  tasksList: {
    required: helpers.withMessage(t('Please add a task'), (value) => {
      return value.length > 0 && value.some((task) => task !== '');
    }),
  },
};

const v$ = useVuelidate(rules, { tasksList: localTaskListNames }, { $autoDirty: false });

const localAndSampleData = computed(() => {
  const columns = boardColumnsFromState ?? boardColumnsFromTasksDataTemplate;
  const combinedTasks = SAMPLE_USER_TASKS_DATA.tasklists.map((tasklist) => ({
    ...tasklist,
    tasks: tasklist.tasks.map((task, taskIndex) => ({
      ...task,
      name: localTaskListNames.value[taskIndex] ?? '',
    })),
  }));

  const columnsDisplayed = columns.map((columnName, index) => ({
    ...SAMPLE_USER_TASKS_DATA.boardColumns[index],
    name: columnName,
  }));

  return {
    boardColumns: columnsDisplayed,
    tasklists: combinedTasks,
  };
});

function nextStep() {
  v$.value.$touch();
  if (!v$.value.$error) {
    emit('nextStep', localTaskListNames.value.toString() !== stateData?.tasksList.toString(), {
      name: projectName,
      tasksList: localTaskListNames.value,
      tasklistsPreviewData: localAndSampleData.value,
      selectedView: activeView.value,
      numberOfTasksAdded: localTaskListNames.value.length,
    });
  }
}

function handleInputFocus(index) {
  return !hasContent && index === 0;
}

function handleActiveTabChanged(tab) {
  activeView.value = tab;
}

function getErrorMessage(index) {
  return index === 0 ? v$.value.tasksList?.$errors.map((error) => error.$message) : '';
}

onMounted(() => {
  if (!hasContent) {
    itemRefs.value[0].focus();
  }
});
</script>
<template>
  <OnboardingWizardCommonStep
    :title="t('Add a few tasks')"
    :description="t('What are the first few things that you have to get done for this project?')"
  >
    <VTextField
      v-for="(placeholder, index) in placeholderTasks"
      :key="index"
      ref="itemRefs"
      v-model.trim="localTaskListNames[index]"
      :label="t('Add task name')"
      :placeholder="placeholder"
      :maxLength="255"
      :autofocus="handleInputFocus(index)"
      :errorMessages="getErrorMessage(index)"
      class="mb-3"
    />

    <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :clientCompanyName="addClientState?.name"
        :preselectedTab="activeView"
        :tasksData="localAndSampleData"
        :tasksDataFromTemplate="localAndSampleData.length > 0"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST]"
        :stepId="STEP_ADD_TASKS"
        @activeTabChanged="handleActiveTabChanged"
      />
    </template>
  </OnboardingWizardCommonStep>
</template>
