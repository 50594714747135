<script setup>
import useVuelidate from '@vuelidate/core';
import { useI18n, useValidators } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  ONBOARDING_GOAL_GETTING_STARTED,
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_TABLE,
  STEP_ADD_CLIENT_GOAL,
  STEP_PROJECT_NAME,
  STEP_PROJECT_TYPE_SELECTION,
} from '../constants';
import { useOnboardingWizard } from '../useOnboardingWizard';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep', 'previousStep', 'close']);

/** use stateData to check if there's old values for this state and if they have changed */
const {
  [STEP_PROJECT_NAME]: stateData,
  [STEP_PROJECT_TYPE_SELECTION]: projectTypeSelectionState,
  [STEP_ADD_CLIENT_GOAL]: clientGoalState,
} = props.state;

const { t } = useI18n();
const { required } = useValidators();
const { currentGoal } = useOnboardingWizard();

const inputEl = shallowRef(null);
const selectedTemplate = shallowRef((projectTypeSelectionState?.selectedTemplate || stateData?.selectedTemplate) ?? {});
const tasksData = shallowRef(selectedTemplate?.value?.content ?? {});
const projectName = shallowRef((selectedTemplate?.value?.name || stateData?.name) ?? '');
const activeView = shallowRef(projectTypeSelectionState?.selectedView ?? PREVIEW_TAB_TABLE);
const clientCompanyName = shallowRef(clientGoalState?.newClientName || clientGoalState?.selectedClientName || '');

const tasksDataFromTemplate = !!projectTypeSelectionState?.selectedTemplate;

const titleString =
  currentGoal.value === ONBOARDING_GOAL_GETTING_STARTED ? t('Set up your first project') : t('Set up your project');

const rules = {
  projectName: {
    required,
  },
};

const v$ = useVuelidate(rules, { projectName }, { $autoDirty: false });

function handleActiveTabChanged(tab) {
  activeView.value = tab;
}

function nextStep() {
  v$.value.$touch();
  if (!v$.value.$error) {
    emit('nextStep', projectName.value !== stateData?.name, {
      name: projectName.value,
      selectedTemplate,
      selectedView: activeView.value,
    });
  }
}

function previousStep() {
  emit('previousStep');
}

function skipStep() {
  // no action
}

onMounted(() => {
  if (projectName.value === '') {
    inputEl.value.focus();
  }
});
</script>

<template>
  <OnboardingWizardCommonStep :title="titleString" :description="t('Give your project a name')">
    <VTextField
      ref="inputEl"
      v-model.trim="projectName"
      :label="t('Project name')"
      :placeholder="t('e.g. Marketing Campaign')"
      :errorMessages="v$.projectName.$errors.map((error) => error.$message)"
      :maxLength="255"
      :autoFocus="true"
      class="my-2"
    />

    <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :clientCompanyName="clientCompanyName"
        :preselectedTab="activeView"
        :tasksData="tasksData"
        :tasksDataFromTemplate="tasksDataFromTemplate"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST]"
        :stepId="STEP_PROJECT_NAME"
        @activeTabChanged="handleActiveTabChanged"
      />
    </template>
  </OnboardingWizardCommonStep>

  <slot
    name="footerButtons"
    :nextButtonText="nextButtonText"
    :nextStep="nextStep"
    :skipButtonText="t('Skip')"
    :showSkipButton="showSkipButton"
    :skipStep="skipStep"
    :previousStep="previousStep"
  />
</template>
