<!-- eslint-disable no-console -->
<script setup>
import { useExperimentA2301, useExperimentA41, usePreferences } from '@/api';
import { useI18n } from '@/util';
import LoadingIllustration from '../assets/loading-illustration.svg';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import {
  BILLING_TYPE_FIXED_FEE,
  BILLING_TYPE_RETAINER,
  BILLING_TYPE_STANDARD,
  ONBOARDING_GOAL_ADD_BUDGET,
  ONBOARDING_GOAL_ADD_TEAM_AND_SET_RATES,
  ONBOARDING_GOAL_CREATE_PROJECT,
  ONBOARDING_GOAL_GETTING_STARTED,
  ONBOARDING_GOAL_SAVE_AS_TEMPLATE,
  STEP_ADD_CLIENT_GOAL,
  STEP_ADD_INTEGRATIONS,
  STEP_BILLING_TYPE,
  STEP_CLIENT_PROJECT,
  STEP_PROJECT_NAME,
  STEP_SAVE_AS_TEMPLATE,
  STEP_SET_BUDGET,
} from '../constants';
import { useOnboardingWizard } from '../useOnboardingWizard';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';
import { useOnboardingWizardIntegrations } from './useOnboardingWizardIntegrations';
import { useOnboardingWizardLastStep } from './useOnboardingWizardLastStep';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n();
const { onboardingProjectId } = usePreferences();
const {
  saveProductOutcomes,
  createProject,
  createProjectGoalBased,
  inviteUsersToProject,
  prioritizeFinanceTabForProject,
  setAccountCurrency,
  setBudget,
  setTeamRates,
  redirectUserAfterCompletion,
  createDummyData,
  saveAsTemplate,
} = useOnboardingWizardLastStep();
const { onboardingSelectedIntegrations } = useOnboardingWizardIntegrations();
const { isExpA2301Variation } = useExperimentA2301();
const { isExpA41Variation1 } = useExperimentA41();
const { trackOnboardingCompleted } = useOnboardingWizardTracking();
const { currentGoal, currentProject } = useOnboardingWizard();

/**
 * This "Step" is handling all the processing of creating the project, inviting users, etc etc
 */

function addIntegrationsStep() {
  if (!isExpA41Variation1.value || !props.state[STEP_ADD_INTEGRATIONS]) {
    return;
  }
  const { selectedIntegrations } = props.state[STEP_ADD_INTEGRATIONS] ?? [];
  onboardingSelectedIntegrations.value = selectedIntegrations;
}

async function processFinanceOnboardingSteps(projectId, invitedUsers) {
  if (!isExpA2301Variation.value) {
    return;
  }

  await prioritizeFinanceTabForProject(projectId);

  if (!props.state[STEP_CLIENT_PROJECT]?.clientProject) {
    return;
  }

  if (props.state[STEP_BILLING_TYPE]?.skipped) {
    return;
  }

  await setAccountCurrency(props.state);
  await setBudget(props.state, currentProject?.value);
  await setTeamRates(props.state, invitedUsers);
}

async function createProjectAndInviteUsers() {
  const projectId = await createProject(props.state).catch((error) => {
    if (import.meta.env.DEV) {
      // TODO: remove logs after we go to real production with this
      console.error('createProject', error);
    }
  });

  let invitedUsers;
  if (projectId) {
    onboardingProjectId.value = projectId;

    invitedUsers = await inviteUsersToProject(props.state, projectId).then((promises) => {
      if (import.meta.env.DEV) {
        promises
          .filter((promise) => promise.status === 'rejected')
          .forEach((promise) => console.error('inviteUsersToProject', promise.reason));
      }

      return promises
        .map((promise, i) => {
          const userId = promise?.value?.data?.user?.id;
          return userId && { id: userId, position: i + 1 };
        })
        .filter(Boolean);
    });
  }

  return {
    projectId: Number(projectId),
    invitedUsers: invitedUsers || [],
  };
}

async function saveTemplate(projectId) {
  if (!projectId || props.state[STEP_SAVE_AS_TEMPLATE]?.skipped) {
    return;
  }

  await saveAsTemplate(projectId, props.state[STEP_SAVE_AS_TEMPLATE]);
}

// put all the processing in here in the order we need it, call functions that deal with whatever steps
async function gettingStartedLastStep() {
  await saveProductOutcomes(props.state);

  // Exp A41 - Add Integrations
  addIntegrationsStep();

  // create project and invite users
  const { projectId, invitedUsers } = await createProjectAndInviteUsers();

  // Finance onboarding - Experiment A-23-01 - experiment flag checked inside function
  await processFinanceOnboardingSteps(projectId, invitedUsers);

  // Dummy Data - Experiment A27 - experiment flags checked inside function
  await createDummyData();

  // track onboarding completion
  trackOnboardingCompleted();

  // redirect user to correct page depending on who they are
  redirectUserAfterCompletion(projectId);
}

async function addBudgetLastStep() {
  try {
    await setBudget(props.state, currentProject?.value);
  } finally {
    const billingType = {
      [BILLING_TYPE_STANDARD]: 'time_and_materials',
      [BILLING_TYPE_RETAINER]: 'retainer',
      [BILLING_TYPE_FIXED_FEE]: 'fixed_fee',
    };
    const payload = {};
    if (props.state.billingType.type === BILLING_TYPE_RETAINER) {
      const { type, repeatValue, timelogType, addUnspentHours, subtractOverspentHours } = props.state[STEP_SET_BUDGET];
      payload.budget_type = type;
      payload.budget_period_length = repeatValue;
      payload.budget_based_on = `${timelogType}_time`;
      payload.budget_add_unspent_hours = addUnspentHours;
      payload.budget_subtract_overspent_hours = subtractOverspentHours;
    } else if (props.state.billingType.type === BILLING_TYPE_FIXED_FEE) {
      const { profitMargin, amount } = props.state[STEP_SET_BUDGET];
      const profitMarginFloat = parseFloat(profitMargin);
      const amountInt = parseInt(amount, 10);
      const profitValue = (amountInt / 100) * profitMarginFloat;
      payload.budget_profit_margin = profitMargin;
      payload.budget_target_profit = profitValue.toFixed(2);
      payload.budget_target_costs = (amountInt - profitValue).toFixed(2);
    } else if (props.state.billingType.type === BILLING_TYPE_STANDARD) {
      const { type, repeatValue, timelogType } = props.state[STEP_SET_BUDGET];
      payload.budget_type = type;
      payload.budget_repeats = repeatValue;
      payload.budget_based_on = `${timelogType}_time`;
    }
    // track onboarding completion
    trackOnboardingCompleted({
      page_type: 'goal_based_onboarding_complete',
      event_action: 'goal_completed',
      goal_step: 'budget_details',
      billing_type: billingType[props.state.billingType.type],
      project_id: currentProject?.value?.id,
      ...payload,
    });
    emit('close', true);
  }
}

async function saveAsTemplateLastStep() {
  try {
    await saveTemplate(currentProject?.value?.id);
  } finally {
    // track onboarding completion
    trackOnboardingCompleted({
      page_type: 'goal_based_onboarding_complete',
      event_action: 'goal_completed',
      project_id: currentProject?.value?.id,
    });
    emit('close', true); // send "force" boolean to actually close modal
  }
}

async function createProjectLastStep() {
  const { clientProject, newClientName, selectedClientId } = props.state[STEP_ADD_CLIENT_GOAL];
  const { name: projectName } = props.state[STEP_PROJECT_NAME];

  try {
    const { id } = await createProjectGoalBased({ clientProject, newClientName, selectedClientId, projectName });
    if (!onboardingProjectId.value) {
      onboardingProjectId.value = String(id);
    }
  } finally {
    // track onboarding completion
    trackOnboardingCompleted({
      page_type: 'goal_based_onboarding_complete',
      event_action: 'goal_completed',
      project_type: clientProject ? 'client' : 'internal',
    });
    emit('close', true);
  }
}

async function addUsersToProjectAndSetRatesLastStep() {
  const { inviteUsersAndSetTeamRates } = props.state;
  const { invitedUsers, defaultRate, numberOfNewBillableRates, numberOfNewCostRates, numberOfInvitedUsers } =
    inviteUsersAndSetTeamRates;

  try {
    await setTeamRates(props.state, invitedUsers);
  } finally {
    // track onboarding completion
    trackOnboardingCompleted({
      page_type: 'goal_based_onboarding_complete',
      event_action: 'goal_completed',
      number_users_in_project: numberOfInvitedUsers,
      default_billable_rate_set: defaultRate !== undefined,
      number_billable_rates_set: numberOfNewBillableRates,
      number_costs_rates_set: numberOfNewCostRates,
      project_id: currentProject?.value?.id,
    });
    emit('close', true);
  }
}

async function processStepDataSaving() {
  switch (currentGoal.value) {
    case ONBOARDING_GOAL_GETTING_STARTED:
      gettingStartedLastStep();
      break;
    case ONBOARDING_GOAL_ADD_BUDGET:
      addBudgetLastStep();
      break;
    case ONBOARDING_GOAL_ADD_TEAM_AND_SET_RATES:
      addUsersToProjectAndSetRatesLastStep();
      break;
    case ONBOARDING_GOAL_SAVE_AS_TEMPLATE:
      saveAsTemplateLastStep();
      break;
    case ONBOARDING_GOAL_CREATE_PROJECT:
      createProjectLastStep();
      break;
    default:
  }
}

processStepDataSaving();
</script>
<template>
  <OnboardingWizardCommonStep>
    <div class="flex h-[--onboarding-wizard-preview-container-height] flex-col items-center justify-center">
      <LoadingIllustration />
      <div class="mt-5 flex flex-row gap-3 align-middle">
        <VProgressCircular indeterminate color="primary" :width="3" class="size-6" />
        <span class="text-subtitle-2 font-semibold text-default">
          {{ t("Hold tight, we're setting up your project...") }}
        </span>
      </div>
    </div>
  </OnboardingWizardCommonStep>
</template>
