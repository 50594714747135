<script setup>
import {
  BUDGET_EXPENSES_FEATURE_KEY,
  BUDGETS_FEATURE_KEY,
  FINANCIAL_BUDGETS_FEATURE_KEY,
  RETAINER_BUDGETS_FEATURE_KEY,
  SCHEDULED_REPORTS_FEATURE_KEY,
  TASKLIST_BUDGETS_FEATURE_KEY,
  TIME_BUDGETS_FEATURE_KEY,
  useExperimentE21a,
  useFeatureTrialActions,
  usePricePlan,
} from '@/api';
import { useI18n } from '@/util';
import { FinanceBudgetsIcon, FinanceBudgetsPreview } from '@/module/featureLimit';
import ScheduledReportsIcon from './assets/scheduled-reports-icon.svg';
import ScheduledReportsImage from './assets/scheduled-reports-image.svg';
import { useFeatureTrial } from './useFeatureTrial';

const props = defineProps({
  featureKey: {
    type: String,
    required: true,
  },
  featureName: {
    type: String,
    required: true,
  },
  originFeature: {
    type: String,
    default: '',
  },
  learnMoreLink: {
    type: String,
    default: '',
  },
  showLearnMoreLink: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n();
const {
  trialDaysRemaining,
  isTrialExpired,
  totalTrialDays,
  contactSales,
  reasonForFeatureAccess,
  featureLimit,
  goToCheckoutOrContactCS,
  hasTrialEverStarted,
} = useFeatureTrial(props.featureKey);
const { startFeatureTrial } = useFeatureTrialActions();
const { pricePlanName } = usePricePlan();
const toast = useLsToast();
const { trackEventE21a, trackFeatureAccessEvent } = useExperimentE21a();
const loading = ref(false);

const featureDetails = {
  [SCHEDULED_REPORTS_FEATURE_KEY]: {
    grow: {
      title: t('Scheduled reports'),
      subtitle: t('Available on the {plan} plan', { plan: 'Scale' }),
      preTrialText: t(
        "It seems you've come across a premium feature not included in your plan. Not to worry, you can trial this feature for {numDays} days, absolutely free. Explore its benefits and see how it automates your reporting. After the trial, if you decide not to upgrade, your feature access will be removed. Start the trial now and take your reporting automations to the next level!",
        { numDays: totalTrialDays.value },
      ),
      postTrialText: t(
        "Your feature trial has ended, but that's ok, to keep your increased Scheduled Reports limit, simply upgrade your plan. Unlock this enhanced time saving feature and increase visibility and alignment across your team. Upgrade now and take your report automations to the next level!",
      ),
      toastMessage: t('You now have access for {numDays} days. This page will reload to apply the latest changes.', {
        numDays: totalTrialDays.value,
      }),

      ctaAction: () => startTrialOrContactSales(),
      ctaText: !hasTrialEverStarted.value ? t('Try now for free') : t('Contact us to upgrade'),
    },
  },
  [TASKLIST_BUDGETS_FEATURE_KEY]: {
    deliver: {
      title: t('Task List Budgets'),
      subtitle: t('Available on the {grow} & {scale} plans', { grow: 'Grow', scale: 'Scale' }),
      postTrialText: t(
        'Your feature trial has ended, but that’s ok, to unlock Task List Budgets, simply upgrade your plan. Unlock enhanced budgeting so you can monitor progress at every stage of your projects. Upgrade now and take your project analysis to the next level!',
      ),
      toastMessage: t(
        'You now have access to Task List Budgets for {numDays} days. This page will reload to apply the latest changes.',
        {
          numDays: totalTrialDays.value,
        },
      ),
      featureLimit: 5,
      ctaAction: () => goToCheckoutOrContactCS(),
      ctaText: t('Compare plans'),
    },
    grow: {
      title: isTrialExpired.value ? t('Task List Budgets') : t("You've hit your Task List Budgets limit"),
      subtitle: t('Available on the {plan} plan', { plan: 'Scale' }),
      postTrialText: t(
        'Your feature trial has ended, but that’s ok, to add unlimited Task List Budgets, simply upgrade your plan. Unlock enhanced budgeting so you can monitor progress at every stage of your projects. Upgrade now and take your project analysis to the next level!',
      ),
      toastMessage: t(
        'You now have unlimited Task List Budgets for {numDays} days. This page will reload to apply the latest changes.',
        {
          numDays: totalTrialDays.value,
        },
      ),

      ctaAction: () => startTrialOrContactSales(),
      ctaText: t('Contact us to upgrade'),
    },
  },
  [BUDGET_EXPENSES_FEATURE_KEY]: {
    deliver: {
      title: t('Project Budget Expenses'),
      subtitle: t('Available on the {grow} & {scale} plans', { grow: 'Grow', scale: 'Scale' }),
      preTrialText: t(
        'Unlock a free trial of Project Budget Expenses for {numDays} days so you can monitor budget progress at every stage of your project spend.',
        { numDays: totalTrialDays.value },
      ),
      postTrialText: t(
        'Your feature trial has ended, but that’s ok, to keep using Project Budget Expenses, simply upgrade your plan. Track expenses against your budget and get an accurate view of your budget availability. Upgrade now and take your project budgets to the next level!',
      ),
      toastMessage: t(
        'You now have access to Project Budget Expenses for {numDays} days. This page will reload to apply the latest changes.',
        {
          numDays: totalTrialDays.value,
        },
      ),
      ctaAction: () => goToCheckoutOrContactCS(),
      ctaText: t('Compare plans'),
    },
  },
};

const currentFeatureDetails = computed(
  () => featureDetails?.[props.featureKey.toLowerCase()]?.[pricePlanName.value.toLowerCase()],
);

const expiredText = computed(() => {
  const absTrialDaysRemaining = Math.abs(trialDaysRemaining.value);
  if (absTrialDaysRemaining > 1) {
    return t('Your feature trial ended {numDays} days ago', { numDays: absTrialDaysRemaining });
  }
  if (absTrialDaysRemaining === 1) {
    return t('Your feature trial ended yesterday');
  }
  return t('Your feature trial ended today');
});

const infoText = computed(() => {
  if (isTrialExpired.value) {
    return currentFeatureDetails.value.postTrialText;
  }
  return currentFeatureDetails.value.preTrialText;
});

const isBudgets = computed(() =>
  [
    FINANCIAL_BUDGETS_FEATURE_KEY,
    TIME_BUDGETS_FEATURE_KEY,
    BUDGETS_FEATURE_KEY,
    TASKLIST_BUDGETS_FEATURE_KEY,
    RETAINER_BUDGETS_FEATURE_KEY,
    BUDGET_EXPENSES_FEATURE_KEY,
  ].includes(props.featureKey.toLowerCase()),
);
const isScheduledReports = computed(() => props.featureKey.toLowerCase() === SCHEDULED_REPORTS_FEATURE_KEY);

function openLearnMoreLink() {
  trackEventE21a({
    eventAction: 'learn_more_clicked',
    meta: {
      page_type: `${props.featureKey}_feature_trial_modal`,
      report: props.originFeature,
      feature: props.featureKey,
    },
  });
  window.open(props.learnMoreLink, '_blank');
}

async function startTrialOrContactSales() {
  loading.value = true;
  if (isTrialExpired.value) {
    trackEventE21a({
      eventAction: 'contact_us_to_upgrade_clicked',
      meta: {
        page_type: `${props.featureKey}_feature_trial_modal`,
        report: props.originFeature,
        feature: props.featureKey,
      },
    });
    await contactSales();
    loading.value = false;
    return;
  }

  trackEventE21a({
    eventAction: 'try_now_for_free_clicked',
    meta: {
      page_type: `${props.featureKey}_feature_trial_modal`,
      report: props.originFeature,
      feature: props.featureKey,
      feature_limit: featureLimit.value,
      reason_for_feature_access: reasonForFeatureAccess.value,
    },
  });
  trackFeatureAccessEvent({
    eventAction: 'try_now_for_free_clicked',
    meta: {
      page_type: `${props.featureKey}_feature_trial_modal`,
      report: props.originFeature,
      feature: props.featureKey,
      feature_limit: featureLimit.value,
      reason_for_feature_access: reasonForFeatureAccess.value,
    },
  });

  await startFeatureTrial({
    name: props.featureKey,
    numDays: totalTrialDays.value,
    value: currentFeatureDetails.value?.featureLimit ? currentFeatureDetails.value.featureLimit : -1,
  }).then(() => {
    toast.success({
      title: currentFeatureDetails.value.toastMessage,
      onRemove: () => window.location.reload(),
    });
  });

  loading.value = false;
}

function close() {
  trackEventE21a({
    eventAction: 'close_clicked',
    meta: {
      page_type: `${props.featureKey}_feature_trial_modal`,
      report: props.originFeature,
      feature: props.featureKey,
    },
  });
  emit('close');
}

onMounted(() =>
  trackEventE21a({
    eventAction: `${props.featureKey}_feature_trial_modal_viewed`,
    meta: {
      page_type: `${props.featureKey}_feature_trial_modal`,
      report: props.originFeature,
    },
  }),
);
</script>
<template>
  <WidgetDialogPanel size="lg" @close="close">
    <section class="mx-5 mb-10 flex max-h-full max-w-full flex-row items-center justify-between gap-11">
      <div class="leading-relaxed flex w-80 shrink-0 flex-col items-center gap-4 text-center">
        <ScheduledReportsIcon v-if="isScheduledReports" />
        <FinanceBudgetsIcon v-if="isBudgets" />

        <div>
          <h2 class="text-h2">
            {{ currentFeatureDetails.title }}
          </h2>
          <h2 v-if="isTrialExpired" class="mt-1 text-subtitle-1 text-subtle">
            {{ currentFeatureDetails.subtitle }}
          </h2>
          <h2 v-if="isTrialExpired" class="mt-1 text-subtitle-2 text-accent">
            {{ expiredText }}
          </h2>
        </div>
        {{ infoText }}
        <div class="flex flex-col items-center gap-4">
          <LscButton variant="primary" @click="currentFeatureDetails.ctaAction">
            {{ currentFeatureDetails.ctaText }}
          </LscButton>
          <LscButton v-if="showLearnMoreLink" variant="tertiary" @click="openLearnMoreLink">
            {{ t('Learn more') }}
          </LscButton>
        </div>
      </div>

      <ScheduledReportsImage v-if="isScheduledReports" />
      <FinanceBudgetsPreview v-if="isBudgets" />
    </section>
  </WidgetDialogPanel>
</template>
