<script setup>
import { getInitials, useI18n } from '@/util';
import { LscAvatarSizes, LscAvatarVariants } from './constants';

const props = defineProps({
  /**
   * The source of the image.
   */
  src: {
    type: String,
    default: '',
  },
  /**
   * The background color of the avatar.
   */
  bgColor: {
    type: String,
    default: undefined,
  },
  /**
   * The name of the entity the avatar represents. Will be used as alt text if no alt text is provided.
   */
  name: {
    type: String,
    default: undefined,
  },
  /**
   * The alt text for the image.
   */
  alt: {
    type: String,
    default: undefined,
  },
  /**
   * The icon to display if no image is provided.
   */
  icon: {
    type: String,
    default: undefined,
  },
  /**
   * The color of the icon.
   */
  iconColor: {
    type: String,
    default: undefined,
  },
  /**
   * The size of the avatar.
   * @type {PropType<typeof LscAvatarSizes[number]>}
   */
  size: {
    type: String,
    default: 'sm',
    validator: (v) => LscAvatarSizes.includes(v),
  },
  /**
   * The variant of the avatar.
   * @type {PropType<typeof LscAvatarVariants[number]>}
   */
  variant: {
    type: String,
    default: 'default',
    validator: (v) => LscAvatarVariants.includes(v),
  },
  /**
   * Whether the avatar is clearable.
   */
  clearable: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether to show the tooltip.
   */
  showTooltip: {
    type: Boolean,
    default: true,
  },
  /**
   * A function to generate the tooltip text for the removing the avatar.
   * @type {PropType<(name:string) => string>}
   */
  removeTooltipFunction: {
    type: Function,
    default: undefined,
  },
});

const emit = defineEmits(['remove']);

const { t } = useI18n();
const showFallback = shallowRef(!props.src);

const computedRemoveTooltip = computed(() => {
  if (props.removeTooltipFunction) {
    return props.removeTooltipFunction;
  }
  return (user) => t('Remove {name}', { name: user.name });
});

watchEffect(() => {
  showFallback.value = !props.src;
});

const avatarVariantStyleConfig = tv({
  base: [
    'group/LscAvatar flex items-center justify-center overflow-hidden rounded-full no-underline transition-all',
    'select-none bg-[--bg-color] leading-1 text-[color:--icon-color] !no-underline',
  ],
  slots: {
    icon: '',
    image: 'h-full w-full object-cover text-[transparent]',
    clearableButton:
      'pointer-events-none absolute inset-0 flex items-center justify-center bg-surface-dark bg-opacity-40 opacity-0 transition-opacity',
  },
  variants: {
    size: {
      xxs: {
        base: 'size-[--lsds-c-avatar-size-xxs] text-[length:--lsds-c-avatar-text-size-xxs]',
      },
      xs: {
        base: 'size-[--lsds-c-avatar-size-xs] text-[length:--lsds-c-avatar-text-size-xs]',
      },
      sm: {
        base: 'size-[--lsds-c-avatar-size-sm] text-[length:--lsds-c-avatar-text-size-sm]',
      },
      md: {
        base: 'size-[--lsds-c-avatar-size-md] text-[length:--lsds-c-avatar-text-size-md]',
      },
      lg: {
        base: 'size-[--lsds-c-avatar-size-lg] text-[length:--lsds-c-avatar-text-size-lg]',
      },
      xl: {
        base: 'size-[--lsds-c-avatar-size-xl] text-[length:--lsds-c-avatar-text-size-xl]',
      },
      xxl: {
        base: 'size-[--lsds-c-avatar-size-xxl] text-[length:--lsds-c-avatar-text-size-xxl]',
      },
    },
    clearable: {
      true: {
        base: 'relative',
        clearableButton: 'pointer-events-auto group-hover/LscAvatar:opacity-100',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'on-surface',
      showFallback: true,
      class: {
        base: 'border border-bold',
      },
    },
  ],
});

const type = computed(() => {
  if (showFallback.value) {
    if (props.icon || !props.name) {
      return 'icon';
    }
    return 'text';
  }
  return 'image';
});

const classes = computed(() =>
  avatarVariantStyleConfig({
    size: props.size,
    variant: props.variant,
    type: type.value,
    clearable: props.clearable,
    showFallback: showFallback.value,
  }),
);

const computedIcon = computed(() => {
  if (props.icon) {
    return props.icon;
  }
  if (!props.name) {
    return 'lsi-assignee';
  }
  return undefined;
});

const computedBgColor = computed(() => {
  if (props.bgColor) {
    return props.bgColor;
  }
  if (type.value === 'text') {
    return 'var(--lsds-a-color-decorative-2)';
  }
  return 'var(--lsds-a-color-surface-default)';
});

const computedIconColor = computed(() => {
  if (props.iconColor) {
    return props.iconColor;
  }
  if (type.value === 'text') {
    return 'var(--lsds-a-color-icon-default)';
  }
  return 'var(--lsds-a-color-icon-subtle)';
});

const tooltip = computed(() => {
  if (props.clearable) {
    computedRemoveTooltip.value(props.name);
  }
  if (!props.showTooltip) {
    return undefined;
  }
  return props.name;
});

const computedAlt = computed(() => {
  if (props.alt) {
    return props.alt;
  }
  if (props.name) {
    return t('Avatar of {name}', { name: props.name });
  }
  return '';
});

const computedText = computed(() => {
  if (props.name) {
    return getInitials(props.name);
  }
  return '';
});

// XL icon size is too large for the avatar
const iconSizeMap = {
  xxs: 'xs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 32,
  xxl: 56,
};

const iconSize = computed(() => iconSizeMap[props.size]);

function remove() {
  emit('remove');
}
</script>
<template>
  <div
    v-LsdTooltip="tooltip"
    :class="classes.base()"
    :style="{
      '--bg-color': computedBgColor,
      '--icon-color': computedIconColor,
    }"
  >
    <slot>
      <img v-if="!showFallback" :src="src" :alt="computedAlt" :class="classes.image()" @error="showFallback = true" />
      <template v-else>
        <LscIcon
          v-if="computedIcon"
          :size="iconSize"
          role="img"
          :title="computedAlt"
          :class="classes.icon()"
          :icon="computedIcon"
        />
        <span v-else>{{ computedText }}</span>
      </template>
      <button
        v-if="clearable"
        type="button"
        :class="classes.clearableButton()"
        :ariaLabel="t('Remove')"
        @click.stop="remove"
      >
        <LscIcon :size="iconSize" icon="lsi-close" class="text-on-dark" />
      </button>
    </slot>
  </div>
</template>
