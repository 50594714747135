import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useInboxActions } from './useInboxActions';
import { useInboxFilters } from './useInboxFilters';
import { useInboxPreview } from './useInboxPreview';
import { useInboxV3Loader } from './useInboxV3Loader';

const InboxSymbol = Symbol('InboxSymbol');

export function Inbox() {
  const pageSize = ref(40);
  const count = ref(pageSize.value);
  const hasPendingBulkAction = ref(false);

  const filter = useInboxFilters();

  const loader = useInboxV3Loader({
    count,
    params: filter.queryParams,
    pageSize,
  });

  const preview = useInboxPreview({ items: loader.state.items });

  const { setItemRead, setItemUnread, toggleItemArchivedState, toggleItemReadState, bulkUpdateItemState } =
    useInboxActions({ preview, filter, hasPendingBulkAction });

  const isInitialLoading = computed(() => {
    return !loader.state.initialized.value || hasPendingBulkAction.value;
  });

  useRealTimeUpdates((event, rawEvent) => {
    if (!event || !rawEvent) {
      return;
    }

    if (rawEvent.eventInfo.event === 'inboxBulkUpdate') {
      if (['created', 'progress'].includes(rawEvent.eventInfo.actionType)) {
        hasPendingBulkAction.value = true;
      }

      if (rawEvent.eventInfo.actionType === 'completed') {
        hasPendingBulkAction.value = false;
        loader.reset();
        loader.loadMore();
      }
    }
  });

  return {
    filter,
    loader,
    preview,
    setItemRead,
    setItemUnread,
    toggleItemArchivedState,
    toggleItemReadState,
    bulkUpdateItemState,
    hasPendingBulkAction,
    isInitialLoading,
  };
}

export function provideInbox() {
  const inbox = Inbox();
  provide(InboxSymbol, inbox);
  return inbox;
}

export function useInbox() {
  return inject(InboxSymbol);
}
