import { useVuelidate } from '@vuelidate/core';
import { useDropZone } from '@vueuse/core';
import { getMimeType, useValidators } from '@/util';

const symbol = Symbol('useLscDropzone');

/**
 * @typedef {Object} LscDropzoneParams
 * @property {ModelRef<object[]>} modelValue - The selected or uploaded files
 * @property {MaybeRef<string[]>} dropzoneRef - The dropzone element
 * @property {MaybeRef<string[]>} extensions - Allowed file extensions
 * @property {MaybeRef<number>} maxFileSize - Maximum file size in bytes
 * @property {MaybeRef<boolean>} multiple - Allow multiple files
 * @property {MaybeRef<boolean>} disabled - Control the disabled state
 * @property {MaybeRef<string>} message - Custom message
 * @property {MaybeRef<boolean>} showMessage - If the dropzone should show a the formats / or custom message
 * @property {MaybeRef<string>} errorMessage - Custom error message
 * @property {MaybeRef<boolen>} error - Control the error state
 */

/**
 * @param {LscDropzoneParams} params
 */
function LscDropzone(params = {}) {
  const modelValue = shallowRef(params.modelValue);
  const dropzoneRef = shallowRef(params.dropzoneRef);
  const size = shallowRef(params.size);
  const extensions = shallowRef(params.extensions || []);
  const maxFileSize = shallowRef(params.maxFileSize || Infinity);
  const multiple = shallowRef(params.multiple || false);
  const message = shallowRef(params.message || '');
  const disabled = shallowRef(params.disabled || false);
  const showMessage = shallowRef(params.showMessage || false);
  const error = shallowRef(params.error || false);
  const errorMessage = shallowRef(params.errorMessage || '');
  const computedDropzoneRef = computed(() => (disabled.value ? null : dropzoneRef.value));
  const acceptedMimeTypes = computed(() => Array.from(new Set(extensions.value.map(getMimeType))));
  const acceptedMimeTypesString = computed(() =>
    acceptedMimeTypes.value.length ? acceptedMimeTypes.value.join(',') : '*',
  );

  // Only used for validation, once validated, the files are moved to modelValue
  const files = shallowRef([]);
  const validators = useValidators();
  const rules = computed(() => ({
    files: {
      $each: validators.helpers.forEach({
        file: {
          mimeType: validators.mimeType(acceptedMimeTypes.value),
          maxFileSize: validators.maxFileSize(maxFileSize.value),
        },
      }),
    },
  }));

  const v$ = useVuelidate(rules, { files });

  const vuelidateErrorMessage = computed(() => {
    if (!v$.value.$error) {
      return '';
    }
    const messages = [];

    v$.value.files.$each.$response.$errors.forEach(({ file }) => {
      file.forEach(({ $message }) => {
        messages.push($message);
      });
    });
    return messages[0] ?? '';
  });

  // Either a custom error or the default error state
  const computedError = computed(() => error.value || v$.value.$error);

  // Either a custom error message or the default error message
  const computedErrorMessage = computed(() => {
    if (errorMessage.value) {
      return errorMessage.value;
    }
    return vuelidateErrorMessage.value;
  });

  /**
   * @param {File[]} newFiles
   * @returns
   */
  async function selectFiles(newFiles = []) {
    if (!newFiles.length) {
      return;
    }
    files.value = newFiles.map((file) => ({ file }));
    v$.value.$touch();
    if (v$.value.$error) {
      return;
    }
    modelValue.value = multiple.value ? [...newFiles] : [newFiles.at(0)];
    files.value = [];
    v$.value.$reset();
  }

  const { isOverDropZone } = useDropZone(computedDropzoneRef, { onDrop: selectFiles });

  return {
    acceptedMimeTypes,
    acceptedMimeTypesString,
    disabled,
    error: computedError,
    errorMessage: computedErrorMessage,
    extensions,
    isOverDropZone,
    maxFileSize,
    message,
    multiple,
    rules,
    selectFiles,
    showMessage,
    size,
    vuelidateErrorMessage,
  };
}

/**
 * @param {LscDropzoneParams} params
 */
export function provideLscDropzone(params = {}) {
  const dropzone = LscDropzone(params);
  provide(symbol, dropzone);
  return dropzone;
}

/**
 * @returns {ReturnType<LscDropzone>}
 */
export function useLscDropzone() {
  return inject(symbol);
}
