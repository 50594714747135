import { useFeaturesUtil } from './useFeaturesUtil';

const symbol = Symbol('useFeatures');

function Features() {
  const { initialized, ldFlag, twEnabled, twValue } = useFeaturesUtil();

  const lightspeedListViewToggleEnabledEarlyAccessSegment = ldFlag('projects-lightspeed-view-toggle--list-view', false);
  const lightspeedListViewToggleEnabledFinalSegment = ldFlag('projects-listview-beta-toggle-default-off', false);

  const lightspeedListViewToggleEnabled = computed(
    () => lightspeedListViewToggleEnabledEarlyAccessSegment.value || lightspeedListViewToggleEnabledFinalSegment.value,
  );

  return {
    initialized,

    // Teamwork features
    proofsLimit: twValue('proofs', Number),
    proofsEnabled: twEnabled('proofs'),
    activeProjectLimit: twValue('activeprojectlimit', Number),
    auditTrailEnabled: twEnabled('audittrail'),
    billableTargetMetEnabled: twEnabled('billabletargetmet'),
    clockInOutEnabled: twEnabled('clockinout'),
    clientListBudgetSummaryColumnsEnabled: twEnabled('clientbudgetsummaries'),
    clientOverviewEnabled: twEnabled('clientoverview'),
    clientOverviewCardsFeatureEnabled: twEnabled('clientoverviewcards'),
    clientUsersEnabled: twEnabled('clientusers'),
    companyTeamsEnabled: twEnabled('statusescompanyteams'),
    formulaFieldsEnabled: twEnabled('formulafields'),
    customfieldsLimit: twValue('customfields', Number, undefined, true),
    customfieldsProjectsEnabled: twEnabled('customfieldsprojects'),
    customfieldsTasksEnabled: twEnabled('customfieldstasks'),
    customReportingEnabled: twValue('customreporting', Number),
    companyTimesheetsEnabled: twEnabled('companytimesheets'),
    peopleJobRolesPageEnabled: twEnabled('jobroles'),
    timeRemindersEnabled: twEnabled('timereminders'),
    timeApprovalsEnabled: twEnabled('timeapprovals'),
    intakeFormsEnabled: twEnabled('intakeforms'),
    intakeFormsConditionalLogicEnabled: twEnabled('intakeformsconditionallogic'),
    intakeFormsAdvancedConditionalLogicEnabled: twEnabled('intakeformsadvancedconditionallogic'),
    intakeFormsFieldMappingEnabled: twEnabled('intakeformsfieldmapping'),
    myBoardsEnabled: twEnabled('myboards'),
    newGanttEnabled: twEnabled('newgantt'),
    newSchedulerInPricePlan: twEnabled('newscheduler'),
    newSchedulerPeopleTab: twEnabled('newschedulerpeopletab'),
    newSchedulerInsights: twEnabled('newschedulerinsights'),
    newSchedulerTentativeProjects: twEnabled('newschedulertentativeprojects'),
    newSchedulerTentativeProjectsLimit: twValue('newschedulertentativeprojects', Number),
    newSchedulerTasksEnabled: twEnabled('newschedulertasks'),
    resourceSchedulingEnabled: twEnabled('resourcescheduling'),
    plannedVsActualMilestoneReportEnabled: twEnabled('plannedactualmilestonereport'),
    plannedVsActualTasksReportEnabled: twEnabled('plannedactualtasksreport'),
    portfolioEnabled: twEnabled('portfolio'),
    profitabilityEnabled: twEnabled('profitability'),
    projectBudgetExpensesEnabled: twEnabled('projectbudgetexpenses'),
    financialBudgetLimit: twValue('projectfinancialbudgets', Number, undefined, true),
    projectFinancialBudgetsEnabled: twEnabled('projectfinancialbudgets'),
    projectTemplatesEnabled: twEnabled('projecttemplates'),
    timeBudgetLimit: twValue('projecttimebudgets', Number, undefined, true),
    projectTimeBudgetsEnabled: twEnabled('projecttimebudgets'),
    projectUpdatesEnabled: twEnabled('projectupdates'),
    reportsEnabled: twEnabled('reports'),
    reportingEnabled: twEnabled('reporting'),
    reportingColumnsLockedToScaleEnabled: twEnabled('reportingscalecolumns'),
    projectsHealthReportEnabled: twEnabled('projectshealthreport'),
    projectTimeReportEnabled: twEnabled('projecttimereport'),
    scheduleReportingEnabled: twEnabled('schedulereporting'),
    taskTimeReportEnabled: twEnabled('tasktimereport'),
    userTimeReportEnabled: twEnabled('userloggedtimereport'),
    timeReportEnabled: twEnabled('timereport'),
    userLoggedTimeReportEnabled: twEnabled('userloggedtimereport'),
    userTaskCompletionReportEnabled: twEnabled('usertaskcompletionreport'),
    reportFinancialInsightsEnabled: twEnabled('financialinsights'),
    tasklistBudgetsEnabled: twEnabled('tasklistbudgets'),
    tasklistBudgetsLimit: twValue('tasklistbudgets', Number, undefined, true),
    companyTimesheetsLimit: twValue('companytimesheets', Number, undefined, true),
    teamsCompanyTeamsEnabled: twEnabled('teamscompanyteams'),
    teamsSubteamsEnabled: twEnabled('teamssubteams'),
    teamsProjectsEnabled: twEnabled('teamsdefaultprojects'),
    utilizationReportEnabled: twEnabled('utilizationreport'),
    workloadBacklogEnabled: twEnabled('workloadbacklog'),
    workloadPlannerEnabled: twEnabled('workloadplanner'),
    riskRegisterEnabled: twEnabled('riskregister'),
    accessGoogleSheetsExports: twEnabled('accessgooglesheetsexports'),
    retainerBudgetsEnabled: twEnabled('retainerbudgets'),
    fixedFeeBudgetsEnabled: twEnabled('fixedfeebudgets'),
    retainerBudgetsLimit: twValue('retainerbudgets', Number, undefined, true),
    fixedFeeBudgetsLimit: twValue('projectfixedfeebudgets', Number, undefined, true),
    projectTemplatesLimit: twValue('projecttemplates', Number, undefined, true),
    projectTagsEnabled: twEnabled('projecttags'),
    intakeFormsLimit: twValue('intakeforms', Number, undefined, true),
    retrospectiveRates: twEnabled('retrospectiverates'),
    teamBulkActionsEnabled: twEnabled('teamsbulkactions'),
    sessionTimeoutEnabled: twEnabled('sessiontimeout'),
    fullPlanningOverviewEnabled: twEnabled('planningoverview'),
    planningOverviewChartOnlyEnabled: twEnabled('planningoverviewallocatedtime'),
    aiQuotaLimit: twValue('aiquotalimit', Number),
    workflowsLimit: twValue('workflows', Number, undefined, false),
    workflowsEnabled: twEnabled('workflows'),
    automationsMSTeamsEnabled: twEnabled('automationsmsteams'),
    automationsHubspotEnabled: twEnabled('automationshubspot'),
    automationsChatEnabled: twEnabled('automationschat'),
    automationsMultipleActionsEnabled: twEnabled('automationsmultipleactions'),
    automationsManualUpgradeEnabled: twEnabled('automationsmanualupgrade'),
    schedulerPlaceholdersAndTransferOfWorkEnabled: twEnabled('schedulerplaceholders'),

    // LaunchDarkly features
    hotjarEnabled: ldFlag('projects-hotjar', false),
    importOnboardingExperimentEnabled: ldFlag('projects-import-onboarding-experiment', false),
    integrateOnboardingExperimentEnabled: ldFlag('projects-integrate-onboarding-experiment', false),
    legacyPlansCanUpdatePaymentDetailsEnabled: ldFlag('projects-legacy-plans-can-update-payment-details', false),
    productTourEnabled: ldFlag('projects-show-in-app-product-tour', false),
    inboxEnabled: ldFlag('projects-show-inbox-new-notification-bell', false),
    captureProductOutcomesExperimentEnabled: ldFlag('projects-capture-product-outcomes-experiment', false),
    hotspotTourEnabled: ldFlag('projects-hotspot-tour-flag', false),
    hideUpgradePathsForMaxpSmallAccountsEnabled: ldFlag('projects-hide-upgrade-paths-maxp-small-accounts', false),
    addUsersCheckoutModalEnabled: ldFlag('projects-show-add-users-checkout-modal', false),
    productOutcomeFlowEnabled: ldFlag('projects-show-product-outcome-flow', false),

    profilePhotoPromptEnabled: ldFlag('projects-profile-photo-prompt-experiment', false),
    logTimeFeedbackLinkEnabled: ldFlag('projects-log-time-feedback-link-enabled', false),
    shouldReduceElasticsearchLoad: ldFlag('projects-elasticsearch-should-reduce-load', false),
    lightspeedOnboardingEnabled: ldFlag('projects-lightspeed-onboarding', false),
    newOverviewEnabled: ldFlag('projects-new-planning-overview-tab', false),
    showOverviewToggle: ldFlag('projects-planning-new-overview-tab-toggle', false),
    projectsPlantrialUpgradeSlatesEnabled: ldFlag('projects-plantrial-upgrade-slates-enabled', false),
    schedulerPlaceholdersEnabled: ldFlag('projects-scheduler-placeholders', false),
    schedulerTransferOfWorkEnabled: ldFlag('projects-placeholder-transfer-of-work', false),
    newSchedulerEnabled: ldFlag('projects-show-new-scheduler', false),
    oldSchedulerEnabled: ldFlag('projects-show-old-scheduler', false),
    lightspeedSpacesPanelEnabled: ldFlag('projects-spacesPanel-enabled', false), // Owner Peter Coppinger
    newUserProfileEnabled: ldFlag('projects-lightspeed-new-user-profile', false),
    projectsLightspeedMyWorkEnabled: ldFlag('projects-lightspeed-my-work', false),
    projectsLightspeedTableViewEnabled: ldFlag('projects-lightspeed-table-view', false),
    projectsLightspeedTaskDetails: ldFlag('projects-lightspeed-task-details', false),
    projectsOpenAIEnabled: ldFlag('projects-open-ai-enabled', false),
    projectsListViewNewTaskCreateOptions: ldFlag('projects-list-view-new-task-create-options', false),
    projectsReplaceTaskDetailsPage: ldFlag('projects-replace-task-details-page', false),
    projectsShowTWATaskDetailsUpdateAlert: ldFlag('projects-show-twa-task-details-update-alert', false),
    timeApprovalsFlagEnabled: ldFlag('projects-time-approvals', false), // Owner Manuel Cuesta / Financial Times
    logTimeModalSettingsEnabled: ldFlag('projects-log-time-modal-settings', false), // Owner Manuel Cuesta / Financial Times
    projectsDependentsSkipWeekends: ldFlag('projects-list-view-dependents-skip-weekends', false),
    hubspotChatEnabled: ldFlag('projects-hubspot-chat-enabled', false),
    chaportEnabled: ldFlag('projects-chaport', false),
    timeTaskProjectPickersRecentTabEnabled: ldFlag('projects-time-task-project-pickers-recent-tab', false), // Owner Manuel Cuesta / Financial Times
    projectsFixedFeeBudgetsEnabled: ldFlag('projects-fixed-fee-budgets', false), // Owner Manuel Cuesta / Financial Times
    timesheetsV3Enabled: ldFlag('projects-timesheets-v3', false), // Owner Manuel Cuesta / Financial Times
    projectsOnboardingTemplatesExperimentEnabled: ldFlag('projects-exp-a-23-26-onboarding-templates', false),
    projectsGoalBasedOnboardingExperimentEnabled: ldFlag('projects-exp-a-23-50-goal-based-onboarding', false),
    projectsGoalBasedOnboardingProjectTabEnabled: ldFlag('projects-exp-a-23-50-gbo-project-tab', false),
    projectsGoalBasedOnboardingBlankStatesEnabled: ldFlag('projects-exp-a-23-50-gbo-blank-states', false),
    userTimesheetCustomRowFeedbackLinkEnabled: ldFlag('projects-user-timesheet-custom-row-feedback-link', false), // Owner Manuel Cuesta / Financial Times
    expensesV2Enabled: ldFlag('projects-expenses-v2', false), // Owner Manuel Cuesta / Financial Times
    newAddOrEditInvoiceDialogEnabled: ldFlag('projects-new-add-or-edit-invoice-dialog', false),
    newMoveExpenseOrTimelogToInvoiceDialogEnabled: ldFlag(
      'projects-new-move-expense-or-timelog-to-invoice-dialog',
      false,
    ), // Owner Manuel Cuesta / Financial Times
    userTimesheetSettingsEnabled: ldFlag('projects-user-timesheet-settings', false), // Owner Manuel Cuesta / Financial Times
    projectsNewTasklistBudgetsEnabled: ldFlag('projects-task-list-budgets-dialog', false),
    taskCommentEditorEnabled: ldFlag('projects-task-comment-editor', false),
    projectsOverviewProjectGroupby: ldFlag('projects-overview-project-groupby', false),
    projectsNewBudgetAddOrEdit: ldFlag('projects-new-budget-add-or-edit', false), // Owner Manuel Cuesta / Financial Times
    newBillingPageEnabled: ldFlag('projects-new-billing-page', false), // Owner Manuel Cuesta / Financial Times
    projectsFixedFeePermissionEnabled: ldFlag('projects-fixed-fee-permission', false), // Owner Manuel Cuesta / Financial Times
    projectsWorkflowsEnabled: ldFlag('projects-workflows', false),
    projectsGlobalWorkflowsEnabled: ldFlag('projects-global-workflows', false),
    projectsWorkflowsMigrationInAWeek: ldFlag('projects-workflows-migration-in-a-week', false),
    projectsWorkflowsMigrationInADay: ldFlag('projects-workflows-migration-in-a-day', false),
    projectsBudgetProfitabilityPermissionEnabled: ldFlag('projects-budget-profitability-permission', false), // Owner Manuel Cuesta / Financial Times
    invoiceSummaryEnabled: ldFlag('projects-invoice-summary', false), // Owner Manuel Cuesta / Financial Times
    invoiceQuickBooksSummaryEnabled: ldFlag('projects-invoice-quickbooks-summarisation', false), // Owner Manuel Cuesta / Financial Times

    // Clients
    clientCommunicationsEnabled: ldFlag('projects-lightspeed-client-comms', false),
    clientViewEnabled: ldFlag('projects-client-view-access', false),
    clientTimePageEnabled: ldFlag('projects-client-view-logged-time', false),

    // Roles
    rolesManyToManyEnabled: ldFlag('projects-roles-many-to-many', false),

    // Reports
    utilizationReportChartEnabled: ldFlag('projects-reports-utilization-report-chart', false),
    advancedReportingEnabled: ldFlag('projects-perspective-experiment-01', false),
    advancedReportingNewColumnsEnabled: ldFlag('projects-customreports-perspective-newcolumns', false),
    clientPrecannedReportEnabled: ldFlag('projects-client-precanned-report', false),
    profitabilityReportChartEnabled: ldFlag('projects-profitability-report-chart', false),

    // LD Experiment flags
    addClientCreationToOnboardingFlowEnabled: ldFlag(
      'projects-exp-a-23-62-add-client-creation-to-onboarding-flow',
      false,
    ),
    icpOnboardingImprovementsEnabled: ldFlag('projects-a-23-30-personalised-icp-onboarding-improvements', false),
    onboardingSignupSourceSurveyStepEnabled: ldFlag('projects-g-23-07-onboarding-signup-source-survey', false),
    projectsFinanceOnboardingV2Enabled: ldFlag('projects-exp-a-23-01-finance-onboarding-v2', false),
    slackIntegrationDuringOnboarding: ldFlag('projects-exp-a-23-41-slack-integration-during-onboarding', 'control'),
    projectsDeliverToGrowSurfaceUpgradePaths: ldFlag('projects-exp-e-23-30-dg-upgrade-paths', false),
    projectsDummyDataEnabled: ldFlag('projects-exp-a-23-27-dummy-data', false),
    projectsDummyDataV3Enabled: ldFlag('projects-exp-a-23-27-dummy-data-v3', false),
    projectsFeatureTrialScale: ldFlag('projects-exp-e-23-21a-feature-trials-scale', false),
    projectsFeatureTrialGrow: ldFlag('projects-exp-e-23-21b-feature-trials-grow', false),
    projectsFeatureTrialGrowTasklistBudgets: ldFlag('projects-exp-e-23-21b-feature-trials-grow-tasklistbudgets', false),
    projectsFeatureTrialGrowRetainerBudgets: ldFlag('projects-exp-e-23-21b-feature-trials-grow-retainerbudgets', false),
    projectsFeatureTrialGrowCustomReports: ldFlag('projects-exp-e-23-21b-feature-trials-grow-customreporting', false),
    projectsFeatureTrialGrowCompanyTimesheets: ldFlag(
      'projects-exp-e-23-21b-feature-trials-grow-companytimesheets',
      false,
    ),
    optimiseFreeForeverToPaidUpgradeUXEnabled: ldFlag(
      'projects-exp-a-23-18-optimise-free-forever-to-paid-upgrade-ux',
      false,
    ),
    projectsCraicDealerMaxProjects: ldFlag('projects-exp-e-23-01-craic-dealer-maxp', false),
    contextAwareHelpMenuEnabled: ldFlag('projects-exp-a-23-49-context-aware-help-menu', false),
    projectsBlackFridayDeliver: ldFlag('projects-exp-r-23-04-black-friday-deliver', false),
    projectsBlackFridayGrow: ldFlag('projects-exp-r-23-04-black-friday-grow', false),
    projectsBlackFridayOffer: ldFlag('projects-exp-r-23-04-black-friday-offer', false),
    promoteGrowPlanDuringTrialEnabled: ldFlag('projects-exp-a-23-57-promote-grow-plan-during-trial', false),
    projectsJan2024SaleEnabled: ldFlag('projects-exp-e-24-01-jan-sale', false),
    budgetsUxImprovementsEnabled: ldFlag('projects-exp-a-23-63-budgets-ux-improvements', false),
    projectsRepsContactEnabled: ldFlag('projects-exp-r-24-01-reps-contact', false),
    projectsFreeImplementationTrainingEnabled: ldFlag('projects-exp-e-24-04-offer-free-implementation-training', false),
    projectsFreeImplementationTrainingVariation: ldFlag(
      'projects-exp-e-24-04-offer-free-implementation-training-variation',
      'control',
    ),
    projectsTicketsTabWordingVariation: ldFlag('projects-e-24-05-tickets-tab-wording', 'tickets'),
    displayAccountExecContacDetailsToTrialsEnabled: ldFlag(
      'projects-exp-a-24-08-display-account-exec-contac-details-to-trials',
      false,
    ),
    projectsLightSpeedTaskDetailsUIVariation: ldFlag('projects-lightspeed-task-details-ui-variation', false),
    projectsWorkerBeeTrainingProjectEnabled: ldFlag('projects-exp-a-24-11-worker-bee-training-project', false),
    linkedDemosEnabled: ldFlag('projects-linked-demos', false), // Owner Aodh, temp flag to show linked Partner Demos
    deskManagedBookingFormEnabled: ldFlag('desk-managed-booking-form', false), // Owner Bradley Aherne, temp flag for displaying hubspot form
    projectsGroupedAndPersonalisedSideNavEnabled: ldFlag(
      'projects-exp-a-24-13-grouped-and-personalised-side-nav',
      false,
    ),
    projectsHomeImprovementsEnabled: ldFlag('projects-exp-a-24-13a-home-improvements', false),
    deskProjectTicketLinkEnabled: ldFlag('desk-project-ticket-link', false),
    contextHelpButtons: ldFlag('projects-exp-a-24-03-contextual-onboarding', false),
    deskOnboardingFlowEnabled: ldFlag('desk-onboarding-flow', false),
    projectsProductEmailsRedesignEnabled: ldFlag('projects-exp-r-24-08-product-emails-redesign', false),
    scaleTrialUpgrade: ldFlag('projects-exp-a-24-19-scale-trial-upgrade', false),
    differentiatedOnboardingForIcp20PlusEnabled: ldFlag(
      'projects-exp-a-24-20-differentiated-onboarding-for-icp-20-plus',
      false,
    ),

    // Lightspeed Route Features
    lightspeedListViewToggleEnabled,
    lightspeedListViewEnabled: ldFlag('projects-lightspeed-list-view', false),
    projectsListViewBetaToggleDefaultOff: lightspeedListViewToggleEnabledFinalSegment,
    lightspeedInboxEnabled: ldFlag('projects-lightspeed-inbox', false),
    lightspeedPeopleCompaniesPageEnabled: ldFlag('projects-lightspeed-people-companies-page', false), // Owner Peter Coppinger
    lightspeedPeopleCompaniesPageToggleEnabled: ldFlag('projects-lightspeed-view-toggle--people-companies-page', false), // Owner Peter Coppinger
    commandCenterEnabled: ldFlag('projects-view-command-center', false), // Owner Peter Coppinger
    lightspeedProjectsInSidebar: ldFlag('projects-in-sidebar', false), // Owner Peter Coppinger
    projectNavSubmenuEnabled: ldFlag('projects-project-nav-submenu', false), // Owner Peter Coppinger
    projectIconInHeaderEnabled: ldFlag('projects-project-icon-header', false), // Owner Peter Coppinger
    aiAssistantEnabled: ldFlag('projects-ai-assistant', false),
    lightspeedTimePagesEnabled: ldFlag('projects-lightspeed-time-pages', false),
    projectsSearchV3Enabled: ldFlag('projects-search-v-3', false), // Owner Bravo team

    // Lightspeed migrations
    sharingSavedFilterLinksEnabled: ldFlag('projects-filters-sharing-saved-filter-links', false),
    migrateHomeActivity: ldFlag('projects-migrate-home-activity', false), // Owner Saad (c) / Peter C.

    migrateSettingsCustomfields: ldFlag('projects-migrate-settings-customerfields', false), // Owner Yasin (c) / Peter C.
    migrateProjectIdLinks: ldFlag('projects-migrate-project-id-links', false), // Owner Saad (c) / Peter C.
    migrateHomeProjects: ldFlag('projects-migrate-home-projects', false), // Owner Steve Walsh
    migrateProjectsList: ldFlag('projects-migrate-projects-list', false), // Owner Steve Walsh
    migrateProjectsTemplatesEnabled: ldFlag('projects-migrate-projects-templates', false), // Owner Simon Berry Byrne
    lightspeedMilestoneDrawerEnabled: ldFlag('milestone-quickview-migration', false), // Owner Steve Walsh
    lightspeedBudgetTableViewEnabled: ldFlag('projects-budget-table-migration', false), // Owner Steve Walsh
    lightspeedEverythingLinksPageEnabled: ldFlag('projects-app-everything-links-page-migration', false), // Owner Steve Walsh
    lightspeedAutomationsModalEnabled: ldFlag('projects-automations-modal-migration', false), // Owner Sinead Cullinane / Automations team
    lightspeedFinanceBudgetsEnabled: ldFlag('projects-finance-budgets', false), // Owner Manuel Cuesta / Financial Times
    lightspeedFinanceBudgetsSwitchEnabled: ldFlag('projects-finance-budget-switch', false), // Owner Manuel Cuesta / Financial Times

    // Calendar flags
    calendarGoogleLogtimeFlagEnabled: ldFlag('projects-google-calendar-log-time', false),
    calendarGoogleLogtimeEnabled: twEnabled('googlecalendarlogtime'),
    lightspeedCalendarEnabled: ldFlag('projects-lightspeed-calendar', false),
    calendarEnabled: twEnabled('mycalendar'),
    calendarGoogleCalSyncEnabled: twEnabled('googlecalsync'),
    calendarGoogleReadEnabled: twEnabled('googlecalendarreadevents'),
    calendarGoogleEditEnabled: twEnabled('googlecalendareditevents'),
    calendarTimeBlocksEnabled: twEnabled('timeblocks'),
    calendarTimeBlocksFlagEnabled: ldFlag('projects-calendar-time-blocks', false),
    calendarLogTimeBlocksEnabled: twEnabled('logtimeblocks'),
    calendarLogTimeBlocksFlagEnabled: ldFlag('projects-calendar-log-time-blocks', false),
    timesheetCalendarViewFlagEnabled: ldFlag('projects-timesheet-calendar-view', false),
    timesheetTaskPlannerEnabled: ldFlag('projects-timesheet-task-planner', false),
    costRatesFormEnabled: ldFlag('projects-cost-rates-v2', false),
    salesforceIntegrationEnabled: ldFlag('projects-integrations-salesforce', false),

    // special case for a customer
    projectsPreventTaskDeletion: ldFlag('projects-prevent-task-deletion', false),
  };
}

/**
 * Sets up LaunchDarkly and Teamwork features.
 */
export function provideFeatures() {
  provide(symbol, Features());
}

/**
 * Provides access to LaunchDarkly and Teamwork features.
 * @type {Features}
 */
export function useFeatures() {
  return inject(symbol);
}
