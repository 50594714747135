import { useCurrentAccount, useProjectSamplesV3Loader } from '@/api';

function getRecommendedTemplateIds(industryCategoryId) {
  switch (industryCategoryId) {
    case 2: // marketing agency
      return [
        22, // content creation workflow
        26, // digital marketing plan
        38, // seo management
      ];

    case 1: // website development agency
      return [
        20, // website project plan
        4, // content plan
        15, // bug tracking
      ];

    case 27: // creative agency
      return [
        16, // design project management
        6, // creative requests
        25, // design requests
      ];

    case 15: // professional services
      return [
        3, // client onboarding
        19, // task management
        11, // project tracker
      ];

    case 18: // software development
    case 17: // technology
      return [
        1, // agile software development
        15, // bug tracking
        10, // product launch plan
      ];

    case 7: // entertainment and media
      return [
        7, // event planning
        17, // event marketing
        12, // public relations planning
      ];

    case 20: // transport/distribution
      return [
        39, // risk assessment
        13, // teamwork onboarding
        19, // task management
      ];

    // Higher education / Healthcare / Financial services / Consultancy / Electronics and electrical /
    // Banking and insurance / Government / Hospitality / Manufacturing / Not for profit /
    // Retail / E-commerce / Utilities / Energy / Other
    default:
      return [
        3, // client onboarding
        19, // task management
        13, // teamwork onboarding
      ];
  }
}

function getTemplateIdForOutcomes(outcomeIds) {
  // user can have all outcomes selected, this sets up a hierarchy of which one to return

  if (outcomeIds.some((outcomeId) => [2, 3, 5].includes(outcomeId))) {
    return 3; // client onboarding
  }

  if (outcomeIds.some((outcomeId) => [1, 4].includes(outcomeId))) {
    return 19; // task management
  }

  if (outcomeIds.some((outcomeId) => [6].includes(outcomeId))) {
    return 13; // teamwork onboarding
  }

  return -1;

  // productOutcomeIds
  // 1 - managing projects
  // 2 - working with clients
  // 3 - tracking billable time
  // 4 - managing team capacity
  // 5 - billing clients for work
  // 6 - Reporting on profitability and utilization
}

const symbol = Symbol('onboardingWizardTemplateSelect');

/**
 * @param selectedProductOutcomeIds {ComputedRef<number[]>} Product outcome ids selected in the first step
 */
function OnboardingWizardCommonTemplateSelect(selectedProductOutcomeIds) {
  const account = useCurrentAccount();

  const { items: sampleTemplateProjects, inSync: sampleTemplateProjectsInSync } = useProjectSamplesV3Loader({
    count: Infinity,
    pageSize: 50,
    params: {
      include: 'projectCategories',
      'fields[projects]': 'id,name',
    },
  });

  const categoryRecommendations = getRecommendedTemplateIds(account.value.industryCategoryId);

  const categoryOrder = {
    1: 'Project Management',
    2: 'Marketing',
    3: 'Creative content',
    4: 'Operations',
    5: 'IT & Engineering',
  };

  const outcomeRecommendation = computed(() => getTemplateIdForOutcomes(selectedProductOutcomeIds.value));

  const templateCategories = computed(() => {
    const categories = {};
    let categoryId = 1;
    sampleTemplateProjects.value.forEach((template) => {
      if (template.id === 9) {
        // remove "Project management plan" template
        return;
      }
      const categoryName = template.category.name;
      if (!categories[categoryName]) {
        categories[categoryName] = { id: categoryId++, templates: [], isVisible: true };
      }
      categories[categoryName].templates.push(template);
    });
    const orderedCategories = {};
    Object.keys(categoryOrder).forEach((order) => {
      const categoryName = categoryOrder[order];
      if (categories[categoryName]) {
        orderedCategories[categoryName] = categories[categoryName];
      }
    });

    return orderedCategories;
  });

  const recommendedTemplates = computed(() => {
    const recommendedTemplatesArray = [];

    sampleTemplateProjects.value.forEach((template) => {
      if (categoryRecommendations.includes(template.id)) {
        recommendedTemplatesArray.push(template);
      }
    });

    recommendedTemplatesArray.sort((a, b) => {
      const indexA = categoryRecommendations.indexOf(a.id);
      const indexB = categoryRecommendations.indexOf(b.id);
      return indexA - indexB;
    });

    const outcomeTemplate = sampleTemplateProjects.value.find(
      (template) => template.id === outcomeRecommendation.value,
    );

    // we replace last industry recommendation with an outcome recommendation if one exists
    if (outcomeRecommendation.value >= 0 && !categoryRecommendations.includes(outcomeRecommendation.value)) {
      if (outcomeTemplate) {
        recommendedTemplatesArray.pop();
        recommendedTemplatesArray.push(outcomeTemplate);
      }
    }

    return recommendedTemplatesArray;
  });

  const industryCategory = computed(() => {
    switch (account.value.industryCategoryName?.toLowerCase()) {
      case 'marketing agency':
        return 'Marketing Agencies';
      case 'web development agency':
        return 'Web Development Agencies';
      case 'creative agency':
        return 'Creative Agencies';
      case 'consultancy':
        return 'Consultancies';
      default:
        return account.value.industryCategoryName;
    }
  });

  return {
    sampleTemplateProjects,
    sampleTemplateProjectsInSync,
    templateCategories,
    recommendedTemplates,
    industryCategory,
  };
}

/**
 * @param selectedProductOutcomeIds {ComputedRef<number[]>} Product outcome ids selected in the first step
 */
export function provideOnboardingWizardCommonTemplateSelect(selectedProductOutcomeIds) {
  const templateSelect = OnboardingWizardCommonTemplateSelect(selectedProductOutcomeIds);
  provide(symbol, templateSelect);
  return templateSelect;
}

export function useOnboardingWizardCommonTemplateSelect() {
  return inject(symbol);
}
