<script setup>
import { useTimeoutFn } from '@vueuse/core';
import { useCohort, useCurrentAccountState, useCurrentUserState, useFeatures, usePendo, usePermissions } from '@/api';
import { useQuickView } from '@/route';
import { useI18n } from '@/util';

const { inSync: accountInSync } = useCurrentAccountState();
const { inSync: userInSync } = useCurrentUserState();
const { chaportEnabled, initialized } = useFeatures();
const { isOwnerAdmin } = usePermissions();
const { isCompanySizeAboveTwenty, isICP, isInFreeTrial } = useCohort();
const { isQuickViewOpen } = useQuickView();
const { trackPendoEvent } = usePendo();
const { t } = useI18n();

const COMMON_METRICS = ['account_id', 'plan_name', 'page', 'user_role', 'on_trial', 'is_icp', 'is_paid'];
const EVENT_NAME = 'CHAT_EVENT';
const CHAPORT_APP_ID = '672371fd4a1db50e1387852e';
const shouldShowDismissButton = shallowRef(false);

function trackEvent({ eventAction, meta = {} }) {
  trackPendoEvent({
    eventName: EVENT_NAME,
    commonMetrics: COMMON_METRICS,
    metadata: {
      event_action: eventAction,
      ...meta,
    },
  });
}

function setupListeners() {
  window.chaport.on('widget.stateChange', () => {
    if (!window.chaport.getWidgetState()) {
      return;
    }

    if (window.chaport.getWidgetState() === 'expanded') {
      trackEvent({ eventAction: 'chat_minimised' });
    } else {
      trackEvent({ eventAction: 'chat_panel_displayed' });
    }
  });

  const chaportLauncher = document.getElementsByClassName('chaport-container')[0];
  chaportLauncher.style.position = 'fixed';
  chaportLauncher.style.zIndex = '2399';

  const { start: startDismissibleTimer, stop: stopDismissibleTimer } = useTimeoutFn(() => {
    shouldShowDismissButton.value = false;
  }, 3500);

  chaportLauncher.addEventListener('mouseenter', () => {
    stopDismissibleTimer();
    shouldShowDismissButton.value = true;
  });

  chaportLauncher.addEventListener('mouseout', () => {
    startDismissibleTimer();
  });
}

function onChaportReady() {
  window.chaport.on('ready', () => {
    setupListeners();
    trackEvent({ eventAction: 'chat_panel_enabled' });
  });
}

/* eslint-disable */
function initChaport() {
  (function (w, d, v3) {
    w.chaportConfig = {
      appId: CHAPORT_APP_ID,
      closeButton: true,
      appearance: {
        position: ['right', 0, 0],
        textStatuses: true,
      },
    };

    if (w.chaport) {
      return;
    }
    v3 = w.chaport = {};
    v3._q = [];
    v3._l = {};
    v3.q = function () {
      v3._q.push(arguments);
    };
    v3.on = function (e, fn) {
      if (!v3._l[e]) {
        v3._l[e] = [];
      }
      v3._l[e].push(fn);
    };
    const s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://app.chaport.com/javascripts/insert.js';
    const ss = d.getElementsByTagName('script')[0];
    ss.parentNode.insertBefore(s, ss);
  })(window, document);

  if (window?.chaport) {
    onChaportReady();
  }
}

function dismissChaportIcon() {
  shouldShowDismissButton.value = false;
  window.chaport.stopSession();
  trackEvent({ eventAction: 'chat_dismissed' });
}

const unwatch = watch(
  () =>
    Boolean(
      accountInSync.value &&
        userInSync.value &&
        initialized.value &&
        isOwnerAdmin.value &&
        chaportEnabled.value &&
        isCompanySizeAboveTwenty.value &&
        isICP.value &&
        isInFreeTrial.value,
    ),
  (shouldInitChaport) => {
    if (shouldInitChaport) {
      initChaport();
      queueMicrotask(() => unwatch());
    }
  },
  {
    immediate: true,
  },
);

watch(isQuickViewOpen, () => {
  if (isQuickViewOpen) {
    window.chaport?.close();
  }
});
</script>

<template>
  <div
    v-if="shouldShowDismissButton"
    type="button"
    class="fixed bottom-18 right-2 z-[--hubspot-dismiss-button-z-index]"
  >
    <LscIconButton
      v-LsdTooltip="t('Dismiss chat')"
      :ariaLabel="t('Dismiss chat')"
      icon="lsi-close"
      variant="secondary"
      class="flex items-center justify-center rounded-full"
      size="xs"
      @click.stop="dismissChaportIcon"
    />
  </div>
</template>
