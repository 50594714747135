<script setup>
import { useI18n } from '@/util';
import { LscAvatarSizes } from '../../../components/media/avatar/constants';
import LscAvatar from '../../../components/media/avatar/LscAvatar.vue';
import LscAvatarStack from '../../../components/media/avatar/LscAvatarStack.vue';
import { useAssigneePicker } from './useAssigneePicker';

const props = defineProps({
  avatars: {
    type: Array,
    default: () => [],
  },
  /**
   * The size of the avatar.
   * @default 'sm'
   * @type {PropType<typeof LscAvatarSizes[number]>}
   */
  size: {
    type: String,
    default: 'sm',
    validator: (v) => LscAvatarSizes.includes(v),
  },
  /**
   * The maximum number of avatars to display.
   */
  max: {
    type: Number,
    default: 3,
    validator: (v) => v > 0,
  },
  dataTestIdPrefix: {
    type: String,
    default: undefined,
  },
  blankTooltip: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const { multiple } = useAssigneePicker();

const computedBlankTooltip = computed(() => {
  if (props.disabled) {
    return undefined;
  }
  if (props.blankTooltip) {
    return props.blankTooltip;
  }
  return multiple.value ? t('Assign to one or more people') : t('Assign to someone');
});
</script>

<template>
  <button
    type="button"
    :disabled="disabled"
    class="rounded-full"
    :class="{
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    }"
    :data-test-id="dataTestIdPrefix && `${dataTestIdPrefix}-assignee-icon`"
  >
    <LscAvatarStack v-if="avatars.length" :size="size" :avatars="avatars" :max="max" />
    <LscAvatar v-else v-LsdTooltip="computedBlankTooltip" :size="size" />
  </button>
</template>
