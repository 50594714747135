<script setup>
import { useCohort, useCurrentAccount, useCurrentUser, useExperimentA18, useFeatures, usePendo } from '@/api';
import { useQuickView } from '@/route';
import { useLegacyBridge } from '@/module/legacy';
import { useOnboardingTourData } from '../tour/data/useOnboardingTourData';
import { provideOnboardingTour } from '../tour/useOnboardingTour';

const OnboardingChecklistDrawer = defineAsyncComponent(() => import('./drawer/OnboardingChecklistDrawer.vue'));
const OnboardingChecklistDrawerActivator = defineAsyncComponent(
  () => import('./drawer/OnboardingChecklistDrawerActivator.vue'),
);
const OnboardingTourSheet = defineAsyncComponent(() => import('../tour/OnboardingTourSheet.vue'));

const { activeProductTourId, incompleteItems } = provideOnboardingTour();
const account = useCurrentAccount();
const user = useCurrentUser();
const {
  isPaid,
  isPaymentOverdue,
  isClientUser,
  isCollaborator,
  isSiteOwner,
  isCompanyRoleContributorGrp,
  isInOwnerCompany,
  isContact,
  firstLoginMoreThan30DaysAgo,
} = useCohort();
const { projectTimeBudgetsEnabled, projectFinancialBudgetsEnabled, utilizationReportEnabled, workloadPlannerEnabled } =
  useFeatures();
const { onboardingTourData: tours } = useOnboardingTourData();
const { isExpA18Variation } = useExperimentA18();
const { trackPendoEvent } = usePendo();
const { toasts } = useLsToast();
const { isLegacyModalOpen } = useLegacyBridge();
const { isQuickViewExpanded } = useQuickView();

const showCheckList = ref(false);

const isAdminTrialUser = computed(() => {
  return (
    user.value.administrator &&
    user.value.inOwnerCompany &&
    // account is in free trial state
    account.value.trialDaysRemaining > 0 &&
    account.value.paymentStatus !== 'paid'
  );
});

const productOutcomeFlowEnabled = computed(() => {
  return (
    projectFinancialBudgetsEnabled.value &&
    projectTimeBudgetsEnabled.value &&
    workloadPlannerEnabled.value &&
    utilizationReportEnabled.value
  );
});

const showChecklistFlow = computed(() => {
  if (isExpA18Variation.value) {
    return !activeProductTourId.value;
  }

  if (
    isCompanyRoleContributorGrp.value &&
    !isSiteOwner.value &&
    isInOwnerCompany.value &&
    !isContact.value &&
    !isCollaborator.value
  ) {
    return !activeProductTourId.value;
  }

  if (
    (isPaid.value || isPaymentOverdue.value) &&
    !isClientUser.value &&
    !isCollaborator.value &&
    !isSiteOwner.value &&
    !firstLoginMoreThan30DaysAgo.value
  ) {
    return !activeProductTourId.value;
  }

  return !activeProductTourId.value && productOutcomeFlowEnabled.value && isAdminTrialUser.value;
});

const showOnboardingChecklistDrawerActivator = computed(() => {
  return !toasts.value.length && !isLegacyModalOpen.value;
});

const showChecklistButton = computed(() => {
  return showChecklistFlow.value && !showCheckList.value && !isQuickViewExpanded.value;
});

const showChecklistDrawer = computed(() => {
  return showChecklistFlow.value && showCheckList.value;
});

const currentTour = computed(() => Object.values(tours).find((tour) => tour.id === activeProductTourId.value));

function trackOnboardingChecklistOpened() {
  trackPendoEvent({
    eventName: 'ONBOARDING_CHECKLIST_EVENT',
    commonMetrics: ['plan_name', 'user_role', 'page'],
    metadata: {
      event_action: 'onboarding_checklist_opened',
    },
  });
}

function handleOnboardingChecklistOpened() {
  trackOnboardingChecklistOpened();
  showCheckList.value = true;
}
</script>
<template>
  <OnboardingTourSheet v-if="currentTour" :tour="currentTour" />
  <!-- match TWA Onboarding, the button-container class is used as the target element by pendo for guides currently -->
  <section class="button-container fixed bottom-8 right-6 z-20 p-1">
    <OnboardingChecklistDrawerActivator
      v-if="showChecklistButton"
      v-show="showOnboardingChecklistDrawerActivator"
      class="hidden md:flex"
      :remainingTasks="incompleteItems.length"
      @click="handleOnboardingChecklistOpened"
    />
  </section>
  <OnboardingChecklistDrawer v-if="showChecklistDrawer" v-model="showCheckList" />
</template>
