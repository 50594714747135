<script setup>
import { useCheckoutActions } from '@/api';
import { useRoute } from '@/route';
import { useI18n } from '@/util';
import { useCheckoutData } from '@/module/checkout';

const props = defineProps({
  needConfirmation: {
    type: Boolean,
    required: true,
  },
});

const { t, formatDate } = useI18n();
const { paidUntil } = useCheckoutData();
const { abortCancelSubscription } = useCheckoutActions();
const toast = useLsToast();
const route = useRoute();

function reactivateSubscription() {
  abortCancelSubscription()
    .then(() => {
      const isInsideSubscriptionPage = route.path.includes('settings/subscription');
      toast.success(
        t('Your subscription has been reactivated.') +
          (isInsideSubscriptionPage ? t('This page will reload to apply the latest changes.') : ''),
        {
          onClose: () => {
            if (isInsideSubscriptionPage) {
              window.location.reload();
            }
          },
        },
      );
    })
    .catch(() => {
      toast.critical(t('Sorry! Something went wrong reactivating your subscription.'));
    });
}

watchEffect(() => {
  if (!props.needConfirmation) {
    reactivateSubscription();
  }
});

const isOpen = shallowRef(false);
</script>

<template>
  <WidgetConfirmDialog
    v-if="needConfirmation"
    v-model="isOpen"
    :title="t('To make changes, reactivate your subscription')"
    :message="
      t(
        'Your subscription has been scheduled for cancellation on {date}. To regain the ability to make changes to your account, simply reactivate your subscription.',
        { date: formatDate(paidUntil) },
      )
    "
    :confirmButtonText="t('Reactivate subscription')"
    @close="isOpen = true"
    @confirm="
      reactivateSubscription();
      isOpen = true;
    "
  />
</template>
