<script setup>
import { useRouter } from 'vue-router';
import { useI18n } from '@/util';

defineProps({
  /**
   * Array that define the content list of the breadcrumb, each item is an object with to, title keys.
   */
  item: {
    type: Object,
    required: true,
  },
});

const open = inject('open');
const { t } = useI18n();

const router = useRouter();

function openItemLink(item) {
  // router push needs query passed as an object
  let query = {};
  if (item.to.indexOf('?') > -1) {
    query = Object.fromEntries(new URLSearchParams(item.to.split('?')[1]));
  }
  router.push({ path: item.to, query });
}
</script>

<template>
  <VListItem :key="item.id" v-bind="VListItemSidebarProjectsDrawer" class="group" tabindex="-1">
    <template #prepend>
      <LscIconButton
        icon="lsi-open-panel"
        class="transition-transform"
        size="sm"
        :ariaLabel="open.includes(item.id) ? t('Collapse') : t('Expand')"
        :class="{ '!rotate-90': open.includes(item.id) }"
      />
      <VIcon :color="item.iconColor" :size="14" tabindex="-1" :class="item.iconClass">
        {{ item.icon || 'lsi-project' }}
      </VIcon>
    </template>
    <RouterLink :to="item.to" :exact="true">
      <VListItemTitle :title="item.title" class="text-body-1" @click.stop.prevent="openItemLink(item)">
        {{ item.title }}
      </VListItemTitle>
    </RouterLink>
    <VListItemSubtitle v-if="item.subtitle" :title="item.subtitle" class="text-body-2">
      {{ item.title }}
    </VListItemSubtitle>
    <template #append>
      <LscBadge v-if="item.count" :count="item.count" />
    </template>
  </VListItem>
</template>
