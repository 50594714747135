/**
 * Constants, computed properties and functions related to Experiment E-24-04
 * https://app.launchdarkly.com/default/staging/features/projects-exp-e-24-04-offer-free-implementation-training/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/54861-e-24-04-offer-free-implementation-for-fix-forward-eligible-trials
 */

import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

export function useExperimentE2404() {
  const { projectsFreeImplementationTrainingEnabled, projectsFreeImplementationTrainingVariation } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();
  const { isCompanySizeAboveTwenty, isICP, isInFreeTrial } = useCohort();

  const { launchDarklyFlagKey, defaultValue } = projectsFreeImplementationTrainingVariation;

  const variations = {
    control: 'control',
    variant: 'variation',
    variantFinancial: 'variation-financial',
  };

  const ignoreInExperimentOnRuleMatch = true;

  const expE2404AppLevelTargeting = computed(
    () => isCompanySizeAboveTwenty.value && isICP.value && isInFreeTrial.value,
  );

  const isExpE2404Enabled = computed(
    () => expE2404AppLevelTargeting.value && projectsFreeImplementationTrainingEnabled.value,
  );

  const isExpE2404Control = computed(
    () => isExpE2404Enabled.value && projectsFreeImplementationTrainingVariation.value === variations.control,
  );

  const isExpE2404Variation = computed(
    () => isExpE2404Enabled.value && projectsFreeImplementationTrainingVariation.value === variations.variant,
  );

  const isExpE2404VariationFinancial = computed(
    () => isExpE2404Enabled.value && projectsFreeImplementationTrainingVariation.value === variations.variantFinancial,
  );

  function trackExperimentE2404() {
    if (isExpE2404Enabled.value) {
      trackExperimentInPendo({
        launchDarklyFlagKey,
        defaultValue,
        ignoreInExperimentOnRuleMatch,
        experimentEventSuffix: '_E2404B',
      });
    }
    if (isExpE2404Variation.value) {
      trackPendoEvent({ eventName: 'FREE_IMPLEMENTATION_E2404B_VARIATION' });
    }
    if (isExpE2404VariationFinancial.value) {
      trackPendoEvent({ eventName: 'FREE_IMPLEMENTATION_E2404B_VARIATION_FINANCIAL' });
    }
  }

  function trackEventE2404({ eventAction, meta = {} }) {
    trackPendoEvent({
      eventName: 'FREE_IMPLEMENTATION_EVENT',
      metadata: {
        event_action: eventAction,
        ...meta,
      },
    });
  }

  const CHECKOUT_SOURCE = 'checkout-E2404';

  return {
    launchDarklyFlagKey,
    isExpE2404Enabled,
    CHECKOUT_SOURCE,
    isExpE2404Control,
    isExpE2404Variation,
    isExpE2404VariationFinancial,
    trackExperimentE2404,
    trackEventE2404,
  };
}
