<script setup>
import { LscDateStepperSizes } from './constants';
import { provideLscDateStepper } from './useLscDateStepper';

const props = defineProps({
  /**
   * The size of the date stepper.
   * @type {PropType<typeof LscDateStepperSizes[number]>}
   */
  size: {
    type: String,
    default: 'lg',
    validator: (value) => LscDateStepperSizes.includes(value),
  },
});

provideLscDateStepper({
  size: computed(() => props.size),
});
</script>

<template>
  <div class="flex items-center gap-2">
    <slot name="prepend" />
    <slot name="nav" />
    <slot name="default" />
    <slot name="append" />
  </div>
</template>
