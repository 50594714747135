// eslint-disable-next-line no-restricted-imports
import { useRoute as useRouteOriginal, useRouter } from 'vue-router';

const useRouteSymbol = Symbol('useRoute');
const useQuickViewRouteSymbol = Symbol('useQuickViewRoute');

/**
 * Provides a reactive route object for the main route only.
 */
export function provideRouteMinimal() {
  provide(useRouteSymbol, useRouteOriginal());
}

/**
 * Provides reactive route objects for the main and quick view routes.
 */
export function provideRoute() {
  const router = useRouter();
  const historyState = shallowRef(window.history.state || {});
  // update history state after each route change
  router.afterEach((to, from) => {
    if (to.fullPath === historyState.value.quickViewBackgroundPath) {
      // if the to route is the same as the background path
      // we don't want to set it in a quick view.
      // this behaviour is triggered when trying to open a subtask quickview from a legacy entity page.
      window.history.state.quickViewBackgroundPath = null;
    } else if (to.meta.isQuickViewRoute && window.history.state && !window.history.state.quickViewBackgroundPath) {
      // if we're coming from another quick view,
      // persist the background path, else don't be a quick view.
      if (from.meta.isQuickViewRoute && historyState.value.quickViewBackgroundPath) {
        window.history.state.quickViewBackgroundPath = historyState.value.quickViewBackgroundPath;
      }
    }
    historyState.value = window.history.state || {};
  });

  // Due to our quickview routes pattern, we can't always assume that
  // the active current route will be derived from `router.currentRoute`.
  // Sometimes it may be computed from `historyState.quickViewBackgroundPath`.

  // This means we cannot use `useRoute` directly from `vue-router`, but should instead
  // use `useRoute` from `./useRoute`.

  // Logic here is taken from the vue-router implementation of `useRoute`.

  // https://github.com/vuejs/router/blob/v4.1.6/packages/router/src/router.ts#L1231-L1239
  const reactiveRoute = {};
  const reactiveQuickViewRoute = {};

  // get the background path if there is one.
  const quickViewBackgroundRoute = computed(() =>
    historyState.value.quickViewBackgroundPath ? router.resolve(historyState.value.quickViewBackgroundPath) : null,
  );

  // quick view routes should have both a bakground path in history and be enabled through route meta.
  const isQuickViewRoute = computed(
    () => router.currentRoute.value.meta.isQuickViewRoute && quickViewBackgroundRoute.value,
  );

  for (const key of Object.keys(router.currentRoute.value)) {
    // Main route reactive object
    reactiveRoute[key] = computed(() => {
      if (isQuickViewRoute.value) {
        return quickViewBackgroundRoute.value[key];
      }
      return router.currentRoute.value[key];
    });

    // Drawer route reactive object
    reactiveQuickViewRoute[key] = computed(() => {
      if (isQuickViewRoute.value) {
        return router.currentRoute.value[key];
      }
      return undefined;
    });
  }

  // Manually set shared history state on the route objects.
  // https://github.com/vuejs/router/pull/2106
  reactiveRoute.state = computed(() => historyState.value);
  reactiveQuickViewRoute.state = computed(() => historyState.value);

  const route = reactive(reactiveRoute);
  const quickViewRoute = reactive(reactiveQuickViewRoute);

  provide(useRouteSymbol, route);
  provide(useQuickViewRouteSymbol, quickViewRoute);
}

/**
 * Injects reactive route object.
 * @returns {import('vue-router').RouteLocationNormalizedLoaded}
 */
export function useRoute() {
  return inject(useRouteSymbol);
}

/**
 * Injects reactive quick view route object.
 * @returns {import('vue-router').RouteLocationNormalizedLoaded}
 */
export function useQuickViewRoute() {
  return inject(useQuickViewRouteSymbol);
}
