<script setup>
import { useAppShellSidebar } from '@/appShell';
import { getEntityDescriptionText, getEntitySecondaryText, getEntitySomeoneText, useI18n } from '@/util';
import NotificationDrawerListItem from './NotificationDrawerListItem.vue';

const props = defineProps({
  state: {
    type: Object,
    default: () => ({}),
  },
  step: {
    type: Number,
    default: 20,
  },
});

const emit = defineEmits(['toggleRead']);

const count = defineModel('count', { type: Number, required: true });

const { t } = useI18n();

const { activeDrawerPanel } = useAppShellSidebar();

const scrollContainer = shallowRef(null);

watch(
  activeDrawerPanel,
  (newVal) => {
    if (newVal === 'notifications') {
      nextTick(() => {
        const $element = scrollContainer.value;
        if ($element.scrollTop > 0) {
          $element.scrollTop = 0;
        }
      });
    }
  },
  { immediate: true },
);

const notifications = computed(() => unref(props.state.items));

function getNotificationTitle({ user, entity, extra }) {
  return getEntitySomeoneText(entity)(t, user || t('Someone'), extra);
}

function getNotificationDescription({ entity, description }) {
  return getEntityDescriptionText(entity)(t) ?? description;
}

function getNotificationSecondaryText({ entity, extra, action, project }) {
  const defaultText = extra?.description && action !== 'reacted' ? `${extra.description} - ${project}` : project;
  return getEntitySecondaryText(entity)(t) ?? defaultText;
}

// `<WidgetLoadingState>` cannot be used until `useNotificationsFetcher` is replaced by
// proper, standards-conforming `useNotificationsLoader` and `useNotificationActions` composables.
const uiState = computed(() => {
  const items = unref(props.state.items);
  const totalCount = unref(props.state.totalCount);
  const error = unref(props.state.error);
  if (error) {
    return 'error';
  }
  if (items.length > 0) {
    return 'default';
  }
  if (totalCount === 0) {
    return 'blank';
  }
  return 'loading';
});
</script>

<template>
  <div ref="scrollContainer" class="h-full overflow-y-auto">
    <LscSlotSwitch :name="uiState">
      <template #default>
        <VList class="m-0 h-full !px-4 !pt-2">
          <TransitionGroup
            moveClass="transition-opacity duration-500 ease-in-out"
            leaveActiveClass="transition-opacity duration-500 ease-out absolute"
            leaveToClass="opacity-0"
          >
            <NotificationDrawerListItem
              v-for="notification in notifications"
              :key="notification.id"
              v-memo="[notification.read]"
              :notification="notification"
              :title="getNotificationTitle(notification)"
              :description="getNotificationDescription(notification)"
              :secondaryText="getNotificationSecondaryText(notification)"
              :tooltipText="notification.read ? t('Mark as unread') : t('Mark as read')"
              v-bind="VListItemSidebarNotificationsDrawer"
              @toggleRead="emit('toggleRead', notification.id)"
            />
          </TransitionGroup>
          <WidgetLoadingLazy v-model:count="count" :step="step" :state="state" />
        </VList>
      </template>
      <template #loading>
        <WidgetLoadingProgress :message="t('Loading notifications')" />
      </template>
      <template #blank>
        <slot name="blank" />
      </template>
      <template #error>
        <LscEmptyState variant="error" class="h-full" :title="t('Notifications failed to load')" />
      </template>
    </LscSlotSwitch>
  </div>
</template>
