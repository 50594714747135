<script setup>
// This isnt automatically imported by Vite, so we need to import it manually
import { VMenu } from 'vuetify/components';
import { provideKeyboardShortcut } from '@/util';
import { blockScrollStrategy } from './blockScrollStrategy';
import { repositionScrollStrategy } from './repositionScrollStrategy';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  openOnHover: {
    type: Boolean,
    default: false,
  },
  preventUserSelect: {
    type: Boolean,
    default: true,
  },
  preventParentMenuClose: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['afterEnter', 'afterLeave']);
const attrs = useAttrs();
const modelValue = defineModel({
  type: Boolean,
  default: false,
});
provideKeyboardShortcut(modelValue);

const menuRef = shallowRef();

// Handle click outside.
let clickOutsideEvent = null;
const modelValueFiltered = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    // Do not close the menu when a toast was clicked.
    if (clickOutsideEvent?.target.closest('.LscToast')) {
      return;
    }
    if (props.preventParentMenuClose && !clickOutsideEvent && value === false) {
      return;
    }
    modelValue.value = value;
  },
});

function handleClickOutside(event) {
  clickOutsideEvent = event;
  nextTick(() => {
    clickOutsideEvent = null;
  });
}

function updateLocation() {
  menuRef.value?.updateLocation();
}

defineExpose({
  updateLocation,
  contentEl: computed(() => menuRef.value?.contentEl),
});
</script>

<template>
  <!-- eslint-disable-next-line lightspeed/no-restricted-component -->
  <VMenu
    ref="menuRef"
    v-model="modelValueFiltered"
    v-bind="attrs"
    :retainFocus="false"
    :openOnFocus="false"
    :openOnHover="openOnHover"
    :scrollStrategy="openOnHover ? repositionScrollStrategy : blockScrollStrategy"
    :class="preventUserSelect ? 'select-none' : 'select-auto'"
    @click:outside="handleClickOutside"
    @afterEnter="emit('afterEnter', $event)"
    @afterLeave="emit('afterLeave', $event)"
  >
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <slot name="default" />
  </VMenu>
</template>
