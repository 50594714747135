<script>
import { DateTime, Interval } from 'luxon';
import { useI18n } from '@/util';

export const ReportDateStepperRange = {
  allTime: 'allTime',
  day: 'day',
  week: 'week',
  month: 'month',
  quarter: 'quarter',
  quarterByWeek: 'quarterByWeek',
  quarterByMonth: 'quarterByMonth',
  custom: 'custom',
};

export function reportDateStepperRange(range) {
  switch (range) {
    case ReportDateStepperRange.quarterByWeek:
    case ReportDateStepperRange.quarterByMonth:
      return { [ReportDateStepperRange.quarter]: 1 };
    case ReportDateStepperRange.custom:
      return { [ReportDateStepperRange.week]: 1 };
    default:
      return { [range]: 1 };
  }
}

export function reportDateStepperDateRange(range, date) {
  switch (range) {
    case ReportDateStepperRange.allTime:
      return {
        startDate: '',
        endDate: '',
      };
    case ReportDateStepperRange.day:
      return {
        startDate: date.startOf('day'),
        endDate: date.endOf('day'),
      };
    case ReportDateStepperRange.week:
      return {
        startDate: date.startOf('week', { useLocaleWeeks: true }),
        endDate: date.endOf('week', { useLocaleWeeks: true }),
      };
    case ReportDateStepperRange.month:
      return {
        startDate: date.startOf('month'),
        endDate: date.endOf('month'),
      };
    case ReportDateStepperRange.quarter:
    case ReportDateStepperRange.quarterByMonth:
    case ReportDateStepperRange.quarterByWeek:
      return {
        startDate: date.startOf('quarter'),
        endDate: date.endOf('quarter'),
      };
    default:
      return {
        startDate: date.startOf('day'),
        endDate: date.startOf('day'),
      };
  }
}
</script>

<script setup>
const props = defineProps({
  ranges: {
    type: Array,
    required: true,
  },

  hidePrepend: {
    type: Boolean,
    required: false,
    default: false,
  },
  /**
   * The minimum date that can be selected.
   * @type {PropType<DateTime|undefined>}
   */
  minDate: {
    type: Object,
    default: undefined,
    validator: (date) => date == null || (DateTime.isDateTime(date) && date.isValid),
    required: false,
  },
  /**
   * The maximum date that can be selected.
   * @type {PropType<DateTime|undefined>}
   */
  maxDate: {
    type: Object,
    default: undefined,
    validator: (date) => date == null || (DateTime.isDateTime(date) && date.isValid),
    required: false,
  },
});

const emit = defineEmits(['trackPendoEvent', 'handleCustomReportTrack']);

const dates = defineModel('dates', {
  type: Object,
  required: true,
});

const { t, formatDateRange } = useI18n();

const hidePrependButton = computed(() => props.hidePrepend || dates.value.range === 'allTime');

const dateRanges = {
  [ReportDateStepperRange.allTime]: t('All time'),
  [ReportDateStepperRange.day]: t('Day'),
  [ReportDateStepperRange.week]: t('Week'),
  [ReportDateStepperRange.month]: t('Month'),
  [ReportDateStepperRange.quarter]: t('Quarter'),
  [ReportDateStepperRange.quarterByWeek]: t('Quarter by week'),
  [ReportDateStepperRange.quarterByMonth]: t('Quarter by month'),
  [ReportDateStepperRange.custom]: t('Custom'),
};

const currentRangeOptions = {
  day: { label: t('This week'), tooltip: t('Navigate to this week') },
  week: { label: t('This week'), tooltip: t('Navigate to this week') },
  month: { label: t('This month'), tooltip: t('Navigate to this month') },
  quarter: { label: t('This quarter'), tooltip: t('Navigate to this quarter') },
  quarterByWeek: { label: t('This quarter'), tooltip: t('Navigate to this quarter') },
  quarterByMonth: { label: t('This quarter'), tooltip: t('Navigate to this quarter') },
  custom: { label: t('Today'), tooltip: t('Navigate to today') },
};

function updateDates(value) {
  const prevValue = dates.value;

  dates.value = {
    ...prevValue,
    ...value,
  };
  if (value.range && value.range !== prevValue.range) {
    emit('trackPendoEvent', 'time_period_applied', 'advanced', prevValue.range, value.range);
  }
}

function navigateToDateRange(range, date) {
  updateDates(reportDateStepperDateRange(range, date));
}

function navigateToPreviousDateRange() {
  emit('trackPendoEvent', 'previous_period_clicked', 'advanced');
  const { endDate, range } = dates.value;

  navigateToDateRange(range, endDate.minus(reportDateStepperRange(range)));
}

function navigateToNextDateRange() {
  emit('trackPendoEvent', 'next_period_clicked', 'advanced');
  const { endDate, range } = dates.value;

  navigateToDateRange(range, endDate.plus(reportDateStepperRange(range)));
}

function navigateToCurrentDateRange() {
  emit('trackPendoEvent', 'this_period_clicked', 'advanced');
  navigateToDateRange(dates.value.range, DateTime.now());
}

async function updateRange(range) {
  emit('handleCustomReportTrack', 'custom_reports', 'custom_report_date_range_applied', 'activation_event', range);
  if (range === ReportDateStepperRange.allTime) {
    updateDates({
      range,
      startDate: '',
      endDate: '',
    });
  } else {
    updateDates({
      range,
      ...reportDateStepperDateRange(range, DateTime.now()),
    });
  }
}

const options = computed(() =>
  props.ranges.map((key) => ({
    key,
    name: dateRanges[key],
  })),
);

const hideNavButton = computed(() => dates.value.range === 'custom' || dates.value.range === 'allTime');

const label = computed(() => {
  const { startDate, endDate } = dates.value;
  if (!startDate || !endDate) {
    return t('All time');
  }
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);

  return formatDateRange(Interval.fromDateTimes(start, end), 'mini');
});

const prevLabel = computed(() => {
  const { range } = dates.value;

  switch (range) {
    case 'day':
      return t('Previous day');
    case 'week':
      return t('Previous week');
    case 'month':
      return t('Previous month');
    case 'quarter':
      return t('Previous quarter');
    default:
      return '';
  }
});

const nextLabel = computed(() => {
  const { range } = dates.value;

  switch (range) {
    case 'day':
      return t('Next day');
    case 'week':
      return t('Next week');
    case 'month':
      return t('Next month');
    case 'quarter':
      return t('Next quarter');
    default:
      return '';
  }
});

const customDates = computed({
  get() {
    const { startDate, endDate } = dates.value;
    if (startDate === '' || endDate === '') {
      return [DateTime.now(), DateTime.now()];
    }
    return [startDate, endDate];
  },
  set(next) {
    updateDates({
      range: ReportDateStepperRange.custom,
      startDate: next[0],
      endDate: next[1],
    });
  },
});

watch(
  () => dates.value,
  (crr, prev) => {
    const validDateObjects = crr.startDate instanceof DateTime && crr.endDate instanceof DateTime;
    if (
      (validDateObjects && !crr.startDate.hasSame(crr.endDate, 'day')) ||
      crr.range === prev?.range ||
      crr.range === ReportDateStepperRange.custom
    ) {
      return;
    }
    if (validDateObjects) {
      navigateToDateRange(crr.range, crr.startDate);
    }
  },
  {
    flush: 'sync',
    immediate: true,
  },
);

function handleClick() {
  emit('trackPendoEvent', 'time_period_opened', 'advanced');
  emit('handleCustomReportTrack', 'custom_reports', 'custom_report_date_ranges_viewed', 'activation_event');
}
</script>

<template>
  <div class="flex items-center">
    <LscDateStepper>
      <template v-if="!hidePrependButton" #prepend>
        <LscDateStepperButton
          v-LsdTooltip="currentRangeOptions[dates.range]?.tooltip || ''"
          data-identifier="report-date-stepper"
          @click="navigateToCurrentDateRange"
        >
          <LscOverflowEllipsis>
            {{ currentRangeOptions[dates.range]?.label }}
          </LscOverflowEllipsis>
        </LscDateStepperButton>
      </template>
      <template v-if="!hideNavButton" #nav>
        <LscDateStepperNavButtons
          :prevLabel="prevLabel"
          :nextLabel="nextLabel"
          dataIdentifierPrefix="report-date-stepper-nav-buttons"
          @prev="navigateToPreviousDateRange"
          @next="navigateToNextDateRange"
        />
      </template>
      <template #default>
        <WidgetOptionsMenu>
          <template #activator="activator">
            <LscDateStepperLabel v-bind="activator.props" showDropDownIcon @click="handleClick">
              {{ label }}
            </LscDateStepperLabel>
          </template>
          <WidgetOptionsMenuItem
            v-for="item in options"
            :key="item.key"
            class="truncate text-body-2"
            :text="item.name"
            :active="dates.range === item.key"
            @click="updateRange(item.key)"
          />
          <LscDatePicker
            v-model:dates="customDates"
            dataIdentifierPrefix="report-date-stepper-date-picker"
            :showShortcuts="false"
            :maxDate="maxDate"
            :minDate="minDate"
            :clearable="false"
          >
            <template #activator="activator">
              <WidgetOptionsMenuItem
                v-bind="activator.props"
                :active="dates.range === ReportDateStepperRange.custom"
                :text="t('Custom')"
                class="truncate text-body-2"
                @click="
                  emit(
                    'handleCustomReportTrack',
                    'custom_reports',
                    'custom_report_date_range_applied',
                    'activation_event',
                    'custom',
                  )
                "
              />
            </template>
          </LscDatePicker>
        </WidgetOptionsMenu>
      </template>
    </LscDateStepper>
  </div>
</template>
