import { debouncedRef } from '@vueuse/core';
import { useAssigneesCompositeLoader, useCurrentAccount, useFeatures } from '@/api';
import { useI18n } from '@/util';

const symbol = Symbol('useAssigneePicker');

/**
 * @param {object} options
 * @param {ShallowRef<object[]>} options.assignees
 * @param {ComputedRef<boolean>} options.clearable
 * @param {ComputedRef<string>} options.dataTestIdPrefix
 * @param {ComputedRef<number[]>} options.excludeUserIds
 * @param {ComputedRef<boolean>} options.onlyOwnerCompany
 * @param {ComputedRef<boolean>} options.includeClientUsers
 * @param {ComputedRef<boolean>} options.includeCollaborators
 * @param {ComputedRef<boolean>} options.includeCompanies
 * @param {ComputedRef<boolean>} options.includeCompanyTeams
 * @param {ComputedRef<boolean>} options.includeObservers
 * @param {ComputedRef<boolean>} options.includeProjectTeams
 * @param {ComputedRef<boolean>} options.includeSubteams
 * @param {ComputedRef<boolean>} options.includeRoles
 * @param {ComputedRef<boolean>} options.includeTeams
 * @param {ComputedRef<boolean>} options.isAnyoneActiveFunction
 * @param {ComputedRef<number[]>} options.lockdownCompanyIds
 * @param {ComputedRef<number>} options.lockdownId
 * @param {ComputedRef<number[]>} options.lockdownUserIds
 * @param {ComputedRef<number[]>} options.lockdownTeamIds
 * @param {ComputedRef<'reassign'|'toggle'>} options.mode
 * @param {ComputedRef<boolean>} options.modelValue
 * @param {ComputedRef<boolean>} options.multiple
 * @param {ComputedRef<number>} options.projectId
 * @param {ComputedRef<boolean>} options.preventParentMenuClose
 * @param {ComputedRef<boolean>} options.showAnyone
 * @param {ComputedRef<boolean>} options.showAssignRoles
 * @param {ComputedRef<number>} options.taskId
 */
function AssigneePicker({
  assignees,
  clearable,
  dataTestIdPrefix,
  excludeUserIds,
  includeClientUsers,
  includeCollaborators,
  includeCompanies,
  includeCompanyTeams,
  includeObservers,
  includeProjectTeams,
  includeRoles,
  includeSubteams,
  includeTeams,
  isAnyoneActiveFunction,
  lockdownCompanyIds,
  lockdownId,
  lockdownTeamIds,
  lockdownUserIds,
  mode,
  modelValue,
  multiple,
  onlyOwnerCompany,
  preventParentMenuClose,
  projectId,
  showAnyone,
  showAssignRoles,
  taskId,
} = {}) {
  const searchTerm = shallowRef('');
  const isAnyoneActive = computed(() => isAnyoneActiveFunction.value(assignees.value));

  const { t } = useI18n();
  const account = useCurrentAccount();
  const { peopleJobRolesPageEnabled } = useFeatures();

  const debouncedSearchTerm = debouncedRef(searchTerm, 300);

  const showClearButton = computed(() => clearable.value && assignees.value.length > 0);

  const avatars = computed(() =>
    assignees.value?.map((assignee) =>
      getLsAvatarProps({ [assignee.assigneeType ?? assignee.entityType ?? 'user']: assignee }),
    ),
  );

  const selectedUserIds = computed(() =>
    assignees.value.filter((assignee) => assignee.entityType === 'user').map((user) => Number(user.id)),
  );

  const selectedTeamIds = computed(() =>
    assignees.value.filter((assignee) => assignee.entityType === 'team').map((team) => Number(team.id)),
  );

  const selectedCompanyIds = computed(() =>
    assignees.value.filter((assignee) => assignee.entityType === 'company').map((team) => Number(team.id)),
  );

  /**
   * Toggles an assignee
   * @param {object} item
   * @param {object} options
   * @param {ClickEvent} options.event
   * @param {boolean} options.removeOthers
   */
  function toggleAssignee(item, options) {
    if (options.removeOthers || !multiple.value) {
      // eslint-disable-next-line no-param-reassign
      assignees.value = [item];
      // eslint-disable-next-line no-param-reassign
      modelValue.value = false;
    } else {
      options.event.stopPropagation();
      const existingAssignee = assignees.value.find(
        (assignee) => assignee.entityType === item.entityType && Number(assignee.id) === Number(item.id),
      );
      if (existingAssignee) {
        if (!clearable.value && assignees.value.length === 1) {
          return;
        }
        // eslint-disable-next-line no-param-reassign
        assignees.value = assignees.value.filter(
          ({ id, entityType } = {}) =>
            (existingAssignee.entityType === entityType && id !== existingAssignee.id) ||
            existingAssignee.entityType !== entityType,
        );
      } else {
        // eslint-disable-next-line no-param-reassign
        assignees.value = [...assignees.value, item];
      }
    }
  }

  /**
   * Toggle a person assignee
   * @param {Object} person
   * @param {Object} options
   * @param {Boolean} options.removeOthers
   * @param {ClickEvent} options.event
   */
  function togglePerson(person, options) {
    toggleAssignee({ ...person, entityType: 'user' }, options);
  }

  /**
   * Toggle a team assignee
   * @param {Object} team
   * @param {Object} options
   * @param {Boolean} options.removeOthers
   * @param {ClickEvent} options.event
   */
  function toggleTeam(team, options) {
    toggleAssignee({ ...team, entityType: 'team' }, options);
  }

  /**
   * Toggle a company assignee
   * @param {Object} company
   * @param {Object} options
   * @param {Boolean} options.removeOthers
   * @param {ClickEvent} options.event
   */
  function toggleCompany(company, options) {
    toggleAssignee({ ...company, entityType: 'company' }, options);
  }

  /**
   * Clear all selected assignees
   */
  function clearAssignees() {
    // eslint-disable-next-line no-param-reassign
    assignees.value = [];
  }

  const count = shallowRef(-1);
  const state = useAssigneesCompositeLoader({
    count,
    projectId,
    taskId,
    lockdownId,
    lockdownUserIds,
    lockdownTeamIds,
    lockdownCompanyIds,
    loadPeople: true,
    peopleParams: computed(() => ({
      searchTerm: debouncedSearchTerm.value,
      excludeIds: excludeUserIds.value.join(','),
      excludeContacts: true,
      includeObservers: includeObservers.value,
      orderPrioritiseCurrentUser: true,
      orderBy: 'company',
      onlyOwnerCompany: onlyOwnerCompany.value,
      includeCollaborators: includeCollaborators.value,
      includeClients: includeClientUsers.value,
      searchUserJobRole: includeRoles.value,
      include:
        includeRoles.value && peopleJobRolesPageEnabled.value
          ? 'companies,permissions,teams,jobroles'
          : 'companies,permissions,teams',
      'fields[companies]': 'id,name',
    })),
    loadTeams: includeTeams,
    teamsParams: computed(() => ({
      includeObservers: false,
      searchTerm: debouncedSearchTerm.value,
      usePagination: true,
      sortBy: 'picker',
      sortOrder: 'asc',
      includeCompanyTeams: includeCompanyTeams.value,
      includeProjectTeams: includeProjectTeams.value,
      includeSubteams: includeSubteams.value,
    })),
    loadCompanies: includeCompanies,
    companiesParams: computed(() => ({
      searchTerm: debouncedSearchTerm.value,
    })),
  });

  const people = computed(() => state.items.value.map(({ person }) => person).filter(Boolean));
  const teams = computed(() => state.items.value.map(({ team }) => team).filter(Boolean));
  const companies = computed(() => state.items.value.map(({ company }) => company).filter(Boolean));

  const searchPlaceholder = computed(() => {
    if (includeTeams.value && includeCompanies.value && includeRoles.value) {
      return account.value.useClientView
        ? t('People, teams, clients, or roles')
        : t('People, teams, companies, or roles');
    }
    if (includeTeams.value && includeCompanies.value) {
      return account.value.useClientView ? t('People, teams, or clients') : t('People, teams, or companies');
    }
    if (includeCompanies.value && includeRoles.value) {
      return account.value.useClientView ? t('People, clients, or roles') : t('People, companies, or roles');
    }
    if (includeCompanies.value) {
      return account.value.useClientView ? t('People or clients') : t('People or companies');
    }
    if (includeTeams.value && includeRoles.value) {
      return t('People, teams, or roles');
    }
    if (includeTeams.value) {
      return t('People or teams');
    }
    if (includeRoles.value) {
      return t('People or roles');
    }
    return t('Search people');
  });

  const emptyStateMessage = computed(() => {
    if (includeTeams.value && includeCompanies.value && includeRoles.value) {
      return account.value.useClientView
        ? t('No people, teams, clients or roles match your search')
        : t('No people, teams, companies or roles match your search');
    }
    if (includeTeams.value && includeCompanies.value) {
      return account.value.useClientView
        ? t('No people, teams or clients match your search')
        : t('No people, teams or companies match your search');
    }
    if (includeCompanies.value && includeRoles.value) {
      return account.value.useClientView
        ? t('No people, clients or roles match your search')
        : t('No people, companies or roles match your search');
    }
    if (includeCompanies.value) {
      return account.value.useClientView
        ? t('No people or clients match your search')
        : t('No people or companies match your search');
    }
    if (includeTeams.value && includeRoles.value) {
      return t('No people, teams or roles match your search');
    }
    if (includeTeams.value) {
      return t('No people or teams match your search');
    }
    if (includeRoles.value) {
      return t('No people or roles match your search');
    }
    return t('No people match your search');
  });

  return {
    assignees,
    avatars,
    clearable,
    clearAssignees,
    companies,
    count,
    dataTestIdPrefix,
    emptyStateMessage,
    includeCompanies,
    includeTeams,
    isAnyoneActive,
    modelValue,
    multiple,
    people,
    preventParentMenuClose,
    projectId,
    searchTerm,
    searchPlaceholder,
    selectedCompanyIds,
    mode,
    selectedTeamIds,
    selectedUserIds,
    showAnyone,
    showAssignRoles,
    showClearButton,
    includeRoles,
    state,
    taskId,
    teams,
    toggleCompany,
    togglePerson,
    toggleTeam,
  };
}

export function provideAssigneePicker(params) {
  const assigneePicker = AssigneePicker(params);
  provide(symbol, assigneePicker);
  return assigneePicker;
}

/**
 *
 * @returns {ReturnType<typeof AssigneePicker>}
 */
export function useAssigneePicker() {
  return inject(symbol);
}
