import { DateTime, Duration, Interval } from 'luxon';
import { useCurrentAccount, useFeatures } from '@/api';
import { useQuickView } from '@/route';
import { getToday, isSameDay, useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';

export function useReportHelpers() {
  const { t, formatDate, formatDateRange, formatMinutes, formatPercentage, formatCurrency } = useI18n();
  const { showLegacyQuickView } = useLegacyBridge();
  const { openQuickView: openQuickViewRoute } = useQuickView();
  const account = useCurrentAccount();
  const { lightspeedMilestoneDrawerEnabled } = useFeatures();

  /**
   * Calculates whether time is left or over based on the given difference.
   * @param {Object} diff - Duration object containing months, days, and hours.
   * @returns {string|null} Formatted text indicating time left or over.
   */
  function calculateLeftOrOver(diff) {
    const { months, days, hours } = diff.values;
    const monthDiff = Math.abs(months);
    const dayDiff = Math.ceil(Math.abs(days));
    const hourDiff = Math.ceil(Math.abs(hours));

    if (monthDiff) {
      return months > 0
        ? t('1 month over | {n} months over', { n: monthDiff })
        : t('1 month left | {n} months left', { n: monthDiff });
    }
    if (dayDiff) {
      return days > 0
        ? t('1 day over | {n} days over', { n: dayDiff })
        : t('1 day left | {n} days left', { n: dayDiff });
    }
    if (hourDiff) {
      return hours > 0
        ? t('1 hour over | {n} hours over', { n: hourDiff })
        : t('1 hour left | {n} hours left', { n: hourDiff });
    }

    return null;
  }

  /**
   * Splits a full name into first and last name.
   * @param {string} fullName - Full name of the individual.
   * @returns {Object} Object containing firstName and lastName.
   */
  function splitFullName(fullName) {
    return {
      firstName: fullName.split(' ')[0],
      lastName: fullName.slice(fullName.indexOf(' ') + 1),
    };
  }

  /**
   * Returns an array of assignee avatars with enriched properties.
   * @param {Array} inputAssignees - Array or object containing assignee information.
   * @returns {Array} Array of avatar properties for assignees.
   */
  function getAssigneeAvatars(inputAssignees) {
    let processedAssignees;

    if (!Array.isArray(inputAssignees)) {
      const flatArrays = [];

      for (const [key, values] of Object.entries(inputAssignees)) {
        const enrichedValues = values.map((value) => ({ ...value, assigneeType: key }));
        flatArrays.push(...enrichedValues);
      }

      processedAssignees = flatArrays;
    } else {
      processedAssignees = inputAssignees;
    }

    return processedAssignees.map((assignee) => {
      if (assignee.name) {
        const { firstName, lastName } = splitFullName(assignee.name);
        const assigneeWithSplitName = {
          ...assignee,
          firstName,
          lastName,
        };
        return getLsAvatarProps({
          [assignee.entityType ?? assignee.assigneeType]: assigneeWithSplitName,
        });
      }
      return getLsAvatarProps({
        [assignee.entityType ?? assignee.assigneeType]: assignee,
      });
    });
  }

  /**
   * Returns a formatted string indicating who completed the task.
   * @param {string} firstName - First name of the person.
   * @param {string} lastName - Last name of the person.
   * @returns {string} Formatted string indicating completion.
   */
  function getCompletedByName(firstName, lastName) {
    return t('Completed by {firstName} {lastName}', {
      firstName,
      lastName,
    });
  }

  function getDifference(dueDate, originalDueDate, completedOn) {
    if (!(dueDate instanceof DateTime)) {
      // eslint-disable-next-line no-param-reassign
      dueDate = DateTime.fromISO(dueDate);
    }
    if (!(completedOn instanceof DateTime)) {
      // eslint-disable-next-line no-param-reassign
      completedOn = DateTime.fromISO(completedOn);
    }
    if (!(originalDueDate instanceof DateTime)) {
      // eslint-disable-next-line no-param-reassign
      originalDueDate = DateTime.fromISO(originalDueDate);
    }
    const completionDateOrCurrentDueDate = completedOn && completedOn.isValid ? completedOn : dueDate;
    const roundedDiff = Math.round(originalDueDate.diff(completionDateOrCurrentDueDate, 'days').days);

    const diff = Math.abs(roundedDiff);

    return { difference: roundedDiff, text: t('1 day | {n} days', { n: Number.isNaN(diff) ? 0 : diff }) };
  }

  function getLatestActivityHoverTextMilestones(firstName, lastName, before, after, field) {
    let latestUpdateText = '';
    switch (field) {
      case 'created': {
        if (after) {
          latestUpdateText = t('{firstName} {lastName} created the milestone with original due date {after}', {
            firstName,
            lastName,
            after,
          });
        } else {
          latestUpdateText = t('{firstName} {lastName} created the milestone', { firstName, lastName });
        }
        break;
      }
      case 'isPrivate':
        if (after / 1 === 1) {
          latestUpdateText = t('{firstName} {lastName} updated privacy, making it visible to specific users only', {
            firstName,
            lastName,
          });
        } else if (after / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} updated privacy, making it visible to everyone on the project', {
            firstName,
            lastName,
          });
        }
        break;
      case 'name': {
        latestUpdateText = t('{firstName} {lastName} changed the name from {before} to {after}', {
          firstName,
          lastName,
          before,
          after,
        });
        break;
      }
      case 'projectId': {
        latestUpdateText = t('{firstName} {lastName} moved between projects', { firstName, lastName });
        break;
      }
      case 'responsiblePartyIds': {
        latestUpdateText = t('{firstName} {lastName} changed who is responsible for the milestone', {
          firstName,
          lastName,
        });
        break;
      }
      case 'responsibleParties': {
        latestUpdateText = t('{firstName} {lastName} changed who is responsible for the milestone', {
          firstName,
          lastName,
        });
        break;
      }
      case 'description': {
        if (before && !after) {
          latestUpdateText = t('{firstName} {lastName} removed the description', { firstName, lastName });
        } else if (!before && after) {
          latestUpdateText = t('{firstName} {lastName} set description to {after}', { firstName, lastName, after });
        } else {
          latestUpdateText = t('{firstName} {lastName} changed the description from {before} to {after}', {
            firstName,
            lastName,
            before,
            after,
          });
        }
        break;
      }
      case 'dueDate': {
        if (after === '') {
          latestUpdateText = t('{firstName} {lastName} removed the due date', { firstName, lastName });
        } else {
          latestUpdateText = t('{firstName} {lastName} changed due date from {before} to {after}', {
            firstName,
            lastName,
            before: formatDate(DateTime.fromISO(before)),
            after: formatDate(DateTime.fromISO(after)),
          });
        }
        break;
      }
      case 'status': {
        if (after === 'completed') {
          latestUpdateText = t('{firstName} {lastName} completed the milestone', { firstName, lastName });
        } else if (after === 'reopened') {
          latestUpdateText = t('{firstName} {lastName} re-opened the milestone', { firstName, lastName });
        } else if (after === 'new') {
          latestUpdateText = t('{firstName} {lastName} created the milestone', { firstName, lastName });
        }
        break;
      }
      case 'isDeleted': {
        if (after === '') {
          latestUpdateText = t('{firstName} {lastName} deleted the milestone', { firstName, lastName });
        } else {
          latestUpdateText = t('{firstName} {lastName} restored the milestone', { firstName, lastName });
        }
        break;
      }
      case 'tasklistIds': {
        latestUpdateText = t('{firstName} {lastName} changed attached task lists', { firstName, lastName });
        break;
      }
      case 'tagIds': {
        latestUpdateText = t('{firstName} {lastName} changed attached tags', { firstName, lastName });
        break;
      }
      default:
    }
    return latestUpdateText;
  }

  /**
   * Returns translated activity text based on user and activity type.
   * @param {object} item - the activity item
   * @returns {string} Translated activity by user.
   */
  function getLatestActivityHoverTextTasks(item) {
    const { firstName, lastName } = item.updatedBy;

    if (!item.latestUpdates) {
      return t('{firstName} {lastName} created the task', { firstName, lastName });
    }
    const { before, after, field } = item.latestUpdates[0];

    let latestUpdateText = '';
    switch (field) {
      case 'created': {
        latestUpdateText = t('{firstName} {lastName} created the task', { firstName, lastName });
        break;
      }
      case 'taskIsPrivate':
        if (after / 1 === 1) {
          latestUpdateText = t('{firstName} {lastName} updated privacy, making it visible to specific users only', {
            firstName,
            lastName,
          });
        } else if (after / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} updated privacy, making it visible to everyone on the project', {
            firstName,
            lastName,
          });
        }
        break;
      case 'name': {
        latestUpdateText = t('{firstName} {lastName} changed the name from {before} to {after}', {
          firstName,
          lastName,
          before,
          after,
        });
        break;
      }
      case 'description': {
        if (before && !after) {
          latestUpdateText = t('{firstName} {lastName} removed the description', { firstName, lastName });
        } else if (!before && after) {
          latestUpdateText = t('{firstName} {lastName} set description to {after}', { firstName, lastName, after });
        } else {
          latestUpdateText = t('{firstName} {lastName} changed the description from {before} to {after}', {
            firstName,
            lastName,
            before,
            after,
          });
        }
        break;
      }
      case 'hasDeskTickets': {
        if (after / 1 === 1) {
          latestUpdateText = t('{firstName} {lastName} added a link to a {teamworkDesk} ticket', {
            firstName,
            lastName,
            teamworkDesk: 'Teamwork Desk',
          });
        } else if (before / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} removed a link to a {teamworkDesk} ticket', {
            firstName,
            lastName,
            teamworkDesk: 'Teamwork Desk',
          });
        }
        break;
      }
      case 'priority': {
        if (before / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} set the priority to {after}', { firstName, lastName, after });
        } else if (after / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} removed the priority', { firstName, lastName });
        } else {
          latestUpdateText = t('{firstName} {lastName} changed the priority from {before} to {after}', {
            firstName,
            lastName,
            before,
            after,
          });
        }
        break;
      }
      case 'status': {
        switch (after) {
          case 'reopened': {
            if (after === 'reopened') {
              latestUpdateText = t('{firstName} {lastName} restored the task', { firstName, lastName });
            } else {
              latestUpdateText = t('{firstName} {lastName} restored the task', { firstName, lastName });
            }
            break;
          }
          case 'deleted': {
            latestUpdateText = t('{firstName} {lastName} deleted the task', { firstName, lastName });
            break;
          }
          case 'completed': {
            latestUpdateText = t('{firstName} {lastName} completed the task', { firstName, lastName });
            break;
          }
          case 'new': {
            latestUpdateText = t('{firstName} {lastName} created the task', { firstName, lastName });
            break;
          }
          default:
        }
        break;
      }
      case 'tagIds': {
        latestUpdateText = t('{firstName} {lastName} changed attached tags', { firstName, lastName });
        break;
      }
      case 'responsiblePartyIds': {
        latestUpdateText = t('{firstName} {lastName} changed who is responsible for the task', { firstName, lastName });
        break;
      }
      case 'responsibleParties': {
        latestUpdateText = t('{firstName} {lastName} changed who is responsible for the task', { firstName, lastName });
        break;
      }
      case 'dueDate': {
        if (after === '') {
          latestUpdateText = t('{firstName} {lastName} removed the due date', { firstName, lastName });
        } else {
          latestUpdateText = t('{firstName} {lastName} changed due date from {before} to {after}', {
            firstName,
            lastName,
            before: formatDate(DateTime.fromISO(before)),
            after: formatDate(DateTime.fromISO(after)),
          });
        }
        break;
      }
      case 'startDate': {
        if (after === null) {
          latestUpdateText = t('{firstName} {lastName} removed the start date', { firstName, lastName });
        } else if (before === null) {
          latestUpdateText = t('{firstName} {lastName} set start date to {after}', {
            firstName,
            lastName,
            after: formatDate(DateTime.fromISO(after)),
          });
        } else {
          latestUpdateText = t('{firstName} {lastName} changed start date from {before} to {after}', {
            firstName,
            lastName,
            before: formatDate(DateTime.fromISO(before)),
            after: formatDate(DateTime.fromISO(after)),
          });
        }
        break;
      }
      case 'progress': {
        if (before === 100) {
          latestUpdateText = t('{firstName} {lastName} reopened the task', { firstName, lastName });
        } else if (after === 100) {
          latestUpdateText = t('{firstName} {lastName} completed the task', { firstName, lastName });
        } else if (before / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} set the progress to {after}', { firstName, lastName, after });
        } else if (after / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} reset the progress to {after}', { firstName, lastName, after });
        } else {
          latestUpdateText = t('{firstName} {lastName} changed the progress from {before} to {after}', {
            firstName,
            lastName,
            before,
            after,
          });
        }
        break;
      }
      case 'projectId': {
        latestUpdateText = t('{firstName} {lastName} moved between projects', { firstName, lastName });
        break;
      }
      case 'columnId': {
        if (after / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} removed from board', { firstName, lastName });
        } else if (before / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} added to board', { firstName, lastName });
        } else {
          latestUpdateText = t('{firstName} {lastName} change board column', { firstName, lastName });
        }
        break;
      }
      case 'createdByUserId': {
        latestUpdateText = t('{firstName} {lastName} modified created-by property', { firstName, lastName });
        break;
      }
      case 'estimatedMinutes': {
        if (before / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} set the estimated time to {after}', {
            firstName,
            lastName,
            after: formatMinutes(after),
          });
        } else if (after / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} removed the estimated time', { firstName, lastName });
        } else {
          latestUpdateText = t('{firstName} {lastName} changed the estimated time from {before} {after}', {
            firstName,
            lastName,
            before: formatMinutes(before),
            after: formatMinutes(after),
          });
        }
        break;
      }
      // TODO fix parentTaskId strings
      case 'parentTaskId': {
        if (before / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} added task to tasklist', { firstName, lastName });
        } else if (after / 1 === 0) {
          latestUpdateText = t('{firstName} {lastName} removed the estimated time', { firstName, lastName });
        } else {
          latestUpdateText = t('{firstName} {lastName} change tasklist for task', { firstName, lastName });
        }
        break;
      }
      // TODO fix tasklistId strings
      case 'tasklistId': {
        latestUpdateText = t('{firstName} {lastName} changed attached task list', { firstName, lastName });
        break;
      }
      default:
    }
    return latestUpdateText;
  }

  /**
   * Returns relative date label for the given date string.
   * @param {string} dateString - ISO date string.
   * @returns {string} Label representing the relative date.
   */
  function getRelativeDate(dateString) {
    const date = DateTime.fromISO(dateString);
    const today = getToday();
    const isToday = isSameDay(date, today);
    const isYesterday = today.diff(date, 'days').days === 1;
    const isTomorrow = date.diff(today, 'days').days === 1;

    if (isToday) {
      return t('Today');
    }
    if (isYesterday) {
      return t('Yesterday');
    }
    if (isTomorrow) {
      return t('Tomorrow');
    }

    return formatDate(date);
  }

  /**
   * Returns the remaining tasks text based on the number of active tasks.
   * @param {number} numOfActiveTasks - Number of active tasks.
   * @returns {string} Text representing the remaining tasks.
   */
  function getRemainingTasksText(numOfActiveTasks) {
    if (numOfActiveTasks && numOfActiveTasks > 0) {
      return t('1 task left | {n} tasks left', { n: numOfActiveTasks });
    }
    return '';
  }

  /**
   * Returns the remaining tasks text for milestone tasklists.
   * @param {Array} tasklists - List of tasklists.
   * @returns {string} Text representing the remaining milestone tasks.
   */
  function getRemainingMilestoneTasks(tasklists) {
    if (!tasklists) {
      return '';
    }

    const totalActive = tasklists.reduce((sum, { stats }) => {
      return sum + (stats.active || 0);
    }, 0);

    return getRemainingTasksText(totalActive);
  }

  /**
   * Returns priority options based on the given priority level.
   * @param {string} priority - Priority level ('high', 'medium', 'low').
   * @returns {Object} Object containing label and background for the priority.
   */
  function getPriorityOptions(priority) {
    const option = {};

    switch (priority) {
      case 'high':
        option.label = t('High');
        option.background = 'var(--lsds-c-report-cell-color-critical)';
        break;
      case 'medium':
        option.label = t('Medium');
        option.background = 'var(--lsds-c-report-cell-color-warning)';
        break;
      case 'low':
        option.label = t('Low');
        option.background = 'var(--lsds-c-report-cell-color-success)';
        break;
      default:
        option.label = t('none');
        option.background = 'var(--lsds-c-report-cell-color-neutral)';
        break;
    }

    return option;
  }

  /**
   * Returns progress cell variant based on the progress value.
   * @param {number} progress - Progress percentage.
   * @returns {string} Variant type ('neutral', 'critical').
   */
  function getProgressCellVariant(progress) {
    switch (true) {
      case progress === 100:
        return 'neutral';
      case progress >= 0 && progress <= 99:
        return 'critical';
      default:
        return 'neutral';
    }
  }

  /**
   * Returns profit cell variant based on the profit value.
   * @param {number} profit - Profit value.
   * @returns {string} Variant type ('neutral', 'critical').
   */
  function getProfitCellVariant(profit) {
    switch (true) {
      case profit > 0:
        return 'neutral';
      case profit <= 0:
        return 'critical';
      default:
        return 'neutral';
    }
  }

  /**
   * Returns formatted project date range.
   * @param {string|DateTime} startDate - Project start date.
   * @param {string|DateTime} endDate - Project end date.
   * @returns {string} Formatted date range.
   */
  function getProjectDates(startDate, endDate) {
    const start = startDate instanceof DateTime ? startDate : DateTime.fromISO(startDate);
    const end = endDate instanceof DateTime ? endDate : DateTime.fromISO(endDate);
    return formatDateRange(Interval.fromDateTimes(start, end), 'mini');
  }

  /**
   * Returns options for project health.
   * @returns {Object} Object containing health options with labels and backgrounds.
   */
  function getProjectHealthOptions() {
    const options = {
      0: {
        label: t('Not set'),
        background: 'var(--lsds-c-report-cell-color-neutral)',
        key: 0,
      },
      1: {
        label: account.value.preferences.projectHealthRedLabel || t('Needs attention'),
        background: 'var(--lsds-c-report-cell-color-critical)',
        key: 1,
      },
      2: {
        label: account.value.preferences.projectHealthAmberLabel || t('At risk'),
        background: 'var(--lsds-c-report-cell-color-warning)',
        key: 2,
      },
      3: {
        label: account.value.preferences.projectHealthGreenLabel || t('Good'),
        background: 'var(--lsds-c-report-cell-color-success)',
        key: 3,
      },
    };

    return options;
  }

  /**
   * Returns status based on the current status and due date.
   * @param {string} status - Current status.
   * @param {DateTime} dueDate - Due date of the item.
   * @returns {string} Status label.
   */
  function getStatus(status, dueDate) {
    if (!['new', 'reopened'].includes(status)) {
      return status;
    }
    if (!dueDate) {
      return 'upcoming';
    }
    if (dueDate.hasSame(DateTime.now(), 'day')) {
      return 'today';
    }
    if (dueDate.endOf('day') > DateTime.now()) {
      return 'upcoming';
    }
    return 'late';
  }

  /**
   * Returns formatted status date or remaining time.
   * @param {DateTime} completedDate - Completed date.
   * @param {DateTime} dueDate - Due date.
   * @returns {string} Formatted status date or remaining time.
   */
  function getStatusDate(completedDate, dueDate) {
    const diff = DateTime.now().diff(dueDate.endOf('day'), ['months', 'days', 'hours']);
    const newCompletionDate = formatDate(completedDate);
    return newCompletionDate || calculateLeftOrOver(diff);
  }

  /**
   * Returns options for status based on status, due date, and completed date.
   * @param {string} status - Current status.
   * @param {DateTime|string} dueDate - Due date of the item.
   * @param {DateTime|string} completedDate - Completed date of the item.
   * @returns {Object} Object containing label, statusDate, and background.
   */
  function getStatusOptions(status, dueDate, completedDate) {
    if (!(dueDate instanceof DateTime)) {
      // eslint-disable-next-line no-param-reassign
      dueDate = DateTime.fromISO(dueDate);
    }
    if (!(completedDate instanceof DateTime)) {
      // eslint-disable-next-line no-param-reassign
      completedDate = DateTime.fromISO(completedDate);
    }

    const statusOption = getStatus(status, dueDate);
    const statusDate = getStatusDate(completedDate, dueDate);
    const option = {};

    switch (statusOption) {
      case 'completed':
        option.label = t('Completed');
        option.statusDate = statusDate;
        option.background = 'var(--lsds-c-report-cell-color-success)';
        break;
      case 'upcoming':
        option.label = t('Upcoming');
        option.statusDate = statusDate;
        option.background = 'var(--lsds-c-report-cell-color-warning)';
        break;
      case 'today':
        option.label = t('Today');
        option.statusDate = statusDate;
        option.background = 'var(--lsds-c-report-cell-color-warning)';
        break;
      case 'late':
        option.label = t('Late');
        option.statusDate = statusDate;
        option.background = 'var(--lsds-c-report-cell-color-critical)';
        break;
      default:
    }
    return option;
  }

  /**
   * Returns task completion variant based on the percentage.
   * @param {number} percentage - Completion percentage.
   * @returns {string} Variant type ('critical', 'neutral').
   */
  function getTaskCompletionVariant(percentage) {
    return percentage >= 0 && percentage <= 49 ? 'critical' : 'neutral';
  }

  /**
   * Returns names of the task lists.
   * @param {string|Array} taskLists - List of tasklists or a string.
   * @returns {string} Comma-separated task list names.
   */
  function getTaskListNames(taskLists) {
    if (typeof taskLists === 'string') {
      return taskLists;
    }

    return taskLists?.map((taskList) => taskList.name).join(', ') ?? '';
  }

  /**
   * Returns time left based on number of days and completion status.
   * @param {number} numOfDays - Number of days left.
   * @param {boolean} [isCompleted=false] - Whether the task is completed.
   * @returns {string} Time left or completion status.
   */
  function getTimeLeft(numOfDays, isCompleted = false) {
    if (isCompleted) {
      return t('Completed');
    }

    const deadline = DateTime.now().plus({ days: numOfDays });
    const timeDiff = DateTime.now().diff(deadline, ['months', 'days']);

    if (timeDiff.months || Math.abs(timeDiff.days) > 25) {
      const monthDiff = timeDiff.shiftTo('months');
      const roundedMonthDiff = Duration.fromObject({ months: Math.round(monthDiff.months) });
      return calculateLeftOrOver(roundedMonthDiff);
    }
    const dayDiff = timeDiff.shiftTo('days');
    const roundedDayDiff = Duration.fromObject({ days: Math.round(dayDiff.days) });
    return calculateLeftOrOver(roundedDayDiff);
  }

  /**
   * Returns dot color based on the number of days left.
   * @param {number} numOfDays - Number of days left.
   * @returns {string} Color value for the dot.
   */
  function getTimeLeftDotColor(numOfDays) {
    switch (true) {
      case numOfDays <= 0:
        return 'var(--lsds-c-report-cell-color-critical)';
      case numOfDays < 5:
        return 'var(--lsds-c-report-cell-color-warning)';
      default:
        return 'var(--lsds-c-report-cell-color-success)';
    }
  }

  /**
   * Returns translated calendar event type.
   * @param {string} calendarEventType - Type of calendar event.
   * @returns {string} Translated event type.
   */
  function getTranslatedCalendarEventType(calendarEventType) {
    switch (calendarEventType) {
      case 'Meeting':
        return t('Meeting');
      case 'Other':
        return t('Other');
      case 'Paid Time Off':
        return t('Paid time off');
      case 'Public Holiday':
        return t('Public holiday');
      case 'Sick Leave':
        return t('Sick leave');
      case 'Training':
        return t('Training');
      default:
        return calendarEventType;
    }
  }

  /**
   * Returns cell variant based on utilisation percentage.
   * @param {number} percentage - Utilisation percentage.
   * @returns {string} Variant type ('warning', 'critical', 'neutral').
   */
  function getUtilisationCellVariant(percentage) {
    switch (true) {
      case percentage > 100:
        return 'warning';
      case percentage >= 0 && percentage < 50:
        return 'critical';
      default:
        return 'neutral';
    }
  }

  /**
   * Checks if there are any assignees in the input object.
   * @param {Object} inputAssignees - Object containing assignees.
   * @returns {boolean} True if there are assignees, false otherwise.
   */
  function hasAssignees(inputAssignees) {
    if (!inputAssignees) {
      return false;
    }
    return Object.keys(inputAssignees).some(
      (key) => Array.isArray(inputAssignees[key]) && inputAssignees[key].length > 0,
    );
  }

  /**
   * Opens a quick view based on the provided type and id.
   * @param {string} type - Type of item to open ('task', 'milestone', 'project', etc.).
   * @param {string|number} id - Identifier for the item to open.
   * @param {...any} args - Additional arguments based on the type.
   * @returns {null|Object} Returns null or result from showLegacyQuickView.
   */
  function openQuickView(type, id, ...args) {
    let config = {};

    if (type === 'task') {
      openQuickViewRoute(`/tasks/${id}`);
      return null;
    }

    switch (type) {
      case 'milestone': {
        if (lightspeedMilestoneDrawerEnabled.value) {
          openQuickViewRoute(`/milestones/${id}`);
          return null;
        }

        config = {
          name: 'milestone',
          params: {
            id,
            milestoneId: id,
            noLink: true,
            isQuickView: true,
            keepUrl: true,
          },
        };

        break;
      }
      case 'project': {
        const [name] = args;

        config = {
          name: 'project',
          params: {
            id,
            title: name,
            titleLink: `projects/${id}`,
            projectId: id,
            isQuickView: true,
            keepUrl: true,
          },
        };
        break;
      }
      case 'audit': {
        const [itemType] = args;

        config = {
          name: 'audit',
          params: {
            title: t('Change history'),
            itemType,
            itemId: id,
            isQuickView: true,
            keepUrl: true,
          },
        };
        break;
      }
      case 'tasks': {
        const [filterType, sortType, key] = args;

        config = {
          name: 'tasks',
          params: {
            [key]: id,
            filterType,
            title: t('Tasks'),
            sortType,
            includeToday: true,
            showProjectHeaders: true,
          },
        };
        break;
      }
      case 'tasklists': {
        const [tasklists] = args;

        const singleOrMultiple = Array.isArray(tasklists) ? tasklists.map((i) => i.id) : [tasklists];
        config = {
          name: 'tasks',
          params: {
            title: t('All tasks'),
            projectId: id,
            taskListIds: singleOrMultiple,
            filterType: 'all',
            isQuickView: true,
            keepUrl: true,
          },
        };
        break;
      }
      default:
        return null;
    }
    return showLegacyQuickView(config);
  }

  /**
   * Transforms selected columns by adding prefix for custom fields.
   * @param {string} selectedColumns - Comma-separated column identifiers.
   * @returns {string} Transformed columns string.
   */
  function transformProjectCustomFieldValues(items) {
    return items.map((item) => {
      const customFields = item.customFieldValues?.reduce((acc, cfValue) => {
        if (cfValue.customField?.id) {
          acc[cfValue.customField.id] = { ...cfValue };
        }
        return acc;
      }, {});
      return { ...item, customFields };
    });
  }

  /**
   * Transforms selected columns by adding prefix for custom fields.
   * @param {string} selectedColumns - Comma-separated column identifiers.
   * @returns {string} Transformed columns string.
   */
  function transformColumnsWithCustomFieldsForExport(selectedColumns) {
    return selectedColumns
      .split(',')
      .map((item) => {
        const number = Number(item);
        if (Number.isInteger(number)) {
          return `customfield${item}`;
        }
        return item;
      })
      .join(',');
  }

  /**
   * Returns class used to set the text color on dates
   * @param {DateTime} date
   * @returns {string} used for date color on date cells
   */
  function getDateColor(date) {
    const isToday = isSameDay(date, getToday());
    if (isToday) {
      return 'text-success';
    }
    if (date < getToday()) {
      return 'text-critical';
    }
    return '';
  }

  /**
   * Returns formatted profit value based on profit type.
   * @param {string} profitType - 'percentage' or 'amount'.
   * @param {number} billable - Total billable amount.
   * @param {number} cost - Total cost.
   * @param {number} profit - Calculated profit.
   * @returns {Object} { value: number, formattedValue: string }
   */
  function getFormattedProfit(profitType, billable, cost, profit) {
    if (profitType === 'percentage') {
      if (billable === 0) {
        return { value: 0, formattedValue: formatPercentage(0) };
      }
      const profitPercentage = (billable - cost) / billable;
      return { value: profitPercentage, formattedValue: formatPercentage(profitPercentage) };
    }
    return { value: profit, formattedValue: formatCurrency(profit) };
  }

  const dateTimeSerializer = {
    read: (datesData) => {
      const data = JSON.parse(datesData);
      return {
        range: data.range,
        startDate: data.startDate ? DateTime.fromISO(data.startDate) : null,
        endDate: data.endDate ? DateTime.fromISO(data.endDate) : null,
      };
    },
    write: (datesData) => {
      const data = {
        range: datesData.range,
        startDate: datesData.startDate ? datesData.startDate.toISO() : null,
        endDate: datesData.endDate ? datesData.endDate.toISO() : null,
      };
      return JSON.stringify(data);
    },
  };

  /**
   * Gets the formatting function for the reporting charts custom date range
   * @param {startDate} datetime object for start date
   * @param {endDate}  datetime object for end date
   * @returns date format to use in report chart for custom date range
   */
  function getCustomDateLabelFormat(startDate, endDate) {
    const diffDays = endDate.diff(startDate, 'days').days;

    if (diffDays < 7) {
      return startDate.toFormat('d MMMM');
    }
    if (diffDays > 31) {
      return startDate.toFormat('MMMM');
    }
    return startDate.toFormat('dd MM');
  }

  /**
   * Returns array of enabled column names
   * @param {Array} columns - Array of column objects
   * @returns {Array} Array of enabled column names
   */
  function getEnabledColumnNames(columns) {
    return columns.map((col) => (col.enabled ? col.name : null)).filter(Boolean);
  }

  return {
    getAssigneeAvatars,
    getCompletedByName,
    getCustomDateLabelFormat,
    getDateColor,
    getDifference,
    getEnabledColumnNames,
    getFormattedProfit,
    getLatestActivityHoverTextTasks,
    getLatestActivityHoverTextMilestones,
    getPriorityOptions,
    getProgressCellVariant,
    getProfitCellVariant,
    getProjectDates,
    getProjectHealthOptions,
    getRelativeDate,
    getRemainingTasksText,
    getRemainingMilestoneTasks,
    getStatus,
    getStatusDate,
    getStatusOptions,
    getTaskCompletionVariant,
    getTaskListNames,
    getTimeLeft,
    getTimeLeftDotColor,
    getTranslatedCalendarEventType,
    getUtilisationCellVariant,
    hasAssignees,
    openQuickView,
    transformProjectCustomFieldValues,
    transformColumnsWithCustomFieldsForExport,
    dateTimeSerializer,
  };
}
