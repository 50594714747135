<script setup>
const props = defineProps({
  projectId: {
    type: Number,
    default: 0,
  },
  customFields: {
    type: Array,
    default: () => [],
  },
  noCustomFields: {
    type: Boolean,
    default: true,
  },
  searchLoading: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({ type: Boolean, default: false });
const searchTerm = defineModel('searchTerm', {
  type: String,
  default: '',
});

const FilterCustomFieldMenuCard = defineAsyncComponent(() => import('./FilterCustomFieldMenuCard.vue'));
</script>

<template>
  <WidgetMenu v-model="modelValue" :closeOnContentClick="false" location="bottom left">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default>
      <FilterCustomFieldMenuCard v-bind="props" v-model:searchTerm="searchTerm" />
    </template>
  </WidgetMenu>
</template>
