import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function toApiPaylaod(formState) {
  const payload = {};

  if (Object.hasOwn(formState.value, 'capacity')) {
    payload.capacity = formState.value.capacity;
  }
  if (Object.hasOwn(formState.value, 'budgetCategory')) {
    payload.budgetCategory =
      formState.value.budgetCategory === 'retainer' ? 'STANDARD' : formState.value.budgetCategory.toUpperCase();
  }
  if (Object.hasOwn(formState.value, 'project')) {
    payload.projectId = formState.value.project.id;
  }
  if (Object.hasOwn(formState.value, 'startDateTime')) {
    payload.startDateTime = formState.value.startDateTime.toISODate();
  }
  if (Object.hasOwn(formState.value, 'type')) {
    payload.type = formState.value.type.toUpperCase();
  }
  if (Object.hasOwn(formState.value, 'timelogType')) {
    payload.timelogType = formState.value.timelogType.toUpperCase();
  }
  if (Object.hasOwn(formState.value, 'isRepeating')) {
    payload.isRepeating = formState.value.isRepeating;
  }
  if (Object.hasOwn(formState.value, 'isRetainer')) {
    payload.isRetainer = formState.value.isRetainer;
  }
  if (Object.hasOwn(formState.value, 'endDateTime') && !formState.value?.isRepeating) {
    payload.endDateTime = formState.value.endDateTime?.plus({ days: 1 }).toISODate();
  }
  if (Object.hasOwn(formState.value, 'completedAt') && formState.value?.isRepeating) {
    payload.completedAt = formState.value.completedAt?.plus({ days: 1 }).toISODate();
  }
  if (Object.hasOwn(formState.value, 'budgetProfitMargin') && formState.value?.budgetProfitMargin) {
    payload.budgetProfitMargin = formState.value.budgetProfitMargin;
  }
  if (Object.hasOwn(formState.value, 'shouldUpdateProjectRate') && formState.value?.projectRate) {
    payload.projectRate = formState.value.projectRate;
  }
  if (Object.hasOwn(formState.value, 'repeatPeriod') && formState.value?.isRepeating) {
    payload.repeatPeriod = formState.value.repeatPeriod;
  }
  if (Object.hasOwn(formState.value, 'repeatUnit') && formState.value?.isRepeating) {
    payload.repeatUnit = formState.value.repeatUnit;
  }
  if (Object.hasOwn(formState.value, 'id') && formState.value?.id) {
    payload.id = formState.value.id;
  }
  if (Object.hasOwn(formState.value, 'carryUnderspend') && formState.value?.isRetainer) {
    payload.carryUnderspend = formState.value.carryUnderspend;
  }
  if (Object.hasOwn(formState.value, 'carryOverspend') && formState.value?.isRetainer) {
    payload.carryOverspend = formState.value.carryOverspend;
  }
  if (Object.hasOwn(formState.value, 'enableNotifications') && formState.value?.enableNotifications) {
    payload.notifications = formState.value.notifications.map((notification) => {
      return {
        capacityThreshold: notification.capacityThreshold,
        notificationMedium: notification.notificationMedium,
        companyIds: [],
        teamIds: notification.teams.map((team) => team.id),
        userIds: notification.users.map((notificationUser) => notificationUser.id),
      };
    });
  }
  return payload;
}

export function useProjectBudgetActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  return {
    createProjectBudget(formState) {
      const budget = toApiPaylaod(formState);
      const promise = api
        .post(
          '/projects/api/v3/projects/budgets.json',
          { budget },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('Failed to create a project budget'),
          },
        )
        .then((response) => {
          const {
            data: { budget: createdBudget },
          } = response;
          emitRealTimeUpdate({
            type: 'budget',
            action: 'new',
            budgetId: createdBudget.id,
            projectId: createdBudget.projectId,
          });
          return response;
        });

      emitOptimisticUpdate({
        promise,
        type: 'budget',
        action: 'create',
        budget: {
          ...budget,
          id: -1,
          capacityUsed: 0,
        },
      });

      return promise;
    },
    updateProjectBudget(formState, isEnding = false) {
      const budget = toApiPaylaod(formState, isEnding);
      const promise = api
        .patch(
          `/projects/api/v3/projects/budgets/${budget.id}.json`,
          {
            budget: {
              ...budget,
              notifications: budget.notifications?.map((notification) => {
                return {
                  id: notification.id,
                  capacityThreshold: notification.capacityThreshold,
                  notificationMedium: notification.notificationMedium,
                  userIds: notification.userIds,
                };
              }),
            },
          },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('Failed to update a project budget'),
          },
        )
        .then((response) => {
          const {
            data: { budget: updatedBudget },
          } = response;
          emitRealTimeUpdate({
            type: 'budget',
            action: 'updated',
            budgetId: updatedBudget.id,
            projectId: updatedBudget.projectId,
          });
          return response;
        });

      emitOptimisticUpdate({
        promise,
        type: 'budget',
        action: 'update',
        budget,
      });

      return promise;
    },
    deleteProjectBudget(budget) {
      const promise = api
        .delete(`/projects/api/v3/projects/budgets/${budget.id}.json`, {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to delete project budget'),
        })
        .then((response) => {
          emitRealTimeUpdate({
            type: 'budget',
            action: 'deleted',
            budgetId: budget.id,
            projectId: budget.projectId,
          });
          return response;
        });

      emitOptimisticUpdate(promise, 'delete', budget);

      return promise;
    },
  };
}
