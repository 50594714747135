<script setup>
import { useRouter } from 'vue-router';
import { useCohort, useCurrentAccount, useExperimentE2404, usePreferences } from '@/api';
import { getToday, useI18n } from '@/util';
import { useSampleProjects } from '../sampleProjects/useSampleProjects';
import AppShellTrialFreeTrainingBannerIcon from './AppShellTrialFreeTrainingBannerIcon.svg';

const router = useRouter();
const { isInFreeTrial, isSiteOwner, isOwnerAdmin: canAccessCheckout } = useCohort();
const { t, formatCurrency, formatDate } = useI18n();
const account = useCurrentAccount();
const { isExpE2404Variation, isExpE2404VariationFinancial, trackEventE2404 } = useExperimentE2404();
const { trialFreeTrainingBannerDismissedCount, sampleProjectsBannerDismissed } = usePreferences();
const { isSampleProjectsBannerVisible } = useSampleProjects();
const today = getToday();

const showTrialFreeTrainingBanner = shallowRef(true);

const isTrialFreeTrainingBannerVisible = computed(() => {
  return (
    (isExpE2404Variation.value || isExpE2404VariationFinancial.value) &&
    showTrialFreeTrainingBanner.value &&
    trialFreeTrainingBannerDismissedCount.value.count < 3 &&
    isSiteOwner.value &&
    isInFreeTrial.value &&
    // Temp fix: hide when 1st day of trial or sampleProjects banners are visible, avoid banner stack
    !(
      formatDate(account.value.dateSignedUp, 'medium') === formatDate(today, 'medium') ||
      (isSampleProjectsBannerVisible.value && !sampleProjectsBannerDismissed.value)
    )
  );
});

const bannerMessage = computed(() => {
  let message = '';
  if (isExpE2404Variation.value) {
    message = t(
      'Purchase {teamwork} today to redeem your complimentary onboarding service | Purchase {teamwork} within {trialDaysRemaining} days to redeem your complimentary onboarding service',
      {
        teamwork: 'Teamwork.com',
        trialDaysRemaining: account.value.trialDaysRemaining,
      },
    );
  }
  if (isExpE2404VariationFinancial.value) {
    message = t(
      'Purchase {teamwork} today to redeem your complimentary onboarding service (up to {price} value) | Purchase {teamwork} within {trialDaysRemaining} days to redeem your complimentary onboarding service (up to {price} value)',
      {
        teamwork: 'Teamwork.com',
        trialDaysRemaining: account.value.trialDaysRemaining,
        price: formatCurrency(5000),
      },
    );
  }
  return message;
});

function trackDismissEvent() {
  trackEventE2404('free_training_banner_dismissed');
}

function dismissBanner() {
  showTrialFreeTrainingBanner.value = false;
  trialFreeTrainingBannerDismissedCount.value = {
    ...trialFreeTrainingBannerDismissedCount.value,
    count: trialFreeTrainingBannerDismissedCount.value.count + 1,
  };
  trackDismissEvent();
}

function comparePlansClicked() {
  trackEventE2404('compare_plans_clicked');
  if (canAccessCheckout.value) {
    router.push({ path: '/redirect/checkout' });
  }
}
</script>

<template>
  <LscAlert
    v-if="isTrialFreeTrainingBannerVisible"
    layout="banner"
    variant="info-subdued"
    closable
    @close="dismissBanner"
  >
    <template #media>
      <AppShellTrialFreeTrainingBannerIcon />
    </template>
    <p class="pl-1 pt-2 font-semibold">
      {{ bannerMessage }}
    </p>
    <template #action>
      <LscAlertButton
        class="bg-[--free-implementation-iterations-button-color] hover:bg-[--free-implementation-iterations-button-hover-color]"
        @click="comparePlansClicked"
      >
        {{ t('Compare Plans') }}
      </LscAlertButton>
    </template>
  </LscAlert>
</template>
