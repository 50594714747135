<script setup>
import { refDebounced } from '@vueuse/core';
/* TBD - useTemplateTasklistsV3Loader */
import { useProjectSamplesV3Loader, useProjectTemplatesV3Loader } from '@/api';
import { useI18n } from '@/util';
import TemplatesDrawerProjectsBlankSlate from './TemplatesDrawerProjectsBlankSlate.vue';
import TemplatesDrawerProjectsList from './TemplatesDrawerProjectsList.vue';
import TemplatesDrawerProjectsSamplesList from './TemplatesDrawerProjectsSamplesList.vue';
import TemplatesDrawerTaskLists from './TemplatesDrawerTaskLists.vue';

const props = defineProps({
  searchTerm: {
    type: String,
    default: '',
  },
});
const { searchTerm } = toRefs(props);
const debouncedSearchTerm = refDebounced(searchTerm, 300);

const { t } = useI18n();

const customTemplateProjects = {
  step: 10,
  count: 10,
  state: useProjectTemplatesV3Loader({
    count: Infinity,
    pageSize: 500,
    params: computed(() => ({
      searchTerm: debouncedSearchTerm.value,
      include: 'companies',
      includeProjectUserInfo: true,
      'fields[projects]': 'id,name,isStarred,companyId,startPage',
      orderBy: 'lastWorkedOn',
      orderMode: 'desc',
      onlyStarredProjects: false,
    })),
  }),
};

const sampleTemplateProjects = {
  step: 100,
  count: Infinity,
  state: useProjectSamplesV3Loader({
    count: Infinity,
    pageSize: 50,
    params: computed(() => ({
      searchTerm: debouncedSearchTerm.value,
      'fields[sampleprojects]': 'id,name,categoryId,description,imagePreview,code',
    })),
  }),
};

const searchHasNoResultsForProjectsOrSamples = computed(() => {
  if (
    searchTerm.value.length &&
    customTemplateProjects.state.loaded.value &&
    sampleTemplateProjects.state.loaded.value &&
    customTemplateProjects.state.items.value.length === 0 &&
    sampleTemplateProjects.state.items.value.length === 0
  ) {
    return true;
  }
  return false;
});
</script>

<template>
  <TemplatesDrawerProjectsList
    :count="customTemplateProjects.count"
    :step="customTemplateProjects.step"
    :state="customTemplateProjects.state"
  >
    <template #resultsIntro>
      <VListSubheader v-if="searchTerm" class="m-0 pb-0 pl-6 pt-5">
        {{ t('Your template projects') }}
        &mdash; {{ t('results') }}
      </VListSubheader>
    </template>
    <template #blank>
      <TemplatesDrawerProjectsBlankSlate v-if="!searchTerm" />
      <template v-else><div /></template>
      <!--- Note: div because empty won't work -->
    </template>
  </TemplatesDrawerProjectsList>

  <TemplatesDrawerTaskLists
    v-if="searchTerm"
    :searchTerm="searchTerm"
    :searchHasNoResultsForProjectsOrSamples="searchHasNoResultsForProjectsOrSamples"
  />
  <TemplatesDrawerProjectsSamplesList
    :count="sampleTemplateProjects.count"
    :step="sampleTemplateProjects.step"
    :state="sampleTemplateProjects.state"
    :searchTerm="searchTerm"
  >
    <template #blank>&nbsp;</template>
  </TemplatesDrawerProjectsSamplesList>
</template>
