import './VChip.variants.css';

export const VChipNoBg = {
  class: 'VChipNoBg',
  variant: 'outlined',
};

export const VChipListViewItem = {
  class: 'VChipListViewItem',
  variant: 'outlined',
};

export const VChipFilter = {
  class: 'VChipFilter',
};

export const VChipLabel = {
  class: 'VChipLabel',
  size: 'small',
  color: 'var(--lsds-a-color-decorative-2)',
  rounded: 'md',
};

export const VChipLabelTruncate = {
  class: 'VChipLabelTruncate',
};
