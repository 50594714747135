<script setup>
import { useCurrentUserState, useHandle401 } from '@/api';
import { provideDocumentTitle, useI18n } from '@/util';
import AppShellError from './AppShellError.vue';
import AppShellLoading from './AppShellLoading.vue';
import AppShellUnauthenticated from './AppShellUnauthenticated.vue';
import AppShellToastContainer from './toast/AppShellToastContainer.vue';

const { got401 } = useHandle401();

const { ready: localizationReady, error: localizationError } = useI18n();
const { item: user, error: userError } = useCurrentUserState();

const isReady = computed(() => localizationReady.value && Boolean(user.value));
const hasError = computed(() =>
  // Report a localization error only once we have loaded the current user
  // because only then we can be sure that we used a valid language code.
  [Boolean(user.value) && localizationError, userError].some((error) => error.value),
);

provideDocumentTitle();
</script>

<template>
  <AppShellError v-if="hasError" />
  <AppShellLoading v-else-if="!isReady" />
  <AppShellUnauthenticated
    v-else-if="got401"
    :message="t('Please log in to {teamwork} to continue using this view', { teamwork: 'Teamwork.com' })"
  />
  <RouterView v-else />
  <AppShellToastContainer />
</template>
