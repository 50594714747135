import { DateTime } from 'luxon';
import { useFileActions } from '@/api';
import { useI18n } from '@/util';

/**
 * @param {Object} params
 * @param {number?} params.maxUploadSize Files over this size will not be uploaded
 * @param {boolean} params.checkForStorageSpace If true, will check if there is enough space to upload files before uploading
 */
export function useFileUploads({ maxUploadSize = Infinity, checkForStorageSpace = true } = {}) {
  const toast = useLsToast();
  const { t, formatFileSize } = useI18n();
  const fileActions = useFileActions();

  // We need to keep this as a ref to let us update individual file upload progress
  // eslint-disable-next-line lightspeed/prefer-shallow-ref
  const filesToUpload = ref([]);

  const isUploading = shallowRef(false);
  const showSelectExistingFileDialog = shallowRef(false);

  /** The S3 refs to link files to an entity */
  const fileRefs = computed(() => filesToUpload.value.map(({ fileRef }) => fileRef).filter(Boolean));

  const fileMetaList = computed(() =>
    filesToUpload.value.map((fileObj) => {
      return {
        mode: 'client',
        fileId: 0,
        fileName: fileObj.file.name,
        displayName: fileObj.file.name,
        src: '0',
        uploadedAt: DateTime.now(),
        uploadDate: DateTime.now(),
        isUploading: fileObj.isUploading,
        uploadProgress: fileObj.uploadProgress,
        size: fileObj.file.size,
        fileSizeInBytes: fileObj.file.size,
        fileSize: formatFileSize(fileObj.file.size),
        fileRef: fileObj.fileRef,
        extraData: fileObj.extraData,
      };
    }),
  );

  function removeFile(fileRef) {
    filesToUpload.value = filesToUpload.value.filter((file) => file.fileRef !== fileRef);
  }

  function resetFiles() {
    filesToUpload.value = [];
  }

  async function uploadFile(fileObj) {
    if (fileObj.fileRef || fileObj.file.size > maxUploadSize) {
      return null;
    }

    if (checkForStorageSpace) {
      const hasEnoughSpace = await fileActions.hasSpaceToUpload(fileObj.file.size);
      if (!hasEnoughSpace) {
        toast.critical(t("You have reached your plan's allocated file storage"));
        return null;
      }
    }

    // TODO: check allowed file types
    // presign file to be able to upload
    const {
      data: { url: presignedUrl, ref: presignedRef },
    } = await fileActions.getPresignedUrl({
      file: fileObj.file,
    });

    // eslint-disable-next-line no-param-reassign
    fileObj.fileRef = presignedRef;
    // eslint-disable-next-line no-param-reassign
    fileObj.isUploading = true;

    try {
      await fileActions.uploadFile(presignedUrl, fileObj.file, {
        onUploadProgress: (event) => {
          if (event.lengthComputable) {
            // eslint-disable-next-line no-param-reassign
            fileObj.uploadProgress = Math.round((event.loaded / event.total) * 100);
          }
        },
      });
    } finally {
      // eslint-disable-next-line no-param-reassign
      fileObj.isUploading = false;
    }

    return { ref: presignedRef };
  }

  /**
   *
   * @param {FileList} files
   * @param {Object|undefined} extraData
   * @returns
   */
  function wrapFile(file, extraData) {
    return {
      file,
      isUploading: false,
      uploadProgress: 0,
      mode: 'client',
      fileRef: '',
      extraData,
    };
  }

  /**
   * Uploads files to the server
   * @param {FileList} files The files to upload
   * @param {Object|undefined} extraData Extra data to attach to each file object
   * @returns {PromiseSettledResult<unknown>[]}
   */
  async function uploadFiles(files, extraData) {
    const newFiles = Array.from(files)?.map((file) => wrapFile(file, extraData));
    try {
      isUploading.value = true;
      const promises = [];
      filesToUpload.value = [...filesToUpload.value, ...newFiles];
      filesToUpload.value.forEach((file) => {
        promises.push(uploadFile(file));
      });

      const result = await Promise.allSettled(promises);
      filesToUpload.value = filesToUpload.value.filter((file) => file.fileRef !== '');
      return result;
    } finally {
      isUploading.value = false;
    }
  }

  return {
    isUploading,
    showSelectExistingFileDialog,
    fileMetaList,
    filesToUpload,
    fileRefs,
    uploadFiles,
    removeFile,
    resetFiles,
  };
}
