<script setup>
import { useI18n } from '@/util';
import LscEmptyState from '../emptyState/LscEmptyState.vue';
import WidgetLoadingProgress from './WidgetLoadingProgress.vue';

// Helps to manage and render the state of a single item or list loader.

// TODO Remove it!
// We should stop passing attrs which Vue will ignore instead of disabling the warnings.
defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  // The `state` object from `useItemLoader` or `useListLoader`.
  state: {
    type: Object,
    required: true,
  },
  loadingMessage: {
    type: String,
    default: undefined,
  },
  blankTitle: {
    type: String,
    default: undefined,
  },
  blankMessage: {
    type: String,
    default: undefined,
  },
  errorTitle: {
    type: String,
    default: undefined,
  },
  errorMessage: {
    type: String,
    default: undefined,
  },
});

const { t } = useI18n();
const uiState = computed(() => {
  const item = unref(props.state.item);
  const items = unref(props.state.items);
  const loaded = unref(props.state.loaded);
  const error = unref(props.state.error);

  if (error) {
    return 'error';
  }
  if (!loaded) {
    return 'loading';
  }
  if (items ? items.length === 0 : item === null) {
    return 'blank';
  }
  return 'default';
});
</script>

<template>
  <!--
    This slot is particularly useful when the default and loading states need to look very similar,
    for example when we load data for a table, we display skeleton rows instead of a loading spinner.
  -->
  <slot
    v-if="$slots.defaultOrLoading && (uiState === 'default' || uiState === 'loading')"
    name="defaultOrLoading"
    :loading="uiState === 'loading'"
  />
  <slot v-else-if="uiState === 'default'" name="default" />
  <slot v-else-if="uiState === 'blank'" name="blank">
    <LscEmptyState class="my-4" :title="blankTitle" :message="blankMessage" />
  </slot>
  <slot v-else-if="uiState === 'error'" name="error">
    <LscEmptyState variant="error" class="my-4" :title="errorTitle ?? t('Loading failed')" :message="errorMessage">
      <template #actions>
        <LscButton variant="primary" @click="state.retry">
          {{ t('Retry') }}
        </LscButton>
      </template>
    </LscEmptyState>
  </slot>
  <slot v-else-if="uiState === 'loading'" name="loading">
    <WidgetLoadingProgress :message="loadingMessage" />
  </slot>
</template>
