<script setup>
import { sanitizeUrl } from '@/util';
import LscOverflowEllipsis from '../../infodisplay/overflow/LscOverflowEllipsis.vue';
import { useLscTabs } from './useLscTabs';

const props = defineProps({
  /**
   * The unique identifier for the tab.
   * @type {PropType<String>}
   */
  dataIdentifier: {
    type: String,
    default: undefined,
  },
  /**
   * Whether the tab is disabled.
   * @type {PropType<Boolean>}
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * The icon to prepend to the label.
   * @type {PropType<LscIcon|undefined>}
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * The icon to append to the label.
   * @type {PropType<LscIcon|undefined>}
   */
  appendIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Used to set the item's active state.
   * @type {PropType<Boolean>}
   */
  active: {
    type: Boolean,
    default: false,
  },
  /**
   * The URL to navigate to.
   * @type {PropType<String>}
   */
  href: {
    type: String,
    default: undefined,
    validator(value, { to }) {
      if (value && to) {
        // eslint-disable-next-line no-console
        console.warn('Only `href` or `to` should be provided');
        return false;
      }

      return true;
    },
  },
  /**
   * The Vue Router link to navigate to.
   * @type {PropType<String|Object>}
   */
  to: {
    type: [String, Object],
    default: undefined,
    validator(value, { href }) {
      if (value && href) {
        // eslint-disable-next-line no-console
        console.warn('Only `href` or `to` should be provided');
        return false;
      }

      return true;
    },
  },
});

const componentToRender = computed(() => {
  if (props.to) {
    return 'RouterLink';
  }
  if (props.href) {
    return 'a';
  }
  return 'button';
});

const { variant, justified } = useLscTabs();

const iconSize = computed(
  () =>
    ({
      primary: 'md',
      secondary: 'sm',
      segmented: 'md',
    })[variant.value],
);

const lscTabVariantStyleConfig = tv({
  base: [
    'inline-flex content-center items-center justify-center overflow-hidden no-underline transition-colors',
    'focus:outline-focus-primary',
    'aria-disabled:cursor-not-allowed aria-disabled:font-normal',
  ],
  slots: {
    icon: 'shrink-0',
  },
  variants: {
    justified: {
      true: {
        base: 'flex-1',
      },
    },
    variant: {
      primary: {
        base: [
          'min-h-8 gap-2 border-b px-4 text-subtle hover:text-primary focus-visible:text-primary',
          'aria-selected:border-primary-default aria-selected:font-medium aria-selected:text-primary',
          'aria-disabled:text-disabled',
        ],
      },
      secondary: {
        base: [
          'min-h-7 gap-1 rounded-md px-2 py-1 text-body-2 text-subtle',
          'hover:bg-surface-emphasis-hover hover:text-primary focus-visible:bg-surface-emphasis-hover focus-visible:text-primary',
          'aria-selected:bg-surface-emphasis-selected aria-selected:font-medium aria-selected:text-primary',
          'aria-disabled:text-disabled',
        ],
      },
      segmented: {
        base: [
          'transition-border flex min-h-7 gap-2 rounded-md border border-transparent px-4 text-subtle hover:border-bold focus-visible:border-bold',
          'aria-selected:border-bold aria-selected:bg-default aria-selected:font-medium',
          'aria-disabled:text-disabled',
        ],
      },
    },
  },
});

const classes = computed(() =>
  lscTabVariantStyleConfig({
    variant: variant.value,
    justified: justified.value,
  }),
);
</script>
<template>
  <Component
    :is="componentToRender"
    role="tab"
    :class="classes.base()"
    :type="componentToRender === 'button' ? 'button' : undefined"
    :aria-selected="active"
    :aria-disabled="disabled"
    :disabled="disabled"
    :to="to"
    :href="sanitizeUrl(href)"
    :data-identifier="dataIdentifier"
  >
    <slot name="prepend">
      <LscIcon v-if="prependIcon" :size="iconSize" :icon="prependIcon" :class="classes.icon()" />
    </slot>
    <LscOverflowEllipsis>
      <slot />
    </LscOverflowEllipsis>
    <slot name="append">
      <LscIcon v-if="appendIcon" :size="iconSize" :icon="appendIcon" :class="classes.icon()" />
    </slot>
  </Component>
</template>
