import nativeAxios from 'axios';
import { useI18n } from '@/util';
import { useCurrentAccount } from '../account/useCurrentAccount';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useFileActions() {
  const api = useAxios();
  const { t } = useI18n();
  const toast = useLsToast();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();
  const account = useCurrentAccount();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  function deleteFile(file) {
    const url = `/projects/api/v3/files/${file.id}.json`;

    const promise = api.delete(url, { ...config(), errorMessage: t('Failed to delete the file') }).then(() =>
      emitRealTimeUpdate({
        type: 'file',
        action: 'edited',
        projectId: file.projectId,
        fileId: file.id,
        affectedTaskIds: [file.taskId],
      }),
    );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file,
    });
    return promise;
  }

  function deleteFileVersion(file) {
    const url = `/fileversions/${file.fileVersionId}.json`;

    const promise = api.delete(url, { ...config(), errorMessage: t('Failed to delete the file version') }).then(() =>
      emitRealTimeUpdate({
        type: 'file',
        action: 'edited',
        projectId: file.projectId,
        fileId: file.fileId,
      }),
    );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file,
    });
    return promise;
  }

  async function downloadFiles(fileIds = []) {
    return api.post(
      '/projects/api/v3/files/archive.json',
      { fileIds },
      { errorMessage: t('Failed to download the files') },
    );
  }

  /**
   *
   * @param {Object} options
   * @param {File} options.file The file to upload
   * @param {'inline'|'useravatar'|'companylogo'|'projectlogo'|'teamlogo'|'sitelogo'|'favicon'|'touchicon'} options.imageType The type of image (e.g. inlineImage,
   * @returns {Promise<{url: string, ref: string}>}
   */
  function getPresignedUrl({ file, imageType = undefined }) {
    if (!(file instanceof File)) {
      throw new Error('File must be an instance of File');
    }
    const url = '/projects/api/v1/pendingfiles/presignedurl.json';
    const params = {
      fileName: file.name,
      fileSize: file.size,
      imageType,
    };
    return api.get(url, { params });
  }

  async function hasSpaceToUpload(fileSize) {
    const {
      data: { available },
    } = await api.get('/projects/api/v3/files/available.json');
    return fileSize <= available.bytes;
  }

  function restoreFile(file, type = 'file') {
    const id = type === 'file' ? file.id : file.fileVersionId || file.versionId;
    const restoreType = type === 'file' ? 'files' : 'fileversions';
    const url = `/trashcan/${restoreType}/${id}/restore.json`;
    const promise = api.put(url, null, { ...config(), errorMessage: t('Failed to restore the file') }).then(() =>
      emitRealTimeUpdate({
        type: 'file',
        action: 'reopened',
        projectId: file.projectId,
        fileId: file.id,
      }),
    );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'reopened',
      file,
    });
    return promise;
  }

  /**
   *
   * @param {string} url The presigned URL to upload the file
   * @param {File} file The file to upload
   * @param {Object} options
   * @param {({lengthComputable: boolean, total: number, loaded: number }) => unknown} options.onUploadProgress The callback to call when the upload progress changes
   * @returns
   */
  function uploadFile(url, file, { onUploadProgress = () => {} } = {}) {
    // temp workaround to file uploading on production and staging
    // see https://digitalcrew.teamwork.com/app/tasks/20284502?c=10129853
    const headers = account.value?.isStaging
      ? { 'Content-Type': file.type }
      : {
          'X-Amz-Acl': 'public-read',
          'Content-Type': file.type,
        };

    return nativeAxios
      .put(url, file, {
        headers,
        onUploadProgress,
      })
      .catch((error) => {
        toast.critical(t('Failed to upload file'));
        throw error;
      });
  }

  function updateFileDetails(fileId, newFileDetails) {
    const url = `/projects/api/v3/files/${fileId}.json`;
    const promise = api
      .patch(url, { file: newFileDetails }, { ...config(), errorMessage: t('Failed to update the file') })
      .then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'update',
          projectId: newFileDetails.projectId,
          fileId,
        }),
      );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file: { id: fileId, ...newFileDetails, displayName: newFileDetails.name },
    });
    return promise;
  }

  function uploadNewFileVersion(fileId, newFile) {
    const url = `/projects/api/v3/files/${fileId}.json`;
    const promise = api
      .post(url, { fileversion: newFile }, { ...config(), errorMessage: t('Failed to upload file') })
      .then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'new',
          projectId: newFile.projectId,
          fileId,
        }),
      );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file: { id: fileId, ...newFile },
    });
    return promise;
  }

  function lockFile(file) {
    const url = `/projects/api/v3/files/${file.id}/lock.json`;

    const promise = api.put(url, null, { ...config(), errorMessage: t('Failed to lock the file') }).then(() =>
      emitRealTimeUpdate({
        type: 'file',
        action: 'edited',
        projectId: file.projectId,
        fileId: file.id,
      }),
    );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file,
    });
    return promise;
  }

  function unlockFile(file) {
    const url = `/projects/api/v3/files/${file.id}/unlock.json`;

    const promise = api.put(url, null, { ...config(), errorMessage: t('Failed to unlock the file') }).then(() =>
      emitRealTimeUpdate({
        type: 'file',
        action: 'edited',
        projectId: file.projectId,
        fileId: file.id,
      }),
    );
    emitOptimisticUpdate({
      promise,
      type: 'file',
      action: 'update',
      file,
    });
    return promise;
  }

  return {
    downloadFiles,
    getPresignedUrl,
    hasSpaceToUpload,
    uploadFile,
    deleteFile,
    deleteFileVersion,
    restoreFile,
    updateFileDetails,
    uploadNewFileVersion,
    lockFile,
    unlockFile,
  };
}
