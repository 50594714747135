<script setup>
import { useRouter } from 'vue-router';
import { useCurrentUser, useExperimentA2408, usePendo } from '@/api';
import { useRoute } from '@/route';
import { useI18n } from '@/util';
import { usePlanTrial } from '@/module/planTrial';
import ShapesPurple from './assets/shapes-purple.svg';
import { useReps } from './useReps';

const emit = defineEmits(['close']);
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const { trackPendoEvent } = usePendo();
const { planTrial } = usePlanTrial();

const user = useCurrentUser();
const { accountAssociatedReps, repRoles, repTitleMapping } = useReps();
const { isExpA2408Variation } = useExperimentA2408();

const relevantRep = computed(() => {
  if (isExpA2408Variation.value) {
    return accountAssociatedReps.value?.productSpecialist;
  }

  if (route.path.includes('subscription')) {
    return accountAssociatedReps.value?.accountManager;
  }
  return accountAssociatedReps.value?.csm;
});

const isOnScalePlanTrial = computed(() => {
  return planTrial.value?.name?.toLowerCase() === 'scale';
});

const repEventMapping = {
  [repRoles.csm]: 'cs_manager',
  [repRoles.accountExec]: 'account_executive',
  [repRoles.accountManager]: 'account_manager',
  [repRoles.accoutSpecialist]: 'account_specialist',
  [repRoles.productSpecialist]: 'product_specialist',
};

function close() {
  trackPendoEvent({
    eventName: 'CONTACT_PROMPT_EVENT',
    metadata: {
      event_action: 'prompt_modal_cancel_clicked',
      type: repEventMapping[relevantRep.value.role],
      name: `${relevantRep.value.userFirstName} ${relevantRep.value.userLastName}`,
    },
  });
  emit('close');
}

function bookACall() {
  trackPendoEvent({
    eventName: 'CONTACT_PROMPT_EVENT',
    metadata: {
      event_action: 'prompt_modal_book_call_clicked',
      type: repEventMapping[relevantRep.value.role],
      name: `${relevantRep.value.userFirstName} ${relevantRep.value.userLastName}`,
    },
  });
  router.push({ path: '/book-a-call', query: { bookingUrl: encodeURI(relevantRep.value.hubspotCalendarLink) } });
  emit('close');
}

const relevantRepTitle = computed(() => {
  return repTitleMapping[relevantRep.value.role];
});

onMounted(() => {
  trackPendoEvent({
    eventName: 'CONTACT_PROMPT_EVENT',
    metadata: {
      event_action: 'prompt_modal_opened',
      type: repEventMapping[relevantRep.value.role],
      name: `${relevantRep.value.userFirstName} ${relevantRep.value.userLastName}`,
    },
  });
});
</script>

<template>
  <Transition
    enterActiveClass="transition-opacity duration-300"
    enterFromClass="opacity-0"
    enterToClass="opacity-100"
    leaveActiveClass="transition-opacity"
    leaveFromClass="opacity-100"
    leaveToClass="opacity-0"
    appear
  >
    <LscCard v-if="relevantRep">
      <template #header>
        <LscCardMediaHeader>
          <template #media>
            <div class="h-[80px] w-full overflow-hidden rounded-md bg-gradient-to-r from-[#443DCA] to-[#8882DE]">
              <ShapesPurple />
            </div>
          </template>
          <template #overlay>
            <img class="h-[40px]" :src="relevantRep.avatarImagePath" />
          </template>
        </LscCardMediaHeader>
      </template>
      <template #body>
        <section class="w-[296px]">
          <p class="pb-2 text-subtitle-1 font-bold">👋 {{ t('Hi') }} {{ user.firstName }}!</p>
          <p class="pb-2 text-body-1 font-bold">
            <LscReplace :text="t('My name is {name} and I\'m your {title}')">
              <template #name>{{ relevantRep.userFirstName }}</template>
              <template #title>{{ relevantRepTitle }}</template>
            </LscReplace>
          </p>
          <p v-if="!isOnScalePlanTrial" class="pb-2 text-body-1">
            {{
              relevantRep.role === repRoles.productSpecialist
                ? t('I can show you how to:')
                : t('I can help you and your team with:')
            }}
          </p>
          <template v-else>
            <p>{{ t("I'm here to help you get the most out of your Scale trial.") }}</p>
            <p class="pb-2">{{ t('Together, we can:') }}</p>
          </template>
          <ul v-if="!isOnScalePlanTrial" class="ml-4 list-disc gap-2 text-body-1">
            <template v-if="relevantRep.role === repRoles.csm">
              <li>{{ t('Getting workflows into {teamwork}.', { teamwork: 'Teamwork.com' }) }}</li>
              <li>{{ t('Enabling your teams.') }}</li>
              <li>{{ t('Escalating your requests.') }}</li>
              <li>{{ t('Getting the most out of our features.') }}</li>
              <li>{{ t('Recommending best practices.') }}</li>
            </template>
            <template v-else-if="relevantRep.role === repRoles.productSpecialist">
              <li>{{ t('Get set up on the essential features of {teamwork}.', { teamwork: 'Teamwork.com' }) }}</li>
              <li>{{ t('Set up your team members.') }}</li>
              <li>{{ t('Use {teamwork} to address your key business pains.', { teamwork: 'Teamwork.com' }) }}</li>
            </template>
            <template v-else>
              <li>{{ t('Helping manage your {teamwork} subscription.', { teamwork: 'Teamwork.com' }) }}</li>
              <li>
                {{
                  t('Exploring new ways to leverage {teamwork}.', {
                    teamwork: 'Teamwork.com',
                  })
                }}
              </li>
              <li>
                {{
                  t(
                    'Adding users, expanding into other parts of your organization, or upgrading your existing subscription.',
                  )
                }}
              </li>
              <li>{{ t('Working with your finance teams on procurement and billing.') }}</li>
            </template>
          </ul>
          <ul v-else class="ml-4 list-disc gap-2 text-body-1">
            <li>
              <LscReplace :text="t('{action} to streamline your workflow.')">
                <template #action>
                  <strong>{{ t('Get started with core features') }}</strong>
                </template>
              </LscReplace>
            </li>
            <li>
              <LscReplace :text="t('{action} for enhanced collaboration.')">
                <template #action>
                  <strong>{{ t('Integrate your team members') }}</strong>
                </template>
              </LscReplace>
            </li>
            <li>
              <LscReplace :text="t('{action}  to solve your unique business needs.')">
                <template #action>
                  <strong>{{ t('Customize {teamwork}', { teamwork: 'Teamwork.com' }) }}</strong>
                </template>
              </LscReplace>
            </li>
          </ul>
          <p v-if="!isOnScalePlanTrial" class="pt-2 text-body-1">{{ t('Book a call today to discuss your needs.') }}</p>
          <p v-else class="pt-2 text-body-1">
            {{ t('You can book a demo of Teamwork.com') }}
          </p>
        </section>
      </template>
      <template #footer>
        <LscCardToolbar>
          <template #start>
            <LscButton variant="tertiary" size="md" @click="close">
              {{ t('Cancel') }}
            </LscButton>
          </template>
          <template #end>
            <LscButton variant="primary" size="md" @click="bookACall">
              <template v-if="isOnScalePlanTrial">
                {{ t('Book a demo') }}
              </template>
              <template v-else>
                {{ t('Book a call') }}
              </template>
            </LscButton>
          </template>
        </LscCardToolbar>
      </template>
    </LscCard>
  </Transition>
</template>
