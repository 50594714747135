<script setup>
import { useI18n } from '@/util';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  defaultValue: {
    type: String,
    default: 'all',
  },
  status: {
    type: Array,
    default: () => ['all', 'upcoming', 'late'],
  },
});

const { t } = useI18n();
const { params, dataIdentifierPrefix } = useFilter();

const statusType = computed({
  get: () => params.value?.[props.name] || props.defaultValue,
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value,
    };
  },
});
const statusList = [
  { key: 'all', name: t('All'), icon: 'lsi-bulleted-list' },
  { key: 'upcoming', name: t('Upcoming'), icon: 'lsi-calendar' },
  { key: 'late', name: t('Late'), icon: 'lsi-alert' },
];

const options = statusList.filter(({ key }) => props.status?.includes(key));

const optionKeys = options.map(({ key }) => key);

useFilterNormalize(toRef(props, 'name'), (value) => (optionKeys.includes(value) ? value : props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() => {
    const selectedOption = options.find((opt) => opt.key === statusType.value);
    if (selectedOption?.key === props.defaultValue) {
      return [];
    }
    return {
      ...selectedOption,
      delete: () => {
        statusType.value = props.defaultValue;
      },
    };
  }),
);

useFilterCount(computed(() => Number(statusType.value !== props.defaultValue)));

function selectOption(option) {
  statusType.value = option.key;
}
</script>
<template>
  <WidgetMenu :closeOnContentClick="false" location="bottom left" offset="12">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="statusType !== props.defaultValue"
        icon="lsi-managing-planning"
        :data-identifier="`${dataIdentifierPrefix}-status-dropdown-button`"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <VList v-bind="VListRounded" maxHeight="384" class="w-[--filter-dropdown-width]">
      <VListItem
        v-for="option in options"
        v-bind="VListItemFilter"
        :key="option.id"
        :active="option.key === statusType"
        :data-identifier="`${dataIdentifierPrefix}-status-dropdown-${option.key}`"
        @click="selectOption(option)"
      >
        <template #prepend>
          <LscIcon :icon="option.icon" size="sm" />
        </template>
        <span class="text-body-1">{{ option.name }}</span>
      </VListItem>
    </VList>
  </WidgetMenu>
</template>
