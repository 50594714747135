<script setup>
import useVuelidate from '@vuelidate/core';
import { useClientsV3Loader } from '@/api';
import { useI18n, useValidators } from '@/util';
import SaveAsTemplate from '../assets/save-as-template.svg';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import { STEP_SAVE_AS_TEMPLATE } from '../constants';
import { useOnboardingWizard } from '../useOnboardingWizard';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep', 'previousStep', 'close']);

/** use stateData to check if there's old values for this state and if they have changed */
const { [STEP_SAVE_AS_TEMPLATE]: stateData } = props.state;

const { t } = useI18n();
const { required } = useValidators();
const { currentProject } = useOnboardingWizard();

const prefillTemplateName = computed(() => `[Template] ${currentProject.value?.name ?? 'My Project'}`);
const inputEl = shallowRef(null);
const templateName = shallowRef(stateData?.name ?? prefillTemplateName);
const templateDescription = shallowRef(stateData?.description ?? '');

const count = shallowRef(20);
const pageSize = shallowRef(20);

const clientsState = useClientsV3Loader({ count, pageSize });
const { items: clients, inSync: clientsInSync } = clientsState;

const selectedClientId = shallowRef(stateData?.selectedClientId);

const rules = {
  templateName: {
    required,
  },
  templateDescription: {},
};

const v$ = useVuelidate(rules, { templateName, templateDescription }, { $autoDirty: false });

function nextStep(skipped = false) {
  v$.value.$touch();
  if (skipped || !v$.value.$error) {
    const localData = {
      name: templateName.value,
      description: templateDescription.value,
      selectedClientId: selectedClientId.value,
      skipped,
    };

    emit('nextStep', JSON.stringify(localData) !== JSON.stringify(stateData), localData);
  }
}

function skipStep() {
  templateName.value = '';
  templateDescription.value = '';
  nextStep(true);
}

watch(clientsInSync, (newValue) => {
  if (newValue) {
    if (!selectedClientId.value) {
      selectedClientId.value = clients.value?.[0].id;
    }
  }
});

onMounted(() => {
  if (templateName.value === '' || templateName.value === prefillTemplateName.value) {
    inputEl.value.focus();
  }
});
</script>

<template>
  <OnboardingWizardCommonStep
    :title="t('Manage your templates')"
    :description="
      t(
        'Save time and optimize your workflows by saving your projects as custom templates for future use. Your custom templates can be found by clicking Templates on the main navigation menu.',
      )
    "
  >
    <div class="mb-5 text-body-1 font-semibold">{{ t('Give your template a name') }}</div>

    <VTextField
      ref="inputEl"
      v-model.trim="templateName"
      aria-required="true"
      required
      :label="t('Add a template name')"
      :placeholder="t('e.g. Finance template')"
      :errorMessages="v$.templateName.$errors.map((error) => error.$message)"
      :maxLength="255"
      :autoFocus="true"
      class="mb-5"
    />

    <div class="mb-5 text-body-1 font-semibold">
      {{ t('Select a company to save your template') }}
    </div>

    <VSelect
      v-model="selectedClientId"
      class="col-span-1 mb-5 h-10"
      :items="clients"
      itemTitle="name"
      itemValue="id"
      :menuProps="{ width: 'auto' }"
      :loading="!clientsInSync"
    >
      <template #append-item>
        <WidgetLoadingLazy v-model:count="count" :state="clientsState" :step="pageSize" :minCount="5" />
      </template>
    </VSelect>

    <div class="mb-5 text-body-1 font-semibold">
      {{ t('Add a description for your template') }}
    </div>

    <VTextarea
      v-model.trim="templateDescription"
      :label="t('Description')"
      :placeholder="t('Add a description')"
      persistentPlaceholder
      class="my-5"
      :errorMessages="v$.templateDescription.$errors.map((error) => error.$message)"
    />

    <slot
      name="underFieldsButtons"
      :nextButtonText="t('Save as template')"
      :nextStep="nextStep"
      :showSkipButton="showSkipButton"
      :skipButtonText="t('Finish without template')"
      :skipStep="skipStep"
    />

    <template #right>
      <SaveAsTemplate class="max-w-[--onboarding-wizard-preview-container-width] shadow-3" />
    </template>
  </OnboardingWizardCommonStep>
  <slot
    name="footerButtons"
    :nextButtonText="nextButtonText"
    :nextStep="nextStep"
    :skipButtonText="t('Skip')"
    :showSkipButton="showSkipButton"
    :skipStep="skipStep"
  />
</template>
