import '@/polyfill';
import * as Sentry from '@sentry/vue';
import {
  appVersion,
  axiosPlugin,
  loadersPlugin,
  optimisticUpdatesPlugin,
  realTimeUpdatesPlugin,
  versionPlugin,
} from '@/api';
import { AppShell, AppShellEmbed, AppShellPublic, AppShellShared } from '@/appShell';
import { chartjsPlugin, iconsPlugin, vuetifyPlugin } from '@/designsystem';
import { createEmbedRouter, createPublicRouter, createRouter } from '@/route';
import {
  devtoolsPlugin,
  enhancedPluginsPlugin,
  i18nPlugin,
  keyboardShortcutPlugin,
  unsavedChangesPlugin,
  zodValidationPlugin,
} from '@/util';
import { feedbackDataIdentifierPlugin } from '@/module/feedback';
import { tourDataIdentifierPlugin } from '@/module/onboarding';
import '@/designsystem/styles/main.css';
// Remove duplicate `/` characters from `pathname`.
// See https://digitalcrew.teamwork.com/app/tasks/21987579
window.history.replaceState(
  window.history.state,
  null,
  `${window.location.pathname.replace(/\/+/g, '/')}${window.location.search}${window.location.hash}`,
);

function setupSentry(app, router) {
  if (import.meta.env.TW_APP_SENTRY_ENABLED === 'true') {
    Sentry.init({
      app,
      dsn: 'https://0aaa30ec7a4b4e6dbb4ffd0f120617f4@o7434.ingest.sentry.io/4504849204838400',
      release: appVersion,
      environment: import.meta.env.MODE,
      ignoreErrors: [
        // Errors related to failed resource loads for varying reason, unrelated to runtime logic.
        'Load failed',
        'Importing a module script failed.',
        'error loading dynamically imported module',
        'Unable to preload CSS',
        'Failed to fetch',
        // Errors related to network requests
        'Network error',
        'Synchronous XHR in page dismissal', // navigating away during XHR
        'A network error occurred.',
        'NetworkError when attempting to fetch resource.',
        'Request failed',
        // https://github.com/getsentry/sentry-javascript/issues/3440
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:', // error caused by 3rd party
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
        'ResizeObserver loop',
        // https://github.com/getsentry/sentry-javascript/issues/5833
        // https://github.com/getsentry/sentry-javascript/issues/2514
        'Non-Error promise rejection captured with value: Timeout', // error caused by 3rd party
        // https://github.com/shadcn-ui/ui/issues/2837#issuecomment-1962884525
        'Cannot redefine property: googletag',
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
      ],
      integrations: [
        Sentry.browserTracingIntegration({
          router,
          tracePropagationTargets: [/^\//],
        }),
      ],
      tracesSampler(samplerContext) {
        // we cannot use the current route from the router here as it's not yet defined for the pageload trace.
        const { tracesSampleRate } = router?.resolve(samplerContext.name.replace('/app/', ''))?.meta || {};
        return tracesSampleRate || 0;
      },
    });
  }
}

function setupSharedPlugins(app) {
  app.use(versionPlugin);
  app.use(i18nPlugin, {
    localeLoaders: new Map(
      Object.entries(import.meta.glob('@/locale/??.js')).map(([key, value]) => [/\/(\w+)\.js$/.exec(key)[1], value]),
    ),
  });
  app.use(feedbackDataIdentifierPlugin);
  app.use(tourDataIdentifierPlugin);
  app.use(devtoolsPlugin);
  app.use(zodValidationPlugin);
  app.use(lsToastPlugin);
  app.use(keyboardShortcutPlugin);
  app.use(axiosPlugin);
  app.use(loadersPlugin);
  app.use(realTimeUpdatesPlugin);
  app.use(optimisticUpdatesPlugin);
  app.use(unsavedChangesPlugin);
  app.use(iconsPlugin);
  app.use(vuetifyPlugin);
  app.use(chartjsPlugin);
}

function mountAppShellShared() {
  const app = createApp(AppShellShared);
  app.use(enhancedPluginsPlugin);
  setupSentry(app);
  setupSharedPlugins(app);
  app.mount('#app');
}

function mountAppShellPublic() {
  const app = createApp(AppShellPublic);
  app.use(enhancedPluginsPlugin);
  const router = createPublicRouter();
  app.use(router);
  setupSentry(app, router);
  setupSharedPlugins(app);
  app.mount('#app');
}

function mountAppShellEmbed() {
  const app = createApp(AppShellEmbed);
  app.use(enhancedPluginsPlugin);
  const router = createEmbedRouter();
  app.use(router);
  setupSentry(app, router);
  setupSharedPlugins(app);
  router.isReady().then(() => app.mount('#app'));
}

function mountAppShell() {
  const app = createApp(AppShell);
  app.use(enhancedPluginsPlugin);
  const router = createRouter();
  app.use(router);
  setupSentry(app, router);
  setupSharedPlugins(app);

  // In Vue Router 4 route navigation is always asynchronous,
  // so we wait until the router is ready before we start the app.
  // This simply ensures that route.queryParams is available in the `setup` functions on start-up.
  // See https://www.vuemastery.com/blog/vue-router-4-route-params-not-available-on-created-setup
  router.isReady().then(() => app.mount('#app'));
}

if (window.location.pathname.startsWith('/app/shared/')) {
  mountAppShellShared();
} else if (window.location.pathname.startsWith('/app/public/')) {
  mountAppShellPublic();
} else if (window.location.pathname.startsWith('/app/embed/')) {
  mountAppShellEmbed();
} else {
  mountAppShell();
}
