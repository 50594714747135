<script setup>
import useVuelidate from '@vuelidate/core';
import { DateTime } from 'luxon';
import { useCurrentAccount, useExperimentA2301, useExperimentA2350 } from '@/api';
import { useI18n, useValidators } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  BILLING_TYPE_FIXED_FEE,
  BILLING_TYPE_RETAINER,
  BILLING_TYPE_STANDARD,
  BUDGET_TYPE_FINANCIAL,
  BUDGET_TYPE_TIME,
  CURRENCY_OPTIONS,
  ONBOARDING_GOAL_GETTING_STARTED,
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_FINANCE,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_PEOPLE,
  PREVIEW_TAB_TABLE,
  STEP_BILLING_TYPE,
  STEP_CLIENT_PROJECT,
  STEP_SELECT_VIEW,
  STEP_SET_BUDGET,
} from '../constants';
import OnboardingWizardBudgetCustomRepeatIntervalDialog from '../OnboardingWizardBudgetCustomRepeatIntervalDialog.vue';
import { useOnboardingWizard } from '../useOnboardingWizard';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  nextButtonText: {
    type: String,
    required: true,
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['nextStep', 'previousStep', 'close']);

const currentAccount = useCurrentAccount();
const { t } = useI18n();
const { required, numeric, integer, minValue, maxValue, requiredIf, minDate, maxDate } = useValidators();
const {
  trackBudgetTypeSelected: trackBudgetTypeSelectedA2301,
  trackBudgetPeriodSelected: trackBudgetPeriodSelectedA2301,
} = useExperimentA2301();
const {
  trackBudgetTypeSelected: trackBudgetTypeSelectedA2350,
  trackBudgetPeriodSelected: trackBudgetPeriodSelectedA2350,
} = useExperimentA2350();
const { isGoalGettingStarted, currentProject, currentGoal } = useOnboardingWizard();

const DATE_FORMAT = 'yyyy-MM-dd';

const {
  [STEP_SET_BUDGET]: stateData,
  [STEP_SELECT_VIEW]: selectViewState,
  [STEP_CLIENT_PROJECT]: clientProjectStateData,
  [STEP_BILLING_TYPE]: billingStateData,
} = props.state;

const typeOptions = [
  { title: t('Financial'), value: 'financial' },
  { title: t('Time'), value: 'time' },
];

const billingType = billingStateData?.type;
const isBillingTypeRetainer = billingType === BILLING_TYPE_RETAINER;
const isBillingTypeFixedFee = billingType === BILLING_TYPE_FIXED_FEE;

const initialTimelogType = isBillingTypeFixedFee ? 'ALL' : 'billable';

const type = shallowRef(
  stateData?.type || (isBillingTypeRetainer || isBillingTypeFixedFee ? BUDGET_TYPE_FINANCIAL : BUDGET_TYPE_TIME),
);
const currencyId = shallowRef(
  stateData?.currencyId ??
    CURRENCY_OPTIONS.find((currency) => currency.id === currentAccount.value?.currency?.id)?.id ??
    CURRENCY_OPTIONS[0].id,
);
const currencySymbol = computed(() => {
  return CURRENCY_OPTIONS.find((currency) => currency.id === currencyId.value)?.symbol;
});

const clientName = computed(() => {
  if (clientProjectStateData?.name) {
    return clientProjectStateData.name;
  }

  if (currentProject?.value && currentProject?.value?.company) {
    const { company } = currentProject.value;
    return company.isOwner ? undefined : company.name;
  }

  return undefined;
});

const projectName = computed(() => selectViewState?.name ?? currentProject?.value?.name);

const amount = shallowRef(stateData?.amount);
const amountEl = shallowRef();
const startDateTime = shallowRef(
  stateData?.startDateTime ? DateTime.fromFormat(stateData.startDateTime, DATE_FORMAT) : undefined,
);
const endDateTime = shallowRef(
  stateData?.endDateTime ? DateTime.fromFormat(stateData.endDateTime, DATE_FORMAT) : undefined,
);
const timelogType = shallowRef(initialTimelogType);
const customRepeatIntervalDialogVisible = shallowRef(false);
const customRepeatPeriod = shallowRef(stateData?.repeatInterval === 'custom' ? stateData?.repeatPeriod : undefined);
const customRepeatUnit = shallowRef(stateData?.repeatInterval === 'custom' ? stateData?.repeatUnit : undefined);
const addUnspentHours = shallowRef(stateData?.addUnspentHours ?? true);
const subtractOverspentHours = shallowRef(stateData?.subtractOverspentHours ?? true);

const profitMargin = shallowRef(stateData?.profitMargin);
const targetProfit = shallowRef(stateData?.targetProfit);
const targetCosts = shallowRef(stateData?.targetCosts);

const rules = computed(() => {
  return {
    amount: {
      required,
      numeric,
      integer: type.value === BUDGET_TYPE_TIME && integer,
      minValue: minValue(1),
      maxValue: type.value === BUDGET_TYPE_FINANCIAL ? maxValue(9999999) : maxValue(9999),
    },
    startDateTime: {
      required,
      maxDate: maxDate(endDateTime.value),
    },
    endDateTime: {
      requiredIf: requiredIf(() => billingType === BILLING_TYPE_RETAINER),
      minDate: minDate(startDateTime.value),
    },
    profitMargin: {
      requiredIf: requiredIf(isBillingTypeFixedFee),
      numeric,
      minValue: minValue(1),
      maxValue: maxValue(100),
    },
    targetCosts: {
      requiredIf: requiredIf(isBillingTypeFixedFee),
      numeric,
      minValue: minValue(1),
    },
    targetProfit: {
      requiredIf: requiredIf(isBillingTypeFixedFee),
      numeric,
      minValue: minValue(1),
    },
  };
});
const v$ = useVuelidate(
  rules,
  { amount, profitMargin, targetCosts, targetProfit, startDateTime, endDateTime },
  { $autoDirty: false },
);

const customRepeatTitle = computed(() => {
  switch (customRepeatUnit.value) {
    case 'month':
      return t('Every month | Every {n} months', {
        n: customRepeatPeriod.value,
      });
    case 'week':
      return t('Every week | Every {n} weeks', {
        n: customRepeatPeriod.value,
      });
    case 'day':
      return t('Every day | Every {n} days', {
        n: customRepeatPeriod.value,
      });
    default:
      return t('Custom');
  }
});

const customRepeatIntervalSet = computed(() => customRepeatPeriod.value && customRepeatUnit.value);

const repeatIntervalOptions = computed(() =>
  isBillingTypeRetainer
    ? [
        { title: t('Monthly'), value: 'monthly', isRepeating: true, repeatPeriod: 1, repeatUnit: 'month' },
        { title: t('Quarterly'), value: 'quarterly', isRepeating: true, repeatPeriod: 1, repeatUnit: 'quarter' },
      ]
    : [
        { title: t('Never'), value: 'never', isRepeating: false, repeatPeriod: 0, repeatUnit: 0 },
        { title: t('Weekly'), value: 'weekly', isRepeating: true, repeatPeriod: 1, repeatUnit: 'week' },
        { title: t('Monthly'), value: 'monthly', isRepeating: true, repeatPeriod: 1, repeatUnit: 'month' },
        {
          title: customRepeatTitle.value,
          value: 'custom',
          id: 'custom',
          isRepeating: true,
          repeatPeriod: customRepeatPeriod.value,
          repeatUnit: customRepeatUnit.value,
        },
      ],
);
const timelogTypeOptions = [
  { title: t('All time'), value: 'all' },
  { title: t('Billable time'), value: 'billable' },
  { title: t('Non-billable time'), value: 'non-billable' },
];
const repeatInterval = shallowRef(stateData?.repeatInterval || repeatIntervalOptions.value[0].value);
const previousRepeatInterval = shallowRef(repeatIntervalOptions.value[0].value);

const previewFinanceData = computed(() => {
  return {
    isBillingTypeRetainer,
    isBudgetTypeFinancial: type.value === BUDGET_TYPE_FINANCIAL,
    currencyId: currencyId.value,
    amount: amount.value,
    addUnspentHours: addUnspentHours.value,
    subtractOverspentHours: subtractOverspentHours.value,
    totalCost: Number(targetCosts.value),
    profit: Number(targetProfit.value),
    billableTotal: Number(amount.value),
  };
});

const billingTypeInfo = computed(() => {
  switch (billingType) {
    case BILLING_TYPE_STANDARD:
      return {
        title: t('Set a time & materials budget'),
        mainField: t('Budget amount'),
        tooltip: t('Budget value can be financial or hourly'),
      };
    case BILLING_TYPE_RETAINER:
      return {
        title: t('Set a retainers budget'),
        mainField: t('Retainer budget'),
        tooltip: t('Recurring rate charged to client'),
      };
    case BILLING_TYPE_FIXED_FEE:
      return {
        title: t('Set a fixed fee budget'),
        mainField: t('Fixed fee budget'),
        tooltip: t('Set targets to keep track of profitability throughout your project'),
      };
    default:
      return undefined;
  }
});

function trackBudgetPeriodSelected(budgetPeriod) {
  if (currentGoal.value === ONBOARDING_GOAL_GETTING_STARTED) {
    trackBudgetPeriodSelectedA2301(budgetPeriod);
  } else {
    trackBudgetPeriodSelectedA2350(budgetPeriod);
  }
}

function trackBudgetTypeSelected(budgetType) {
  if (currentGoal.value === ONBOARDING_GOAL_GETTING_STARTED) {
    trackBudgetTypeSelectedA2301(budgetType);
  } else {
    trackBudgetTypeSelectedA2350(budgetType);
  }
}

function nextStep() {
  v$.value.$touch();
  if (!v$.value.$error) {
    const selectedRepeatInterval = repeatIntervalOptions.value.find((option) => option.value === repeatInterval.value);
    const newData = {
      type: type.value,
      ...(type.value === BUDGET_TYPE_FINANCIAL && {
        currencyId: currencyId.value,
      }),
      amount: amount.value,
      repeatInterval: repeatInterval.value,
      isRepeating: selectedRepeatInterval?.isRepeating,
      repeatValue: selectedRepeatInterval?.value,
      repeatPeriod: selectedRepeatInterval?.repeatPeriod,
      repeatUnit: selectedRepeatInterval?.repeatUnit,
      addUnspentHours: addUnspentHours.value,
      subtractOverspentHours: subtractOverspentHours.value,
      startDateTime: DateTime.fromISO(startDateTime.value).toFormat(DATE_FORMAT),
      endDateTime: endDateTime.value ? DateTime.fromISO(endDateTime.value).toFormat(DATE_FORMAT) : null,
      timelogType: timelogType.value,
      budgetCategory: isBillingTypeFixedFee ? 'FIXEDFEE' : 'STANDARD',
      ...(isBillingTypeFixedFee && {
        profitMargin: profitMargin.value,
      }),
    };
    const hasDataChanged = JSON.stringify(stateData) !== JSON.stringify(newData);

    emit('nextStep', hasDataChanged, newData);
  }
}

function skipStep() {
  emit('nextStep', !stateData || !stateData?.skipped, { skipped: true });
}

function previousStep() {
  emit('previousStep');
}

function handleCustomRepeatIntervalSet(period, unit) {
  repeatInterval.value = 'custom';

  customRepeatPeriod.value = period;
  customRepeatUnit.value = unit;
}

function handleRepeatIntervalSelected(item) {
  if (item.value !== 'custom') {
    return;
  }

  customRepeatIntervalDialogVisible.value = true;
}

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

function resetFixedFeeFields() {
  profitMargin.value = undefined;
  targetProfit.value = undefined;
  targetCosts.value = undefined;
}

function getMarginPercentage(profit, costs) {
  const margin = Number((profit / costs) * 100);
  if (Number.isNaN(margin)) {
    return 0;
  }
  return Number.isInteger(margin) ? margin : margin.toFixed(2);
}

function onProfitMarginChanged(value) {
  if (value === 0) {
    targetProfit.value = 0;
    targetCosts.value = amount.value;
  } else if (value > 0) {
    const clamped = clamp(value, 0, 100);
    profitMargin.value = clamped;
    const profitValue = (amount.value / 100) * clamped;
    targetProfit.value = Number.isInteger(profitValue) ? profitValue : profitValue.toFixed(2);
    targetCosts.value = Number.isInteger(profitValue)
      ? amount.value - profitValue
      : (amount.value - profitValue).toFixed(2);
  } else {
    resetFixedFeeFields();
  }
}

function onTargetCostsChanged(value) {
  if (value > 0) {
    targetCosts.value = Number.isInteger(value) ? value : value.toFixed(2);
    targetProfit.value = Number.isInteger(value) ? amount.value - value : (amount.value - value).toFixed(2);

    profitMargin.value = 100 - getMarginPercentage(value, amount.value);
  } else {
    targetProfit.value = amount.value;
    profitMargin.value = 100;
  }
}

function onTargetProfitChanged(value) {
  if (value > 0) {
    targetCosts.value = amount.value - value;
    profitMargin.value = getMarginPercentage(value, amount.value);
  } else {
    profitMargin.value = 0;
    targetProfit.value = 0;
    targetCosts.value = amount.value;
  }
}

function handleAmountInputBlurred() {
  if (!amount.value) {
    return;
  }

  if (Number.isInteger(amount.value)) {
    if (Number.isInteger(profitMargin.value)) {
      onProfitMarginChanged(profitMargin.value);
    }
    return;
  }

  if (type.value === BUDGET_TYPE_FINANCIAL) {
    amount.value = Number(amount.value.toFixed(2));
    return;
  }

  amount.value = Number(amount.value.toFixed(0));
}

watch(type, () => {
  amount.value = undefined;
  v$.value.$reset();
  amountEl.value.focus();
});

watch(repeatInterval, (_, oldRepeatInterval) => {
  previousRepeatInterval.value = oldRepeatInterval;
});

watch(customRepeatIntervalDialogVisible, (dialogVisible) => {
  if (!dialogVisible) {
    return;
  }

  if (customRepeatIntervalSet.value) {
    return;
  }

  repeatInterval.value = previousRepeatInterval.value;
});

onMounted(() => {
  if (!amount.value) {
    amountEl.value.focus();
  }

  if (stateData?.profitMargin) {
    onProfitMarginChanged(stateData.profitMargin);
  }
});
</script>
<template>
  <OnboardingWizardCommonStep
    :title="billingTypeInfo.title"
    :description="
      t(
        'Adding a budget is an easy and effective way to make sure every project remains profitable. You can update or customize advanced options later.',
      )
    "
  >
    <div class="flex w-full grow-0 flex-col gap-4">
      <div class="grid items-start gap-2">
        <div
          class="mb-1 flex items-center gap-1 text-body-1 font-semibold"
          :class="{
            'col-span-3': type === BUDGET_TYPE_TIME && isGoalGettingStarted,
            'grid-cols-3': isGoalGettingStarted,
            'col-span-2 grid-cols-2': !isGoalGettingStarted,
          }"
        >
          {{ billingTypeInfo.mainField }}
          <LscIcon
            v-LsdTooltip="billingTypeInfo.tooltip"
            class="cursor-help text-icon-default focus:outline-none"
            size="sm"
            icon="lsi-tooltip"
          />
        </div>
        <div
          v-if="type === BUDGET_TYPE_FINANCIAL && isGoalGettingStarted"
          class="mb-1 inline-block text-body-1 font-semibold"
          :class="{ 'col-span-2': type === BUDGET_TYPE_FINANCIAL }"
        >
          {{ t('Currency') }}
        </div>
        <VSelect
          v-if="billingType !== BILLING_TYPE_FIXED_FEE"
          v-model="type"
          :class="{
            'col-span-1': !isGoalGettingStarted,
          }"
          :items="typeOptions"
          :menuProps="{ width: 'auto' }"
          @update:modelValue="(type) => trackBudgetTypeSelected(type)"
        />
        <VSelect
          v-if="type === BUDGET_TYPE_FINANCIAL && isGoalGettingStarted"
          v-model="currencyId"
          itemValue="id"
          itemTitle="code"
          :items="CURRENCY_OPTIONS"
          :menuProps="{ width: 'auto' }"
        />
        <VTextField
          ref="amountEl"
          v-model.number="amount"
          :label="t('Budget amount')"
          :class="{
            'col-span-1': !isGoalGettingStarted,
          }"
          placeholder="0"
          type="number"
          variant="outlined"
          :min="1"
          :max="type === BUDGET_TYPE_FINANCIAL ? 9999999 : 9999"
          :errorMessages="v$.amount.$errors.map((error) => error.$message)"
          @blur="handleAmountInputBlurred"
        />
      </div>
      <div class="grid items-start gap-2">
        <div class="col-span-2 mb-1 flex grid-cols-2 items-center gap-1 text-body-1 font-semibold">
          {{ t('Start and end date') }}
        </div>
        <LscDateField
          v-model="startDateTime"
          :maxDate="endDateTime"
          required
          class="col-span-1"
          :label="t('Start date')"
          :errorMessages="v$.startDateTime.$errors.map((error) => error.$message)"
        />
        <LscDateField
          v-model="endDateTime"
          :minDate="startDateTime"
          :required="billingType === BILLING_TYPE_RETAINER"
          class="col-span-1"
          :errorMessages="v$.endDateTime.$errors.map((error) => error.$message)"
          :label="t('End date')"
        />
      </div>
      <div v-if="billingType !== BILLING_TYPE_FIXED_FEE" class="grid grid-cols-2 items-start gap-2">
        <div class="col-span-1 mb-1 flex items-center gap-1 text-body-1 font-semibold">
          {{ isBillingTypeRetainer ? t('Period length') : t('Budget repeats') }}
          <LscIcon
            v-if="isBillingTypeRetainer"
            v-LsdTooltip="t('The project billing frequency')"
            class="cursor-help text-icon-default focus:outline-none"
            size="sm"
            icon="lsi-tooltip"
          />
        </div>
        <div class="col-span-1 mb-1 flex items-center gap-1 text-body-1 font-semibold">
          {{ t('Budget based on') }}
          <LscIcon
            v-LsdTooltip="t('Type of time that contributes to this budget')"
            class="cursor-help text-icon-default focus:outline-none"
            size="sm"
            icon="lsi-tooltip"
          />
        </div>
        <VSelect
          v-model="repeatInterval"
          class="col-span-1 h-10"
          :items="repeatIntervalOptions"
          :menuProps="{ width: 'auto' }"
          @update:modelValue="(period) => trackBudgetPeriodSelected(period)"
        >
          <template #item="{ item, props: itemProps }">
            <VListItem
              density="compact"
              class="focus-visible:group-focus-within:bg-selected"
              v-bind="itemProps"
              :title="item.title?.value || item.title"
              @click="handleRepeatIntervalSelected(item)"
            >
              <template v-if="item.value === 'custom'" #append>
                <LscIcon icon="lsi-edit" size="md" class="mr-2" />
              </template>
            </VListItem>
          </template>
        </VSelect>
        <VSelect
          v-model="timelogType"
          class="col-span-1 h-10"
          :items="timelogTypeOptions"
          :menuProps="{ width: 'auto' }"
        >
          <template #item="{ item, props: itemProps }">
            <VListItem
              density="compact"
              class="focus-visible:group-focus-within:bg-selected"
              v-bind="itemProps"
              :title="item.title?.value || item.title"
            >
              <template v-if="item.value === 'custom'" #append>
                <LscIcon icon="lsi-edit" size="md" class="mr-2" />
              </template>
            </VListItem>
          </template>
        </VSelect>
      </div>
      <div v-if="billingType === BILLING_TYPE_FIXED_FEE">
        <div class="grid grid-cols-3 items-start gap-2">
          <div class="mb-3 text-body-1 font-semibold">
            <span class="flex items-center gap-2">
              {{ t('Profit margin') }}
              <LscIcon
                v-LsdTooltip="t('Set targets to keep track of profitability throughout your project')"
                class="cursor-help text-icon-default focus:outline-none"
                size="sm"
                icon="lsi-tooltip"
              />
            </span>

            <VTextField
              v-model.number="profitMargin"
              class="mt-3 w-full"
              placeholder="0"
              type="number"
              variant="outlined"
              :min="0"
              :max="100"
              :disabled="!amount"
              :errorMessages="v$.profitMargin.$errors.map((error) => error.$message)"
              @update:modelValue="(value) => onProfitMarginChanged(value)"
            >
              <template #prepend-inner>
                <span class="inline-block h-4 w-4 leading-1">%</span>
              </template>
            </VTextField>
          </div>
          <div class="mb-3 text-body-1 font-semibold">
            <span class="flex items-center gap-2">
              {{ t('Target profit') }}
            </span>
            <VTextField
              v-model.number="targetProfit"
              class="mt-3 w-full"
              placeholder="0"
              type="number"
              variant="outlined"
              min="0"
              :disabled="!amount"
              :errorMessages="v$.targetProfit.$errors.map((error) => error.$message)"
              @update:modelValue="(value) => onTargetProfitChanged(value)"
            >
              <template #prepend-inner>
                <span class="inline-block h-4 w-4 leading-1">{{ currencySymbol }}</span>
              </template>
            </VTextField>
          </div>
          <div class="mb-3 text-body-1 font-semibold">
            <span class="flex items-center gap-2">
              {{ t('Target costs') }}
            </span>
            <VTextField
              v-model.number="targetCosts"
              class="mt-3 w-full"
              placeholder="0"
              type="number"
              variant="outlined"
              min="0"
              :disabled="!amount"
              :errorMessages="v$.targetCosts.$errors.map((error) => error.$message)"
              @update:modelValue="(value) => onTargetCostsChanged(value)"
            >
              <template #prepend-inner>
                <span class="inline-block h-4 w-4 leading-1">{{ currencySymbol }}</span>
              </template>
            </VTextField>
          </div>
        </div>
      </div>
      <div v-if="isBillingTypeRetainer">
        <div class="mb-3 text-body-1 font-semibold">
          {{ t('Retainer options') }}
        </div>

        <VCheckbox v-model="addUnspentHours" class="-ml-1.5" :label="t('Add unspent hours to next period\'s budget')" />
        <VCheckbox
          v-model="subtractOverspentHours"
          class="-ml-1.5"
          :label="t('Subtract overspent hours from next period\'s budget')"
        />
      </div>
      <slot
        name="underFieldsButtons"
        :nextButtonText="nextButtonText"
        :nextStep="nextStep"
        :skipButtonText="t('Skip')"
        :skipStep="skipStep"
      />
    </div>

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :clientCompanyName="clientName"
        :preselectedTab="PREVIEW_TAB_FINANCE"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST, PREVIEW_TAB_FINANCE, PREVIEW_TAB_PEOPLE]"
        :tabsClickable="false"
        :financeData="previewFinanceData"
      />
    </template>
  </OnboardingWizardCommonStep>
  <OnboardingWizardBudgetCustomRepeatIntervalDialog
    v-model="customRepeatIntervalDialogVisible"
    :repeatPeriod="customRepeatPeriod"
    :repeatUnit="customRepeatUnit"
    @set="handleCustomRepeatIntervalSet"
  />
  <slot
    name="footerButtons"
    :nextButtonText="nextButtonText"
    :nextStep="nextStep"
    :skipButtonText="t('Skip')"
    :showSkipButton="showSkipButton"
    :skipStep="skipStep"
    :previousStep="previousStep"
  />
</template>
