<script setup>
import { normalizeFilterBoolean } from './normalize';
import { useFilter, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  defaultValue: {
    type: Boolean,
    default: false,
  },
});

const { params, dataIdentifierPrefix } = useFilter();

const param = computed({
  get: () => (params.value ? params.value[props.name] : props.defaultValue),
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value,
    };
  },
});

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterBoolean(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterCount(computed(() => Number(param.value != null && param.value !== props.defaultValue)));
</script>

<template>
  <VCheckbox
    v-model="param"
    class="w-fit"
    :label="label"
    :disabled="$attrs.disabled"
    :data-identifier="`${dataIdentifierPrefix}-checkbox`"
  />
</template>
