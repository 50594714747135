<script setup>
import { useRecencyV3Loader } from '@/api';
import { useAppShellSidebar } from '@/appShell';
import { getEntityText, getEntityYouText, useI18n } from '@/util';
import { EntityAvatar } from '@/module/entity';
import SearchDrawerEmptyRecents from './SearchDrawerEmptyRecents.svg';

const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();

const { t } = useI18n();

const count = shallowRef(20);
const state = useRecencyV3Loader({
  count,
});

const { items: recents } = state;

function getRecentActionText({ entity }) {
  return getEntityYouText(entity)(t);
}

function getRecentGroupText({ description, entity }) {
  if (String(description ?? '').trim()) {
    return description;
  }
  return getEntityText(entity)(t);
}
</script>
<template>
  <div class="flex flex-col items-stretch gap-6 overflow-x-hidden">
    <WidgetLoadingState :state="state">
      <template #default>
        <div class="mx-6">
          <h4 class="text-body-1 font-semibold">{{ t('My recent activity') }}</h4>
        </div>

        <VList class="pt-0">
          <VListItem
            v-for="recent in recents"
            :key="recent.id"
            v-memo="[recent.entity]"
            v-bind="VListItemSidebarSearchDrawer"
            :to="`/${recent.itemLink}`"
            data-identifier="app-nav__search-panel__recents__list-item"
            @click="clearActiveDrawerPanelIfNotPinned"
          >
            <template #prepend>
              <EntityAvatar v-bind="recent.entity" class="mr-3" />
            </template>
            <VListItemSubtitle>
              <div class="flex justify-between text-body-2">
                <span class="mr-3 truncate">
                  {{ getRecentActionText(recent) }}
                </span>
                <span class="shrink-0">
                  {{ recent.createdAt.toRelative() }}
                </span>
              </div>
            </VListItemSubtitle>
            <VListItemTitle class="!text-body-1 font-medium" :title="getRecentGroupText(recent)">
              {{ getRecentGroupText(recent) }}
            </VListItemTitle>
          </VListItem>

          <WidgetLoadingLazy v-model:count="count" :state="state" :step="step" />
        </VList>
      </template>

      <template #blank>
        <LscEmptyState
          class="h-full"
          :title="t('No recent activity')"
          :message="t('You don\'t have any recent activity')"
        >
          <template #image>
            <SearchDrawerEmptyRecents />
          </template>
        </LscEmptyState>
      </template>
    </WidgetLoadingState>
  </div>
</template>
