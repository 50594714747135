<script setup>
import { refDebounced } from '@vueuse/core';
import { useProjectsV3Loader } from '@/api';
import ProjectJumpToDrawerAllBlankSlate from './ProjectJumpToDrawerAllBlankSlate.vue';
import ProjectJumpToDrawerProjectsList from './ProjectJumpToDrawerProjectsList.vue';

const props = defineProps({
  searchTerm: {
    type: String,
    default: '',
  },
});

const { searchTerm } = toRefs(props);
const debouncedSearchTerm = refDebounced(searchTerm, 300);

const step = 30;
const count = shallowRef(step);
const state = useProjectsV3Loader({
  count,
  pageSize: step,
  params: computed(() => ({
    searchTerm: debouncedSearchTerm.value,
    include: 'companies,projectCategories',
    includeProjectUserInfo: true,
    'fields[projects]': 'id,name,isStarred,companyId,startPage,categoryId,logoIcon,logoColor',
    orderBy: 'name',
    orderMode: 'asc',
    onlyStarredProjects: false,
    onlyProjectsWithExplicitMembership: 1,
  })),
});

const tabName = computed(() => {
  return searchTerm.value ? 'search_results' : 'all';
});
</script>

<template>
  <ProjectJumpToDrawerProjectsList v-model:count="count" :step="step" :state="state" :tab="tabName">
    <template #blank>
      <ProjectJumpToDrawerAllBlankSlate :searchTerm="searchTerm" />
    </template>
  </ProjectJumpToDrawerProjectsList>
</template>
