<script setup>
import { useCurrentUser, useFeatures, usePermissions } from '@/api';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import { useAppShellSidebar } from '../useAppShellSidebar';
import { useAppShellSidebarTracking } from '../useAppShellSidebarTracking';

defineProps({
  productUpdatesTotalCount: {
    type: Number,
    default: undefined,
  },
});

const { t } = useI18n();
const { showLegacyModal } = useLegacyBridge();
const { isSiteAdmin, isOwnerAccount } = usePermissions();
const { isMyShortcutsOpen, isProductUpdatesOpen, isSidebarExpanded, isSuccessPlannerOpen, isUserMenuMoreItemsOpen } =
  useAppShellSidebar();
const { trackProfileMenuItemClicked } = useAppShellSidebarTracking({ isSidebarExpanded });
const { myBoardsEnabled, projectsWorkflowsEnabled } = useFeatures();

const user = useCurrentUser();

function closeUserMoreMenu() {
  isUserMenuMoreItemsOpen.value = false;
}

function updateMyStatus() {
  trackProfileMenuItemClicked('update_my_status');

  closeUserMoreMenu();
  showLegacyModal({
    modalName: 'addOrEditStatus',
  });
}

function openMyShortcuts() {
  trackProfileMenuItemClicked('my_shortcuts');

  closeUserMoreMenu();
  isMyShortcutsOpen.value = !isMyShortcutsOpen.value;
}

function handleMyBoardsClicked() {
  trackProfileMenuItemClicked('my_boards');

  closeUserMoreMenu();
}

function openSuccessReport() {
  trackProfileMenuItemClicked('success_planner');

  closeUserMoreMenu();
  isSuccessPlannerOpen.value = true;
}

function openWhatsNew() {
  trackProfileMenuItemClicked('whats_new');

  closeUserMoreMenu();
  isProductUpdatesOpen.value = true;
}
</script>

<template>
  <WidgetOptionsMenu v-model="isUserMenuMoreItemsOpen" location="end" openOnHover openOnClick>
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <WidgetOptionsMenuItem v-if="isOwnerAccount" @click="updateMyStatus">
      <template #prepend>
        <LscIcon icon="lsi-announcement" />
      </template>
      <VListItemTitle>{{ t('Update my status') }}</VListItemTitle>
    </WidgetOptionsMenuItem>
    <WidgetOptionsMenuItem @click="openMyShortcuts">
      <template #prepend>
        <LscIcon icon="lsi-bookmarks" />
      </template>
      <VListItemTitle>{{ t('My shortcuts') }}</VListItemTitle>
    </WidgetOptionsMenuItem>
    <WidgetOptionsMenuItem
      v-if="myBoardsEnabled && !projectsWorkflowsEnabled"
      class="focus-visible:bg-selected"
      tabindex="0"
      :to="`/people/${user.id}/boards`"
      link
      @click="handleMyBoardsClicked"
    >
      <template #prepend>
        <LscIcon icon="lsi-dashboard" />
      </template>
      <VListItemTitle>{{ t('My boards') }}</VListItemTitle>
    </WidgetOptionsMenuItem>
    <VDivider />
    <WidgetOptionsMenuItem v-if="isSiteAdmin" @click="openSuccessReport">
      <template #prepend>
        <LscIcon icon="lsi-health" />
      </template>
      <VListItemTitle>{{ t('Success planner') }}</VListItemTitle>
    </WidgetOptionsMenuItem>
    <WidgetOptionsMenuItem @click="openWhatsNew">
      <template #prepend><LscIcon icon="lsi-whats-new" /></template>
      <VListItemTitle>{{ t("What's new?") }}</VListItemTitle>

      <template #append>
        <LscBadge v-if="productUpdatesTotalCount > 0" :count="productUpdatesTotalCount" variant="highlight" />
      </template>
    </WidgetOptionsMenuItem>
  </WidgetOptionsMenu>
</template>
