<script setup>
import { useI18n } from '@/util';
import SpacesDrawerPageVListItem from './SpacesDrawerPageVListItem.vue';
import SpacesDrawerSpaceVListItem from './SpacesDrawerSpaceVListItem.vue';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  step: {
    type: Number,
    default: 10,
  },
});

const count = defineModel('count', { type: Number, required: true });

const { t } = useI18n();

const starred = computed(() => unref(props.state.items));
</script>

<template>
  <WidgetLoadingState :state="state">
    <template #default>
      <VList density="compact" class="m-0 !px-4 !pt-4">
        <div v-for="starredItem in starred" :key="starredItem.id">
          <SpacesDrawerSpaceVListItem v-if="starredItem.type === 'spaces'" :space="starredItem" />
          <SpacesDrawerPageVListItem v-else-if="starredItem.type === 'pages'" :page="starredItem" />
        </div>
        <WidgetLoadingProgress v-if="!state.inSync.value" />
        <WidgetLoadingLazy v-model:count="count" :step="step" :state="state" />
      </VList>
    </template>
    <template #loading>
      <WidgetLoadingProgress :message="t('Loading starred')" />
    </template>
    <template #blank>
      <slot name="blank" />
    </template>
  </WidgetLoadingState>
</template>
