import { DateTime } from 'luxon';

const itemTypes = {
  users: 'users',
  teams: 'teams',
  companies: 'companies',
};

export function normalizeUsers(items, users, companies, teams) {
  if (items == null) {
    return undefined;
  }
  return items
    .map(({ id, type }) => {
      if (!id) {
        return null;
      }
      if (type === itemTypes.users && users[id]) {
        return {
          ...users[id],
          assigneeType: itemTypes.users,
          entityType: 'user',
        };
      }
      if (type === itemTypes.companies && companies[id]) {
        return {
          ...companies[id],
          assigneeType: itemTypes.companies,
          entityType: 'company',
        };
      }
      if (type === itemTypes.teams && teams[id]) {
        return {
          ...teams[id],
          assigneeType: itemTypes.teams,
          entityType: 'team',
        };
      }
      return null;
    })
    .filter(Boolean);
}

export function normalizeTask(task, included = {}) {
  const {
    projects = {},
    projectPermissions = {},
    tasklists = {},
    users = {},
    companies = {},
    teams = {},
    timeTotals = {},
    tags = {},
    taskSequences = {},
    cards = {},
    columns = {},
    stages = {},
    workflows = {},
    files = {},
    comments = {},
    subtaskStats = {},
    lockdowns = {},
    customfieldTasks: _customfieldTasks = {},
    customfields = {},
    tasks = {}, // holds parentTasks etc.
  } = included;

  const customfieldTasks = Object.values(_customfieldTasks);
  const commentValues = Object.values(comments);
  const permissions = Object.values(projectPermissions)[0];

  function normalize(_task) {
    return {
      ..._task,
      projectPermissions: _task.projectPermissions || permissions,
      description: _task.description ?? '',
      startDate: _task.startDate ? DateTime.fromISO(_task.startDate) : null,
      dueDate: _task.dueDate ? DateTime.fromISO(_task.dueDate) : null,
      createdAt: _task.createdAt ? DateTime.fromISO(_task.createdAt) : null,
      updatedAt: _task.updatedAt ? DateTime.fromISO(_task.updatedAt) : null,
      completedOn: _task.completedOn ? DateTime.fromISO(_task.completedOn) : null,
      originalDueDate: _task.originalDueDate ? DateTime.fromISO(_task.originalDueDate) : null,
      dateLastModified: _task.dateLastModified ? DateTime.fromISO(_task.dateLastModified) : null,
      ...(tags && _task.tagIds && _task.tagIds.length > 0
        ? { tags: _task.tagIds.filter((id) => id in tags).map((id) => tags[id]) }
        : {}),
      ...(lockdowns && _task.lockdown && lockdowns[_task.lockdown.id]
        ? { lockdown: lockdowns[_task.lockdown.id] }
        : {}),
      ...(timeTotals && timeTotals[_task.id] ? { timeTotals: timeTotals[_task.id] } : {}),
      ...(taskSequences && _task.sequenceId ? { sequence: taskSequences[_task.sequenceId] } : {}),
      ...(cards && columns && _task.card && cards[_task.card.id]
        ? {
            column: columns[cards[_task.card.id].column.id],
            card: {
              ...cards[_task.card.id],
              column: columns[cards[_task.card.id].column.id],
            },
          }
        : {}),
      ...(comments ? { comments: commentValues.filter((comment) => comment.objectId === _task.id) } : {}),
      ...(_task.completedBy && users[_task.completedBy] ? { completedBy: users[_task.completedBy] } : {}),
      ...(_task.createdBy && users[_task.createdBy] ? { createdBy: users[_task.createdBy] } : {}),
      ...(_task.updatedBy && users[_task.updatedBy] ? { updatedBy: users[_task.updatedBy] } : {}),
      assignees: normalizeUsers(_task.assignees, users, companies, teams),
      attachments:
        task.attachments?.map((attachment) => ({
          ...attachment,
          ...files[attachment.id],
        })) || [],
      changeFollowers: normalizeUsers(_task.changeFollowers, users, companies, teams),
      commentFollowers: normalizeUsers(_task.commentFollowers, users, companies, teams),

      customfieldValues: customfieldTasks.reduce((acc, field) => {
        if (field.taskId === _task.id) {
          acc[field.customfieldId] = {
            ...customfields[field.customfieldId],
            value: field.value,
            customfieldId: field.id,
          };
        }
        return acc;
      }, {}),

      // TODO: Ask Greg - does this filter need optimizing? - Topper
      // TODO? improve typing
      ...(customfieldTasks
        ? {
            customFieldValues: Object.fromEntries(
              customfieldTasks
                .filter((field) => field.taskId === _task.id)
                .map((customFieldTaskInfo) => [customFieldTaskInfo.customfield.id, customFieldTaskInfo.value]),
            ),
          }
        : {}),

      ...(() => {
        if (_task.tasklistId && tasklists[_task.tasklistId]) {
          const taskList = tasklists[String(_task.tasklistId)];

          const added = {
            taskListId: _task.tasklistId,
            taskList, // NOTE: notice the camelCase
            tasklist: taskList,
          };

          const project = projects[taskList.projectId];

          if (project) {
            const company = companies[project.companyId];

            const baseProject = {
              ...project,
              projectPermissions: permissions,
            };

            return {
              ...added,
              project: company
                ? {
                    ...baseProject,
                    company,
                  }
                : baseProject,
              projectId: taskList.projectId,
              tasklist: {
                ...taskList,
                baseProject,
              },
            };
          }

          return added;
        }

        return {};
      })(),

      subtaskStats: subtaskStats[_task.id],

      workflowStages:
        _task.workflowStages?.map((wf) => ({
          ...wf,
          workflow: workflows[wf.workflowId],
          stage: stages[wf.stageId],
        })) ?? [],
    };
  }

  // First pass, apply normalisations to target task and all included tasks
  const mainTask = normalize(task);
  const includedTasksMap = Object.values(tasks).reduce((acc, t) => {
    acc.set(t.id, normalize(t));
    return acc;
  }, new Map());

  // Finally, apply any nested (normalised) task properties to all tasks
  mainTask.parentTask = includedTasksMap.get(mainTask.parentTaskId) ?? null;

  includedTasksMap.forEach((v, k, m) => {
    // eslint-disable-next-line no-param-reassign
    v.parentTask = m.get(v.parentTaskId) ?? null;
  });

  return mainTask;
}

export function normalizeTasks(_tasks, included = {}) {
  return _tasks.map((task) => normalizeTask(task, included));
}

export function computeDisplayOrder(task, tasks) {
  if (task.positionAfterTaskId != null) {
    const previousTask = tasks.find(({ id }) => id === task.positionAfterTaskId);
    if (previousTask) {
      return previousTask.displayOrder + 0.5;
    }
    return task.positionAfterTaskId < 0 ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
  }
  return task.displayOrder ?? Number.MAX_SAFE_INTEGER;
}

export function normalizeNewTask(inputTask, tasks) {
  const newTask = { ...inputTask };
  newTask.id ??= -1;
  newTask.parentTaskId ??= 0;
  // TODO taskListId is deprecated - remove when no longer used
  newTask.tasklistId ??= newTask.taskListId ?? 0;
  // TODO taskListId is deprecated - remove when no longer used
  newTask.taskListId = newTask.tasklistId;
  newTask.projectId ??= 0;
  newTask.assignees ??= [];
  // TODO use current user
  newTask.createdBy ??= {};
  newTask.priority ??= '';
  newTask.tags ??= [];
  newTask.displayOrder = computeDisplayOrder(newTask, tasks);

  // TODO taskList is deprecated - remove when no longer used
  newTask.tasklist ??= newTask.taskList;

  // Try to reuse the actual project and tasklist
  // which are included in the already loaded tasks.
  if (newTask.tasklist == null) {
    for (let i = 0; i < tasks.length; i += 1) {
      const task = tasks[i];
      if (task.tasklistId === newTask.tasklistId) {
        newTask.tasklist = task.tasklist;
        newTask.project ??= task.project;
        break;
      }
    }
  }

  // Fall back to a placeholder task list.
  newTask.tasklist ??= {
    id: newTask.tasklistId,
    name: '',
    displayOrder: 0,
  };

  // TODO taskList is deprecated - remove when no longer used
  newTask.taskList = newTask.tasklist;

  // Fall back to a placeholder project.
  newTask.project ??= {
    id: newTask.projectId,
    name: '',
  };

  return newTask;
}

/**
 * Normalizes the specified task params to ensure that the minimum required data is always loaded.
 */
export function normalizeTaskParams(params) {
  const normalizedParams = { ...unref(params) };

  normalizedParams.include = [
    ...new Set(
      (normalizedParams.include || '')
        .split(',')
        .map((item) => item.trim())
        .filter(Boolean)
        .concat(['taskLists', 'taskListNames', 'projectNames', 'projects']),
    ),
  ].join(',');

  if (typeof normalizedParams['fields[tasks]'] === 'string') {
    normalizedParams['fields[tasks]'] = [
      ...new Set(
        normalizedParams['fields[tasks]']
          .split(',')
          .map((item) => item.trim())
          .filter(Boolean)
          .concat(['id', 'tasklistId']),
      ),
    ].join(',');
  }

  if (typeof normalizedParams['fields[tasklists]'] === 'string') {
    normalizedParams['fields[tasklists]'] = [
      ...new Set(
        normalizedParams['fields[tasklists]']
          .split(',')
          .map((item) => item.trim())
          .filter(Boolean)
          .concat(['id', 'projectId']),
      ),
    ].join(',');
  }

  if (typeof normalizedParams['fields[projects]'] === 'string') {
    normalizedParams['fields[projects]'] = [
      ...new Set(
        normalizedParams['fields[projects]']
          .split(',')
          .map((item) => item.trim())
          .filter(Boolean)
          .concat(['id']),
      ),
    ].join(',');
  }

  return normalizedParams;
}
