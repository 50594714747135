<script setup>
import { AppShellDrawerPanel, AppShellDrawerTitle, useAppShellSidebar } from '@/appShell';
import { useCurrentProject } from '@/route';
import { useI18n, useLocalStorage } from '@/util';
import SearchDrawerMenu from './SearchDrawerMenu.vue';
import SearchDrawerRecents from './SearchDrawerRecents.vue';
import SearchDrawerResults from './SearchDrawerResults.vue';

const { activeDrawerPanel, clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { t } = useI18n();

const searchTerm = ref('');
const project = useCurrentProject();
const shouldSearchCurrentProject = useLocalStorage('teamwork/SearchDrawer/shouldSearchCurrentProject', false);

const selectedSearchEntity = ref('everything');

const shouldShowSearchCurrentProject = computed(() => project.value && selectedSearchEntity.value !== 'projects');
const shouldShowProjectsOption = computed(() => project.value && shouldSearchCurrentProject.value);
const searchEntities = computed(() =>
  [
    { entity: 'everything', name: t('Search everything'), shortName: t('everything'), icon: 'lsi-search-all' },
    { entity: 'tasks', name: t('Search tasks'), shortName: t('tasks'), icon: 'lsi-task' },
    !shouldShowProjectsOption.value && {
      entity: 'projects',
      name: t('Search projects'),
      shortName: t('projects'),
      icon: 'lsi-project',
    },
    { entity: 'milestones', name: t('Search milestones'), shortName: t('milestones'), icon: 'lsi-milestone' },
    { entity: 'people', name: t('Search people'), shortName: t('people'), icon: 'lsi-assignee' },
    { entity: 'notebooks', name: t('Search notebooks'), shortName: t('notebooks'), icon: 'lsi-notebook' },
    { entity: 'messages', name: t('Search messages'), shortName: t('messages'), icon: 'lsi-email' },
    { entity: 'links', name: t('Search links'), shortName: t('links'), icon: 'lsi-link' },
  ].filter(Boolean),
);

function selectSearchEntity(entity) {
  selectedSearchEntity.value = entity;
}

const advancedSearchRoute = computed(() => {
  if (searchTerm.value) {
    return `/search/${encodeURIComponent(searchTerm.value)}`;
  }
  return '/search';
});

watch(
  project,
  () => {
    if (project.value && selectedSearchEntity.value === 'projects') {
      selectSearchEntity('everything');
    }
  },
  { immediate: true },
);

watch(selectedSearchEntity, (val) => {
  if (val === 'projects') {
    shouldSearchCurrentProject.value = false;
  }
});
</script>

<template>
  <AppShellDrawerPanel v-if="activeDrawerPanel === 'search'" data-identifier="side-nav-search-panel">
    <template #header>
      <AppShellDrawerTitle :title="t('Search')" />
    </template>

    <template #content>
      <div class="flex h-full flex-col" data-identifier="app-nav__search-panel__search-input">
        <!-- Search input with drop menu -->
        <VTextField
          id="searchDrawerInput"
          v-model.trim="searchTerm"
          class="z-10 mx-6 mb-6 grow-0"
          :placeholder="t('Search')"
          v-bind="VTextFieldPanelSearch"
          autofocus
        >
          <template #append-inner>
            <SearchDrawerMenu v-model="selectedSearchEntity" :options="searchEntities" />
          </template>
        </VTextField>

        <KeepAlive include="SearchDrawerRecents">
          <SearchDrawerRecents v-if="!searchTerm" />
          <SearchDrawerResults
            v-else
            :searchTerm="searchTerm"
            :shouldSearchCurrentProject="shouldSearchCurrentProject"
            :entity="selectedSearchEntity"
          />
        </KeepAlive>
      </div>
    </template>
    <template #footer>
      <div class="mt-2 h-px w-full border-t border-separator-on-surface-default" />
      <div class="pb-7 pt-4">
        <div class="flex items-center justify-between text-body-1">
          <RouterLink
            :to="advancedSearchRoute"
            class="text-body-1 font-semibold text-primary no-underline"
            data-identifier="app-nav__search-panel__footer__advanced-search"
            @click="clearActiveDrawerPanelIfNotPinned"
          >
            {{ t('Advanced search') }}
          </RouterLink>
          <LscSwitch
            v-if="shouldShowSearchCurrentProject"
            v-model="shouldSearchCurrentProject"
            data-identifier="app-nav__search-panel__footer__only-current-project"
            :label="t('Only current project')"
          />
        </div>
      </div>
    </template>
  </AppShellDrawerPanel>
</template>
