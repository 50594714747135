<script setup>
import { usePendo } from '@/api';
import { useHelpCenter } from '../..';
import { STATE_KNOWLEDGE_BASE_ARTICLE, STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES } from '../../constants';
import { useCompositeSubcategoriesAndArticlesLoader } from '../../useCompositeSubcategoriesAndArticlesLoader';
import HelpCenterDrawerMenuMedium from '../HelpCenterDrawerMenuMedium.vue';

const props = defineProps({
  breadcrumb: {
    type: String,
    default: '',
  },
  categorySlug: {
    type: String,
    default: '',
  },
});

const { trackPendoEvent } = usePendo();
const { pushState, HELP_CENTER_COMMON_METRICS } = useHelpCenter();

const state = useCompositeSubcategoriesAndArticlesLoader({ categorySlug: props.categorySlug, count: Infinity });
const { items } = state;

function handleItemClicked(item) {
  if (item.type === 'subcategory') {
    trackPendoEvent({
      eventName: 'HELP_EVENT',
      commonMetrics: HELP_CENTER_COMMON_METRICS,
      metadata: {
        event_action: 'knowledge_base_subcategory_clicked',
        event_label: item.title.toLowerCase(),
      },
    });

    pushState(STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES, {
      categorySlug: item.slug,
      categoryName: item.title,
      breadcrumb: `${props.breadcrumb} / ${item.title}`,
    });
  } else {
    trackPendoEvent({
      eventName: 'HELP_EVENT',
      commonMetrics: HELP_CENTER_COMMON_METRICS,
      metadata: {
        event_action: 'knowledge_base_item_clicked',
        event_label: item.title.toLowerCase(),
      },
    });

    pushState(STATE_KNOWLEDGE_BASE_ARTICLE, { articleSlug: item.slug, categorySlug: item.categorySlug });
  }
}
</script>

<template>
  <div class="m-0 h-full max-h-[inherit] overflow-y-auto p-0">
    <WidgetLoadingState :state="state">
      <div class="mx-6 mb-6">
        <HelpCenterDrawerMenuMedium :title="breadcrumb" :items="items" @itemClicked="handleItemClicked" />
      </div>
    </WidgetLoadingState>
  </div>
</template>
