<script setup>
import Draggable from 'vuedraggable';
import { getRandomPickerColor, useI18n } from '@/util';

const modelValue = defineModel({
  type: Array,
  default: () => [],
});

const { t } = useI18n();

const optionsListEnd = shallowRef(null);
const autofocusIndex = shallowRef(0);

function onClickAddOption() {
  modelValue.value.push({
    value: '',
    color: getRandomPickerColor(),
  });

  autofocusIndex.value = modelValue.value.length - 1;

  nextTick(() => {
    optionsListEnd.value.scrollIntoView({ behavior: 'smooth' });
  });
}

function onClickRemoveOption(opt) {
  const opts = modelValue.value;
  const idx = opts.indexOf(opt);
  if (idx > -1) {
    opts.splice(idx, 1);
  }
}
</script>

<template>
  <div>
    <label class="text-body-2 font-semibold">{{ t('Options') }}</label>
    <div class="max-h-56 overflow-y-auto">
      <Draggable
        v-model="modelValue"
        :animation="100"
        :componentData="{ class: 'mt-2 flex flex-col gap-2 items-stretch' }"
        :preventOnFilter="false"
        filter="input"
        ghostClass="bg-surface-hover"
        itemKey="color"
      >
        <template #item="{ element, index }">
          <div class="flex items-center">
            <VIcon class="mr-1 cursor-move">lsi-drag</VIcon>
            <LscColorPickerMenu v-model="element.color" :required="true">
              <template #activator="activator">
                <LscColorPickerButton v-bind="activator.props" :color="element.color" :ariaHasPopup="true" />
              </template>
            </LscColorPickerMenu>
            <VTextField
              v-model.trim="element.value"
              :label="t('Option name')"
              singleLine
              :autofocus="autofocusIndex === index"
              density="compact"
              class="ml-2"
            />
            <LscIconButton
              icon="lsi-delete"
              class="ml-2"
              :ariaLabel="t('Remove')"
              @click="onClickRemoveOption(element)"
            />
          </div>
        </template>
      </Draggable>
      <div ref="optionsListEnd" />
    </div>
    <LscButton variant="plain-secondary" prependIcon="lsi-add" @click="onClickAddOption">
      {{ t('Add option') }}
    </LscButton>
  </div>
</template>
