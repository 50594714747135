import { Interval } from 'luxon';
import { useI18n } from '@/util';

export function useBudgetFormat() {
  const { convertFromCentValue, formatCurrency, t, formatMinutes, formatDate, formatDateRange } = useI18n();

  function getBudgetCapacityColor(percentage) {
    if (percentage < 50) {
      return 'var(--lsds-a-color-surface-success-default)';
    }
    if (percentage < 80) {
      return 'var(--lsds-a-color-surface-warning-default)';
    }
    return 'var(--lsds-a-color-surface-critical-default)';
  }

  /**
   * Returns a formatted string for the date range of a budget
   * @param {object} budget
   * @param {DateTime} budgetToFormat.startDateTime
   * @param {DateTime} budgetToFormat.endDateTime
   * @param {DateTime} budgetToFormat.completedAt
   * @param {object} config
   * @param {boolean} config.wholeBudgetRange - if true, and the budget is repeating, the budget completedAt date will be used
   * @returns {String}
   */
  function formatBudgetDateRange(budget, { wholeBudgetRange = false } = {}) {
    if (!budget) {
      return '';
    }
    const { startDateTime } = budget;
    const endDateTime =
      (wholeBudgetRange && budget.isRepeating
        ? budget.completedAt?.minus({ days: 1 })
        : budget.endDateTime?.minus({ days: 1 })) ?? null;

    if (!endDateTime) {
      return t('{startDateTime} - ongoing', { startDateTime: formatDate(startDateTime) });
    }
    return formatDateRange(Interval.fromDateTimes(startDateTime, endDateTime));
  }

  /**
   * formats a currency value to use currency symbol
   * @param {Number} minutes value to get represented as shorthand
   * @param {'compact'|'standard'} notation
   * @returns {String}
   */
  function formatTimeBudgetCapacity(minutes) {
    return formatMinutes(minutes);
  }

  /**
   * formats a currency value to use currency symbol
   * @param {Number} capacity value to get represented as shorthand
   * @param {'compact'|'standard'} notation
   * @returns {String}
   */
  function formatFinanceBudgetCapacity(capacity, notation = 'standard') {
    const capacityAsNumber = Number(convertFromCentValue(capacity));
    return formatCurrency(capacityAsNumber, { notation });
  }

  function formatBudgetCapacity(budget) {
    if (!budget) {
      return () => '';
    }
    if (budget.type.toLowerCase() === 'time') {
      return formatTimeBudgetCapacity;
    }
    return formatFinanceBudgetCapacity;
  }

  function getBudgetCategoryName(budget) {
    if (budget.isRetainer) {
      return t('Retainer');
    }
    if (budget.budgetCategory === 'standard') {
      return t('Time & materials');
    }
    if (budget.budgetCategory === 'fixedfee') {
      return t('Fixed fee');
    }
    return '';
  }

  return {
    getBudgetCapacityColor,
    formatBudgetCapacity,
    formatBudgetDateRange,
    getBudgetCategoryName,
  };
}
