import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';

export function useFeatureTrialActions() {
  const api = useAxios();
  const { t } = useI18n();

  /**
   * Post start a feature trial
   * @param {object} payload
   * @param {string} payload.name
   * @param {number} payload.numDays
   */
  function startFeatureTrial(payload) {
    const startFeatureTrialUrl = '/account/featuretrial.json';

    const promise = api.post(
      startFeatureTrialUrl,
      {
        trial: payload,
      },
      {
        errorMessage: t('There was an error starting the trial, please try again or contact the support team.'),
      },
    );

    return promise;
  }

  return {
    startFeatureTrial,
  };
}
