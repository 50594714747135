<script setup>
import { provideHotjar } from '../features/useHotjar';
import { providePendo } from '../features/usePendo';
import { provideInbox } from '../inbox/useInbox';
import { provideNotifications } from '../notifications/useNotifications';
import { provideHasActiveProjects } from '../projects/useHasActiveProjects';

provideInbox();
providePendo();
provideHotjar();
provideNotifications();
provideHasActiveProjects();
</script>

<template>
  <slot />
</template>
