<script setup>
import { useClamp } from '@vueuse/math';
import { generateUuid } from '@/util';
import { LscLinearChartDirections, LscLinearChartIs, LscLinearChartVariants } from './constants';

const props = defineProps({
  /**
   * The HTML tag to use for the linear chart.
   * @type {PropType<typeof LscLinearChartIs[number]>}
   */
  is: {
    type: String,
    default: 'div',
    validator: (value) => LscLinearChartIs.includes(value),
  },
  /**
   * The direction of the progress bar.
   * @type {PropType<typeof LscLinearChartDirections[number]>}
   */
  direction: {
    type: String,
    default: 'horizontal',
    validator: (type) => LscLinearChartDirections.includes(type),
  },
  /**
   * The variant of the progress bar. This will change the color of the progress bar and the background.
   * @type {PropType<typeof LscLinearChartVariants[number]>}
   */
  variant: {
    type: String,
    default: 'neutral',
    validator: (variant) => LscLinearChartVariants.includes(variant),
  },
  /**
   * Enable the pulse animation.
   */
  animation: {
    type: Boolean,
    default: false,
  },
  /**
   * The progress percentage of the progress bar.
   * @type {PropType<Number>}
   */
  percentage: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['click']);

const lscLinearChartVariantStyleConfig = tv({
  slots: {
    container:
      'text-neutral-[--lsds-g-palette-neutral-40] relative inline-flex h-full w-full overflow-hidden rounded-md text-body-2 transition duration-500 ease-[cubic-bezier(0.32,0.31,0.16,1)]',
    progress: 'absolute rounded-md transition-all duration-500 ease-[cubic-bezier(0.32,0.31,0.16,1)]',
    label: 'absolute flex h-full w-full items-center p-2 transition-opacity ease-in-out',
  },
  variants: {
    variant: {
      neutral: {
        container: 'bg-[color:--lsds-c-chart-cell-color-surface-neutral]',
        progress: 'bg-[color:--lsds-c-chart-cell-color-indicator-neutral]',
      },
      warning: {
        container: 'bg-[color:--lsds-c-chart-cell-color-surface-warning]',
        progress: 'bg-[color:--lsds-c-chart-cell-color-indicator-warning]',
      },
      critical: {
        container: 'bg-[color:--lsds-c-chart-cell-color-surface-critical]',
        progress: 'bg-[color:--lsds-c-chart-cell-color-indicator-critical]',
      },
      success: {
        container: 'bg-[color:--lsds-c-chart-cell-color-surface-success]',
        progress: 'bg-[color:--lsds-c-chart-cell-color-indicator-success]',
      },
      disabled: {
        progress: 'bg-[color:--lsds-a-color-action-primary-disabled]',
      },
      unavailable: {
        container:
          'bg-[repeating-linear-gradient(-45deg,var(--lsds-a-color-surface-default)_0px_8px,transparent_8px_16px)]',
      },
      empty: {
        container: 'border bg-transparent',
        label: 'justify-center',
      },
    },
    animation: {
      true: {
        container: 'animate-pulse',
      },
    },
    direction: {
      vertical: {
        container: 'bg-transparent',
        progress: 'bottom-0 left-0 h-[--progress] w-full',
        label: 'justify-center',
      },
      horizontal: {
        progress: 'inset-y-0 h-full w-[--progress]',
      },
    },
  },
});

const progress = computed(
  () => `${props.variant === 'disabled' ? 100 : useClamp(Number(props.percentage), 0, 100).value}%`,
);

const classes = computed(() => lscLinearChartVariantStyleConfig(props));

function handleClick(event) {
  if (props.is !== 'button') {
    return;
  }
  emit('click', event);
}

// Generates a new key for the default slot to force a re-render when the percentage prop changes
// This is needed to trigger the ease transition on the progress bar
const slotKey = shallowRef(generateUuid());
watch(
  () => props.percentage,
  () => {
    slotKey.value = generateUuid();
  },
);
</script>
<template>
  <Component
    :is="is"
    :class="classes.container()"
    role="meter"
    :aria-valuetext="progress"
    aria-valuemin="0"
    aria-valuemax="100"
    @click="handleClick"
  >
    <div :style="{ '--progress': progress }" :class="classes.progress()" />
    <Transition
      v-if="variant !== 'disabled'"
      enterActiveClass="transition-opacity duration-500 ease-in-out"
      enterFromClass="opacity-0"
      enterToClass="opacity-100"
    >
      <div :key="slotKey" :class="classes.label()">
        <!-- @slot LinearChart text content. Must not have any interactable elements inside this slot when the 'is' prop is 'button' -->
        <slot />
      </div>
    </Transition>
  </Component>
</template>
