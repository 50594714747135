import { paragon } from '@useparagon/connect';
import { useRouter } from 'vue-router';
import {
  useCurrentAccount,
  useEmbeddedChat,
  useExperiment46,
  useFeatures,
  useIntegrationParagonAuthActions,
  useIntegrationParagonAuthV1Loader,
} from '@/api';
import { useAppShellSidebar, useSampleProjects } from '@/appShell';
import { useQuickView, useRecency, useRoute } from '@/route';
import { isEventOnTextInput, useI18n } from '@/util';
import { badgeIds, useBadge } from '@/module/badge';
import { openToStateStorage } from '@/module/helpCenter';
import { useLegacySwitches } from './useLegacySwitches';

const useLegacyBridgeSymbol = Symbol('useLegacyBridge');

// Main global provider for the legacy associated interface
function LegacyBridge() {
  const enabledSwitches = useLegacySwitches();
  const { clearActiveDrawerPanelIfNotPinned, isQuickAddOpen } = useAppShellSidebar();
  const route = useRoute();
  const router = useRouter();
  const { openQuickView, isQuickViewLocked, isQuickViewOpen } = useQuickView();
  const toast = useLsToast();
  const embeddedChat = useEmbeddedChat();
  const { projectsLightspeedTaskDetails } = useFeatures();
  const account = useCurrentAccount();
  const { deleteSampleData } = useSampleProjects();
  const legacyInboxUnreadCount = shallowRef();
  const legacyIframeEl = shallowRef(null);
  const isLegacyModalOpen = shallowRef(false);
  const isLegacyInteractionDisabled = shallowRef(false);
  const isLegacyFullscreenEnabled = shallowRef(false);
  const isLegacyInitialized = shallowRef(false);
  const isLegacyIframeLoaded = shallowRef(false);
  const isLegacyConnected = computed(() => isLegacyInitialized.value && isLegacyIframeLoaded.value);
  const legacyDocumentTitle = shallowRef(null);
  const projectChannelId = shallowRef(null);
  const activeTaskDetailsId = shallowRef();
  // only override the legacy base URL if we are on localhost
  const legacyBaseUrl =
    window.location.hostname === 'localhost' && import.meta.env.TW_APP_TWA_PORT
      ? `http://localhost:${import.meta.env.TW_APP_TWA_PORT}`
      : '';
  const { t } = useI18n();

  const dialogName = shallowRef('');
  const dialogProps = shallowRef(null);
  const isDialogOpen = shallowRef(false);
  const activeLegacyViewSwitch = shallowRef(null);
  const isLegacyQuickViewOpen = shallowRef(false);

  const { item: authData } = useIntegrationParagonAuthV1Loader();
  const { ensureAuth, updateAPIKey } = useIntegrationParagonAuthActions();
  const recent = shallowRef();
  useRecency(recent);

  // EXP 46
  const { isExp46Variation } = useExperiment46();
  const { completeBadge } = useBadge();

  const frameOrigin = legacyBaseUrl || window.location.origin;

  const legacyViewEnabledCount = shallowRef(0);
  const shouldUseLegacyView = computed(() => Boolean(legacyViewEnabledCount.value));

  const legacyPageToShow = computed(() => {
    const qs = new URLSearchParams(route.query).toString();
    return shouldUseLegacyView.value
      ? route.fullPath
      : // if we're idle on a project page we still need to send TWA the projectId
        `/idle${route.params.projectId ? `/${route.params.projectId}` : ''}${qs ? `?${qs}` : ''}`;
  });

  const iframeVisibilityMode = computed(() => {
    if (
      (isLegacyModalOpen.value || isLegacyQuickViewOpen.value) &&
      (!shouldUseLegacyView.value || (isQuickViewOpen.value && isQuickViewLocked.value))
    ) {
      return 'modal';
    }
    if (!shouldUseLegacyView.value && !isLegacyModalOpen.value && !isLegacyQuickViewOpen.value) {
      return 'hidden';
    }
    return 'normal';
  });

  const shouldUseLightspeedTaskDetails = computed(() => projectsLightspeedTaskDetails.value);

  function toggleFullscreen(newState) {
    isLegacyFullscreenEnabled.value = newState;
  }

  function disableLegacyInteraction() {
    isLegacyInteractionDisabled.value = true;
  }
  function enableLegacyInteraction() {
    isLegacyInteractionDisabled.value = false;
  }

  function enableLegacyView() {
    legacyViewEnabledCount.value++;
  }
  function disableLegacyView() {
    if (legacyViewEnabledCount.value > 0) {
      legacyViewEnabledCount.value--;
    } else {
      // eslint-disable-next-line no-console
      console.warn('useLegacyBridge: disableLegacyView called when the legacy view is already disabled');
    }
  }

  function hasMatchingRoute(path) {
    return router.resolve(path)?.matched.length;
  }

  function toastCallback(callbackId, callbackName) {
    if (!isLegacyConnected.value) {
      return;
    }
    legacyIframeEl.value?.contentWindow.postMessage(
      {
        name: 'lightspeed:toast-callback',
        callbackId,
        callbackName,
      },
      frameOrigin,
    );
  }

  function setProjectChannelId(id) {
    projectChannelId.value = id;
    legacyIframeEl.value?.contentWindow.postMessage({ name: 'lightspeed:chat:projectChannelId', id }, frameOrigin);
  }

  function setChatEnabled(isEnabled) {
    legacyIframeEl.value?.contentWindow.postMessage({ name: 'lightspeed:chat:isEnabled', isEnabled }, frameOrigin);
  }

  async function handleIncomingLegacyMessage(event) {
    if (event.origin !== frameOrigin) {
      return;
    }

    // Respond to TWA checking if it's in lightspeed
    if (event.data.name === 'twa:initialized') {
      isLegacyInitialized.value = true;
    }

    // Respond to backlinks from TWA
    if (event.data.name === 'twa:route-changed') {
      const path = event.data.to[0] !== '/' ? `/${event.data.to}` : event.data.to;

      // TWA sets itself to idle when not active and Lightspeed shouldn't follow it.
      if (path.startsWith('/idle')) {
        return;
      }

      if (path && hasMatchingRoute(path)) {
        if (event.data.silent) {
          // needs pendo to be propagated for pendo academy guides to work properly
          const pendoQs = route.query.pendo ? `?pendo=${route.query.pendo}` : '';
          window.history.pushState(null, '', `/app${path}${pendoQs}`);
        } else if (path !== route.fullPath) {
          if (event.data.createHistoryEntry) {
            router.push(path);
          } else {
            router.replace(path);
          }
        }
      }
    }
    if (event.data.name === 'twa:click') {
      // don't fire click-outside if a quick view is open and a task details activator was clicked.
      if (
        shouldUseLightspeedTaskDetails.value &&
        event.data.isTaskDetailsActivator &&
        window.history.state?.quickViewBackgroundPath
      ) {
        return;
      }
      // simulate mousedown & click events to Vuetify
      document.querySelector('[data-v-app]')?.dispatchEvent(new MouseEvent('mousedown'));
      document.querySelector('[data-v-app]')?.dispatchEvent(new MouseEvent('click'));
    }

    if (event.data.name === 'twa:keydown') {
      document.body.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: event.data.key,
          keyCode: event.data.keyCode,
          repeat: event.data.repeat,
          ctrlKey: event.data.ctrlKey,
          shiftKey: event.data.shiftKey,
          metaKey: event.data.metaKey,
          bubbles: true,
          cancelable: true,
          view: window,
          code: 'twa',
        }),
      );
    }

    // Respond to TWA open modal
    if (event.data.name === 'twa:modal-open') {
      isLegacyModalOpen.value = true;
      clearActiveDrawerPanelIfNotPinned();
    }

    // Respond to TWA close modal
    if (event.data.name === 'twa:modal-close') {
      isLegacyModalOpen.value = false;
    }

    // Respond to TWA quick view open
    if (event.data.name === 'twa:quick-view-open') {
      isLegacyQuickViewOpen.value = true;
    }
    // Respond to TWA quick view empty (close all)
    if (event.data.name === 'twa:quick-view-empty') {
      isLegacyQuickViewOpen.value = false;
    }

    // Respond to TWA quick view close
    if (event.data.name === 'twa:quick-view-close') {
      isLegacyQuickViewOpen.value = false;
    }

    // Respond to TWA Fullscreen block toggle
    if (event.data.name === 'twa:toggle-fullscreen') {
      toggleFullscreen(event.data.fullscreen);
    }

    // DEPRECATED Remove once TWA is not using this anymore
    if (event.data.name === 'twa:task-details') {
      // go to task details quickview route
      router.push({
        path: `/tasks/${event.data.taskId}`,
        // add hash to the URL if it's provided
        ...(Boolean(event.data.hash) && {
          hash: `#${event.data.hash}`,
        }),
        state: {
          quickViewBackgroundPath: route.fullPath,
        },
      });
    }

    // Respond to TWA quickview route
    if (event.data.name === 'twa:quick-view-route') {
      // Support old TWA interface. Can remove once TWA is updated.
      const path =
        event.data.itemId && event.data.itemType ? `/${event.data.itemType}/${event.data.itemId}` : event.data.path;

      openQuickView(path, {
        hash: event.data.hash,
        query: event.data.query,
        item: event.data.item,
      });
    }

    // Respond to TWA opening help panel
    if (event.data.name === 'twa:open-help-center') {
      const { state, data } = event.data;
      if (state) {
        openToStateStorage.value = { state, data };
      }
    }

    // Respond to TWA to open a lightspeed dialog
    if (event.data.name === 'twa:open-dialog') {
      dialogName.value = event.data.dialogName;
      dialogProps.value = event.data.dialogProps;
      isDialogOpen.value = true;
    }

    // Respond to TWA to open paragon dialog
    if (event.data.name === 'twa:paragon') {
      handleParagonMesssage(event.data.integrationName);
    }

    if (event.data.name === 'twa:toast-open') {
      toast[event.data.level]({
        title: event.data.title,
        message: event.data.message,
        actionText: event.data.actionText,
        action: toastCallback.bind(null, event.data.callbackId, 'action'),
        onRemove: toastCallback.bind(null, event.data.callbackId, 'onRemove'),
      });
    }

    // Respond to TWA to close a lightspeed dialog
    if (event.data.name === 'twa:close-dialog') {
      isDialogOpen.value = false;
    }

    // Catch and store the legacy document title.
    if (event.data.name === 'twa:document-title') {
      legacyDocumentTitle.value = event.data.title;
    }

    if (event.data.name === 'twa:chat:openChannel') {
      if (account.value.chatEnabled) {
        embeddedChat?.openChannelById?.(event.data.channelId);
      } else {
        window.open(`/chat/conversations/${event.data.channelId}`);
      }
    }

    if (event.data.name === 'twa:chat:openPersonById') {
      embeddedChat?.openPersonById?.(event.data.personId);
    }

    if (event.data.name === 'twa:inbox-count-update') {
      // We simply set the inbox count for use in LS nav until its fully ported from TWA
      legacyInboxUnreadCount.value = event.data.count;
    }

    // EXP 46
    if (isExp46Variation.value && event.data.name === 'twa:profile-image-uploaded') {
      completeBadge(badgeIds.sayCheese);
    }
    if (isExp46Variation.value && event.data.name === 'twa:onboarding-project-created') {
      completeBadge(badgeIds.projectPioneer);
    }

    if (event.data.name === 'twa:toggle-legacy-switch') {
      enabledSwitches[event.data.switch].value = !enabledSwitches[event.data.switch].value;
    }

    if (event.data.name === 'twa:redirect-to-checkout') {
      await deleteSampleData({ onCheckout: true });
      if (event.data.url) {
        window.location = event.data.url;
      } else {
        router.push({ path: '/redirect/checkout' });
      }
    }

    if (event.data.name === 'twa:log-recent') {
      recent.value = event.data.recent;
    }

    if (event.data.name === `twa:redirect`) {
      window.location.href = event.data.url;
    }

    if (event.data.name === `twa:open-new-tab`) {
      window.open(event.data.url, event.data.target, event.data.features);
    }
  }

  function showLegacyModal({ modalName, params }) {
    if (!isLegacyConnected.value) {
      toast.critical('Unable to open modal. Legacy application is not connected.');
      return;
    }
    legacyIframeEl.value?.contentWindow.postMessage(
      {
        name: 'lightspeed:modal-open',
        modal: {
          name: modalName,
          params,
        },
      },
      frameOrigin,
    );
  }

  function showLegacyQuickView({ name, params }) {
    if (!isLegacyConnected.value) {
      toast.critical('Legacy quickviews only work online');
      return;
    }
    legacyIframeEl.value?.contentWindow.postMessage(
      {
        name: 'lightspeed:quick-view-open',
        quickView: {
          name,
          params,
        },
      },
      frameOrigin,
    );
  }
  function closeLegacyQuickView() {
    if (!isLegacyConnected.value) {
      toast.critical('Legacy quickviews only work online');
      return;
    }
    legacyIframeEl.value?.contentWindow.postMessage({ name: 'lightspeed:quick-view-close' }, frameOrigin);
  }

  function closeAllLegacyQuickViews() {
    if (!isLegacyConnected.value) {
      toast.critical('Legacy quickviews only work online');
      return;
    }
    legacyIframeEl.value?.contentWindow.postMessage({ name: 'lightspeed:quick-view-empty' }, frameOrigin);
  }

  const legacyViewPosition = shallowRef({ top: 0, left: 0, width: '100%', height: '100%' });

  function setLegacyViewPosition(position) {
    legacyViewPosition.value = position;
  }

  function reloadLegacyPage() {
    if (!isLegacyConnected.value) {
      return;
    }
    legacyIframeEl.value?.contentWindow.location.reload();
  }

  function handleBodyClick(event) {
    if (!isLegacyConnected.value) {
      return;
    }
    if (shouldUseLegacyView.value && event.target !== document.querySelector('[data-v-app]')) {
      legacyIframeEl.value?.contentWindow.postMessage(
        {
          name: 'lightspeed:click',
        },
        frameOrigin,
      );
    }
  }
  const lightspeedModifierShortcuts = [
    { shiftKey: true, key: 'S' },
    { shiftKey: true, key: 'P' },
    { shiftKey: true, key: '?' },
  ];

  function shouldSendModifierShortcut(event) {
    return lightspeedModifierShortcuts.some((shortcut) =>
      Object.entries(shortcut).every(([attr, val]) => {
        if (attr === 'ctrlOrMetaKey') {
          return (event.ctrlKey || event.metaKey) && !event.shiftKey;
        }
        if (attr === 'shiftKey') {
          return event.shiftKey && (!event.ctrlKey || !event.metaKey);
        }
        if (attr === 'ctrlOrMetaAndShiftKey') {
          return event.shiftKey && (event.ctrlKey || event.metaKey);
        }
        if (attr === 'key') {
          return event.key.toUpperCase() === val;
        }
        return false;
      }),
    );
  }

  function handleKeyDown(e) {
    if (!isLegacyConnected.value) {
      return;
    }
    const { key, repeat, ctrlKey, shiftKey, metaKey, code, keyCode, which, altKey } = e;

    // If an editable area is in focus, allow the event to behave as normal
    if (isEventOnTextInput(e) || code === 'twa') {
      return;
    }

    // We only want to pass A-Z, 1-9 and some special character events
    if (!/^[-=?A-Z0-9]$/.test(key.toUpperCase())) {
      return;
    }
    // If a modifier key is pressed which isn't destined for TWA allow the event to behave as normal
    if ((metaKey || shiftKey || ctrlKey || altKey) && !shouldSendModifierShortcut(e)) {
      return;
    }

    if (e.defaultPrevented) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    legacyIframeEl.value?.contentWindow.postMessage(
      {
        name: 'lightspeed:keydown',
        key,
        keyCode,
        repeat,
        ctrlKey,
        shiftKey,
        metaKey,
        altKey,
        code,
        which,
      },
      frameOrigin,
    );
  }

  function updateLegacyEditorContent(text) {
    legacyIframeEl.value?.contentWindow.postMessage({ name: 'lightspeed:update-editor-content', text }, frameOrigin);
  }

  function navigateLegacyTaskDetails(offset) {
    legacyIframeEl.value?.contentWindow.postMessage({ name: 'lightspeed:navigate-task-details', offset }, frameOrigin);
  }

  async function handleParagonMesssage(integrationName) {
    if (!authData || !authData.value || !integrationName) {
      toast.critical(t('Unable to load {integrationName}.', { integrationName }));
      return;
    }

    // Paragon configuration for EU region.
    if (account.value.awsRegion.toLowerCase() === 'eu') {
      paragon.configureGlobal({
        host: 'eu.paragon.so',
      });
    }

    await ensureAuth(integrationName);
    try {
      await paragon.authenticate(authData.value.paragonProjectID, authData.value.jwt);
      paragon.connect(integrationName, { onUninstall: ({ integrationType }) => updateAPIKey(integrationType) });
    } catch (error) {
      toast.critical(t('Unable to authenticate with {integrationName}.', { integrationName }));
    }
  }

  // Lifecycle Events
  onMounted(() => {
    window.addEventListener('message', handleIncomingLegacyMessage, false);
    document.body.addEventListener('click', handleBodyClick, false);
    window.addEventListener('keydown', handleKeyDown, true);
  });
  onUnmounted(() => {
    window.removeEventListener('message', handleIncomingLegacyMessage, false);
    document.body.removeEventListener('click', handleBodyClick, false);
    window.removeEventListener('keydown', handleKeyDown, true);
  });

  watch(isLegacyConnected, () => {
    legacyIframeEl.value?.contentWindow.postMessage({ name: 'lightspeed:initialized' }, frameOrigin);
    legacyIframeEl.value?.contentWindow.postMessage(
      { name: 'lightspeed:visibility-state', mode: iframeVisibilityMode.value },
      frameOrigin,
    );

    setProjectChannelId(projectChannelId.value);
    setChatEnabled(embeddedChat.state.isEnabled);

    legacyIframeEl.value?.contentWindow.location.replace(`${legacyBaseUrl}/#${legacyPageToShow.value}`);
  });

  watch(
    legacyPageToShow,
    (_legacyPageToShow) => {
      legacyIframeEl.value?.contentWindow.location.replace(`${legacyBaseUrl}/#${_legacyPageToShow}`);
    },
    { immmediate: true },
  );

  watchEffect(() => {
    if (!legacyIframeEl.value || !isLegacyConnected.value) {
      return;
    }
    legacyIframeEl.value.contentWindow.postMessage(
      {
        name: 'lightspeed:state',
        state: {
          isQuickAddOpen: isQuickAddOpen.value,
          isQuickViewOpen: isQuickViewOpen.value,
          activeTaskDetailsId: activeTaskDetailsId.value,
          shouldUseLightspeedTaskDetails: shouldUseLightspeedTaskDetails.value,
          currentRoutePath: route.path,
          parentHref: window.location.href,
        },
      },
      frameOrigin,
    );
  });

  // Emit visibility mode to legacy app.
  watch(
    [iframeVisibilityMode, legacyIframeEl],
    ([_iframeVisibilityMode, _legacyIframeEl]) => {
      if (!isLegacyConnected.value) {
        return;
      }
      _legacyIframeEl?.contentWindow.postMessage(
        { name: 'lightspeed:visibility-state', mode: _iframeVisibilityMode },
        frameOrigin,
      );
    },
    { immediate: true },
  );

  // Exposed Interface
  return {
    legacyIframeEl,
    legacyInboxUnreadCount,
    iframeVisibilityMode,
    legacyPageToShow,
    shouldUseLegacyView,
    shouldUseLightspeedTaskDetails,
    isLegacyInteractionDisabled,
    isLegacyConnected,
    isLegacyIframeLoaded,
    isLegacyModalOpen,
    isLegacyQuickViewOpen,
    activeTaskDetailsId,
    legacyViewPosition,
    activeLegacyViewSwitch,
    legacyDocumentTitle,
    legacyBaseUrl,
    showLegacyModal,
    showLegacyQuickView,
    closeAllLegacyQuickViews,
    closeLegacyQuickView,
    disableLegacyInteraction,
    enableLegacyInteraction,
    enableLegacyView,
    disableLegacyView,
    reloadLegacyPage,
    toggleFullscreen,
    setProjectChannelId,
    setChatEnabled,
    setLegacyViewPosition,
    isLegacyFullscreenEnabled,
    dialogName,
    dialogProps,
    isDialogOpen,
    updateLegacyEditorContent,
    navigateLegacyTaskDetails,
  };
}

/**
 * @returns {ReturnType<LegacyBridge>}
 */
export function provideLegacyBridge() {
  const legacyBridge = LegacyBridge();
  provide(useLegacyBridgeSymbol, legacyBridge);
  return legacyBridge;
}

/**
 * @returns {ReturnType<LegacyBridge>}
 */
export function useLegacyBridge() {
  return inject(useLegacyBridgeSymbol);
}
