<script setup>
import * as TOKENS from '@teamwork/lightspeed-design-tokens';
import { unrefElement } from '@vueuse/core';
import { useI18n } from '@/util';
import FilterActiveChip from './FilterActiveChip.vue';
import { useFilter } from './useFilter';

defineProps({
  allowSaveFilters: {
    type: Boolean,
    default: true,
  },
  hasQuickFilters: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update', 'clear', 'showSavedFilters', 'save']);
const { t } = useI18n();
const { activeFilter, chips, dataIdentifierPrefix } = useFilter();

const menuFiltersRef = shallowRef(null);
const menuFiltersEl = computed(() => unrefElement(menuFiltersRef));

const isExpanded = shallowRef(false);
const isExpandable = computed(() => menuFiltersEl.value?.childElementCount > 6);

function toggleMoreOptions() {
  isExpanded.value = !isExpanded.value;
  if (!isExpanded.value) {
    menuFiltersEl.value?.children?.[0]?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}
</script>

<template>
  <div class="flex max-h-[--filters-max-height] flex-col gap-3">
    <div v-if="activeFilter?.title">
      <VChip
        :color="TOKENS.LsdsAColorSurfaceEmphasisSelected"
        size="sm"
        class="tag mb-0 mt-2 px-3 py-1 text-body-1 font-semibold text-primary"
        closable
        @click:close="emit('clear')"
      >
        {{ t('Saved filter') + ': ' + activeFilter?.title }}
      </VChip>
    </div>
    <template v-if="chips.length">
      <div class="flex max-h-36 shrink-0 flex-wrap gap-2 overflow-auto">
        <FilterActiveChip v-for="(chip, index) in chips" :key="index" :chip="chip" />
      </div>
      <hr class="-mx-6 my-2 border-b" />
      <div class="flex gap-5 text-body-1 font-semibold text-default">
        <div class="w-72">{{ t('Add more filters') }}:</div>
        <div v-if="hasQuickFilters">{{ t('Quick filters') }}:</div>
      </div>
    </template>
    <div class="flex overflow-hidden">
      <WidgetScrollMask
        ref="menuFiltersRef"
        class="flex min-h-0 w-72 flex-col gap-3 pb-1"
        :shouldDisableMasking="!isExpanded"
        :class="isExpanded ? 'max-h-96' : 'max-h-64 !overflow-hidden'"
      >
        <slot name="menuFilters" />
      </WidgetScrollMask>
      <div v-if="hasQuickFilters" class="ml-5 flex flex-col gap-3">
        <slot name="menuQuickFilters" />
      </div>
    </div>
    <LscButton
      v-if="isExpandable"
      variant="plain-secondary"
      size="md"
      :prependIcon="!isExpanded ? 'lsi-dropdown' : 'lsi-sort-table'"
      @click="toggleMoreOptions"
    >
      {{ isExpanded ? t('Less options') : t('More options') }}
    </LscButton>
    <slot name="menuOptions" />
    <div v-if="allowSaveFilters" class="flex flex-wrap justify-between gap-2">
      <LscButton
        variant="plain-primary"
        :data-identifier="`${dataIdentifierPrefix}-saved-filters-button`"
        prependIcon="lsi-save"
        @click="emit('showSavedFilters')"
      >
        {{ t('Saved filters') }}
      </LscButton>
      <div>
        <LscButton v-if="activeFilter?.id" variant="primary" size="md" @click="$emit('update')">
          {{ t('Update filter') }}
        </LscButton>
        <LscButton
          v-else
          variant="primary"
          size="md"
          :data-identifier="`${dataIdentifierPrefix}-save-filter-button`"
          @click="$emit('save')"
        >
          {{ t('Save filter') }}
        </LscButton>
      </div>
    </div>
  </div>
</template>
