import { useExperimentA2408, usePermissions, useRepsV3Loader } from '@/api';
import { useI18n } from '@/util';

const symbol = Symbol('useReps');

export function RepsService() {
  const { t } = useI18n();
  const { isOwnerAdmin } = usePermissions();
  const { isExpA2408Variation, productSpecialistRep } = useExperimentA2408();
  const state = useRepsV3Loader({
    count: computed(() => (isOwnerAdmin.value && !isExpA2408Variation.value ? Infinity : -1)),
  });

  const repRoles = {
    csm: 'csm',
    accountExec: 'account-executive',
    accountManager: 'account-manager',
    accountSpecialist: 'account-specialist',
    productSpecialist: 'product-specialist',
  };

  const repTitleMapping = {
    [repRoles.csm]: t('Customer Success Manager'),
    [repRoles.accountExec]: t('Account Executive'),
    [repRoles.accountManager]: t('Account Manager'),
    [repRoles.accountSpecialist]: t('Account Specialist'),
    [repRoles.productSpecialist]: t('Product Specialist'),
  };

  const accountAssociatedReps = computed(() => {
    if (isExpA2408Variation.value) {
      return {
        productSpecialist: productSpecialistRep.value,
      };
    }

    if (!state.items.value.length) {
      return {
        accountExec: null,
        csm: null,
        accountManager: null,
        accountSpecialist: null,
      };
    }
    return {
      accountExec: state.items.value.find((rep) => rep.role === repRoles.accountExec),
      csm: state.items.value.find((rep) => rep.role === repRoles.csm),
      accountManager:
        state.items.value.find((rep) => rep.role === repRoles.accountManager) ||
        state.items.value.find((rep) => rep.role === repRoles.accountExec),
      accountSpecialist: state.items.value.find((rep) => rep.role === repRoles.accountSpecialist),
    };
  });

  const isAssignedReps = computed(() => state.items.value.length > 0 || isExpA2408Variation.value);

  return {
    accountAssociatedReps,
    isAssignedReps,
    repRoles,
    repTitleMapping,
  };
}

export function provideReps() {
  provide(symbol, RepsService());
}

/**
 * Provides account level teamwork reps.
 * @type {RepsService}
 */
export function useReps() {
  return inject(symbol);
}
