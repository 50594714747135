<script setup>
import { useIntersectionObserver } from '@vueuse/core';
import { DateTime, Interval } from 'luxon';
import { isSameMonth } from '@/util';
import WidgetMenu from '../../overlay/menu/WidgetMenu.vue';
import { useDatePicker } from './useDatePicker';

const modelValue = defineModel({ type: Boolean, default: false });

const VISIBLE_MONTHS_COUNT = 5 * 12;

const { visibleMonth, minDate, maxDate } = useDatePicker();
const monthRefs = shallowRef([]);
const startRef = shallowRef();
const endRef = shallowRef();
const shouldEnableIntersectionObservers = shallowRef(false);

const computedStartRef = computed(() => (shouldEnableIntersectionObservers.value ? startRef.value : null));
const computedEndRef = computed(() => (shouldEnableIntersectionObservers.value ? endRef.value : null));

function isSelected(date) {
  return isSameMonth(visibleMonth.value, date);
}

/**
 * Grab the months around this month & set it to the months ref
 * Cap it at the minDate and maxDate if they exist
 * @param {DateTime} month
 * @returns {DateTime[]}
 */
function getMonthsAroundMonth(month) {
  const idealStartMonth = month.startOf('month').minus({ months: VISIBLE_MONTHS_COUNT / 2 });
  const idealEndMonth = month.startOf('month').plus({ months: VISIBLE_MONTHS_COUNT / 2 });

  const startMonth = minDate.value ? DateTime.max(minDate.value, idealStartMonth) : idealStartMonth;
  const endMonth = maxDate.value ? DateTime.min(maxDate.value, idealEndMonth) : idealEndMonth;

  return Interval.fromDateTimes(startMonth, endMonth)
    .splitBy({ months: 1 })
    .map(({ start }) => start.startOf('month'));
}

const months = shallowRef([]);

let handle = null;

function scrollToMonth(month) {
  cancelAnimationFrame(handle);
  handle = requestAnimationFrame(() => {
    handle = requestAnimationFrame(() => {
      handle = requestAnimationFrame(() => {
        const foundMonth = monthRefs.value?.find((ref) => Number(ref.dataset.ts) === month.ts);
        foundMonth?.scrollIntoView(true);
      });
    });
  });
}

useIntersectionObserver(computedStartRef, (entries) => {
  if (entries.at(-1).isIntersecting) {
    const startMonth = months.value.at(0);
    // Cache the start month before changing the months
    months.value = getMonthsAroundMonth(startMonth);
    scrollToMonth(startMonth);
  }
});

useIntersectionObserver(computedEndRef, (entries) => {
  if (entries.at(-1).isIntersecting) {
    // Cache the end month before changing the months
    const endMonth = months.value.at(-1);
    months.value = getMonthsAroundMonth(endMonth);
    scrollToMonth(endMonth);
  }
});

async function scrollToSelection(isVisible) {
  if (isVisible) {
    // Set the initial months to be displayed
    months.value = getMonthsAroundMonth(visibleMonth.value);
    // Wait for the next tick to ensure the months are rendered
    await nextTick();
    // Scroll to the visible month
    scrollToMonth(visibleMonth.value);
    //  Then enable the intersection observers
    setTimeout(() => {
      shouldEnableIntersectionObservers.value = true;
    }, 50);
  } else {
    shouldEnableIntersectionObservers.value = false;
  }
}
</script>

<template>
  <WidgetMenu v-model="modelValue" @update:modelValue="scrollToSelection">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <LscSheet class="max-h-80 w-48 overflow-auto !p-2">
      <div ref="startRef" class="size-px" />
      <div class="flex flex-col items-stretch gap-2">
        <button
          v-for="month in months"
          :key="month.ts"
          ref="monthRefs"
          :data-ts="month.ts"
          type="button"
          :aria-selected="isSelected(month)"
          class="group/LscDatePickerCalendarMonthsMenuButton scroll-mt-px appearance-none rounded-md p-2 hover:bg-surface-hover aria-selected:bg-surface-emphasis-selected aria-selected:font-semibold aria-selected:text-on-emphasis"
          @click="visibleMonth = month"
        >
          <div class="flex items-center justify-between text-body-1">
            <LscOverflowEllipsis>
              {{ month.toLocaleString({ month: 'long', year: 'numeric' }) }}
            </LscOverflowEllipsis>
            <LscIcon
              class="text-icon-on-emphasis opacity-0 transition-opacity group-aria-selected/LscDatePickerCalendarMonthsMenuButton:opacity-100"
              size="sm"
              icon="lsi-selected"
            />
          </div>
        </button>
      </div>
      <div ref="endRef" />
    </LscSheet>
  </WidgetMenu>
</template>
