<script setup>
import { useI18n } from '@/util';
import LscDatePickerRangeInput from './LscDatePickerRangeInput.vue';
import { useDatePicker } from './useDatePicker';

const {
  dataTestIdPrefix,
  dates,
  endDateLabel,
  keepFocus,
  rangeMode,
  selectDates,
  shouldShowStartDateField,
  showStartDateField,
  startDateLabel,
} = useDatePicker();
const { t } = useI18n();

const computedStartDateLabel = computed(() => startDateLabel.value ?? t('Start date'));
const computedEndDateLabel = computed(() => endDateLabel.value ?? t('End date'));

setTimeout(keepFocus);
</script>

<template>
  <div class="relative grid grid-cols-2 gap-2">
    <LscDatePickerRangeInput
      v-if="shouldShowStartDateField"
      ref="startDateInputRef"
      :modelValue="dates?.[0]"
      inputMode="start"
      :label="computedStartDateLabel"
      :data-test-id="dataTestIdPrefix ? `${dataTestIdPrefix}-start-date-input` : undefined"
      @update:modelValue="selectDates($event, 'start')"
      @focus="rangeMode = 'start'"
      @blur="keepFocus"
    />
    <div v-else class="flex items-center justify-center">
      <LscButton variant="plain-secondary" prependIcon="lsi-add" @click="showStartDateField">
        {{ t('Start date') }}
      </LscButton>
    </div>
    <LscDatePickerRangeInput
      :modelValue="dates?.[1]"
      inputMode="end"
      :label="computedEndDateLabel"
      :data-test-id="dataTestIdPrefix ? `${dataTestIdPrefix}-due-date-input` : undefined"
      @update:modelValue="selectDates($event, 'end')"
      @focus="rangeMode = 'end'"
      @blur="keepFocus"
    />
  </div>
</template>
