<script setup>
import { ProjectAddPeopleDialog } from '@/module/project';
import { UserAddYourTeamDialog } from '@/module/user';
import LswAssigneePickerActivator from './LswAssigneePickerActivator.vue';
import LswAssigneePickerMenuCard from './LswAssigneePickerMenuCard.vue';
import { provideAssigneePicker } from './useAssigneePicker';

const props = defineProps({
  /**
   * The size of the avatar in the activator.
   * @type {PropType<typeof LscAvatarSizes[number]>}
   */
  avatarSize: {
    type: String,
    default: 'sm',
  },
  /**
   * Whether the assignee picker should be clearable.
   * @type {PropType<Boolean>}
   */
  clearable: {
    type: Boolean,
    default: true,
  },
  /**
   * The data-test-id prefix for the assignee picker.
   * @type {PropType<String>}
   */
  dataTestIdPrefix: {
    type: String,
    default: null,
  },
  /**
   * Whether the assignee picker should be editable.
   * @type {PropType<Boolean>}
   */
  editable: {
    type: Boolean,
    default: true,
  },
  /**
   * The user IDs that should be excluded from the assignee picker.
   * @type {PropType<number[]>}
   */
  excludeUserIds: {
    type: Array,
    default: () => [],
  },
  /**
   * Whether the assignee picker should show client users.
   * @type {PropType<Boolean>}
   */
  includeClientUsers: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the assignee picker should show collaborators.
   * @type {PropType<Boolean>}
   */
  includeCollaborators: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the assignee picker should show companies.
   * @type {PropType<Boolean>}
   */
  includeCompanies: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the assignee picker should show company teams.
   * @type {PropType<Boolean>}
   */
  includeCompanyTeams: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the assignee picker should show observers.
   * @type {PropType<Boolean>}
   */
  includeObservers: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the assignee picker should show project teams.
   * @type {PropType<Boolean>}
   */
  includeProjectTeams: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the assignee picker should allow assigning roles.
   * @type {PropType<Boolean>}
   */
  includeRoles: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the assignee picker should show subteams.
   * @type {PropType<Boolean>}
   */
  includeSubteams: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the assignee picker should show teams.
   * @type {PropType<Boolean>}
   */
  includeTeams: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the 'anyone' option is active. This can have custom logic applied to it.
   * @type {PropType<(assignees: { id: number, entityType: 'user'|'team' }[]) => boolean>}
   */
  isAnyoneActiveFunction: {
    type: Function,
    default: (assignees) => assignees.length === 0,
  },
  /**
   * The location of the assignee picker in relation to the activator.
   * @type {PropType<string>}
   */
  location: {
    type: String,
    default: 'bottom start',
  },
  /**
   * The company IDs that should be locked down.
   * @type {PropType<number[]>}
   */
  lockdownCompanyIds: {
    type: Array,
    default: () => [],
  },
  /**
   * The ID of the entity that should be locked down.
   * @type {PropType<number|undefined>}
   */
  lockdownId: {
    type: Number,
    default: undefined,
  },
  /**
   * The team IDs that should be locked down.
   * @type {PropType<number[]>}
   */
  lockdownTeamIds: {
    type: Array,
    default: () => [],
  },
  /**
   * The user IDs that should be locked down.
   * @type {PropType<number[]>}
   */
  lockdownUserIds: {
    type: Array,
    default: () => [],
  },
  /**
   * The assign mode for the assignee picker.
   * - 'toggle' (default): Clicking the assignee picker toggles the selection
   * - 'reassign': Clicking the assignee picker reassigns the selection to the new assignee. You can still toggle the selection by clicking the + button.
   * @type {PropType<'reassign'|'toggle'>}
   */
  mode: {
    type: String,
    default: 'toggle',
  },
  /**
   * Whether the assignee picker should allow multiple assignees.
   * @type {PropType<Boolean>}
   */
  multiple: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the assignee picker should only show people from the owner company.
   * @type {PropType<Boolean>}
   */
  onlyOwnerCompany: {
    type: Boolean,
    default: false,
  },
  /**
   * Filters the assignee picker to only show people from the specified project.
   * @type {PropType<number>}
   */
  projectId: {
    type: Number,
    default: 0,
  },
  /**
   * Whether the assignee picker should show the 'anyone' option.
   * @type {PropType<Boolean>}
   */
  showAnyone: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the assignee picker should allow assigning roles.
   * @type {PropType<Boolean>}
   */
  showAssignRoles: {
    type: Boolean,
    default: false,
  },
  /**
   * The ID of the task that the assignee picker is for.
   * @type {PropType<number>}
   */
  taskId: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['invitePeople']);
const modelValue = defineModel({ type: Boolean, default: false });

/**
 * The selected assignees.
 * @type {ModelRef<{ id: Number, entityType: 'user'|'team' }[]>}
 */
const assignees = defineModel('assignees', {
  type: Array,
  default: () => [],
});

const dialogName = shallowRef('');
const isDialogOpen = shallowRef(false);
const preventParentMenuClose = shallowRef(false);

function invitePeople() {
  emit('invitePeople');
  dialogName.value = 'UserAddYourTeamDialog';
  isDialogOpen.value = true;
}

function addPeople() {
  dialogName.value = 'ProjectAddPeopleDialog';
  isDialogOpen.value = true;
}

const { avatars } = provideAssigneePicker({
  modelValue,
  assignees,
  preventParentMenuClose,
  clearable: computed(() => props.clearable),
  dataTestIdPrefix: computed(() => props.dataTestIdPrefix),
  excludeUserIds: computed(() => props.excludeUserIds),
  includeClientUsers: computed(() => props.includeClientUsers),
  includeCollaborators: computed(() => props.includeCollaborators),
  includeCompanies: computed(() => props.includeCompanies),
  includeCompanyTeams: computed(() => props.includeCompanyTeams),
  includeObservers: computed(() => props.includeObservers),
  includeProjectTeams: computed(() => props.includeProjectTeams),
  includeRoles: computed(() => props.includeRoles),
  includeSubteams: computed(() => props.includeSubteams),
  includeTeams: computed(() => props.includeTeams),
  isAnyoneActiveFunction: computed(() => props.isAnyoneActiveFunction),
  lockdownCompanyIds: computed(() => props.lockdownCompanyIds),
  lockdownId: computed(() => props.lockdownId),
  lockdownTeamIds: computed(() => props.lockdownTeamIds),
  lockdownUserIds: computed(() => props.lockdownUserIds),
  mode: computed(() => props.mode),
  multiple: computed(() => props.multiple),
  onlyOwnerCompany: computed(() => props.onlyOwnerCompany),
  projectId: computed(() => props.projectId),
  showAnyone: computed(() => props.showAnyone),
  showAssignRoles: computed(() => props.showAssignRoles),
  taskId: computed(() => props.taskId),
});
</script>

<template>
  <WidgetMenu
    v-model="modelValue"
    v-bind="$attrs"
    :location="location"
    :preventParentMenuClose="preventParentMenuClose"
    :closeOnContentClick="false"
    offset="8"
    :disabled="!editable"
  >
    <template #activator="activator">
      <slot
        name="activator"
        v-bind="activator"
        :data-test-id="dataTestIdPrefix && `${dataTestIdPrefix}-assignee-icon`"
        :avatars="avatars"
        :multiple="multiple"
        :clearable="clearable"
        :editable="editable"
        :projectId="projectId"
        :taskId="taskId"
      >
        <LswAssigneePickerActivator
          v-bind="activator.props"
          :dataTestIdPrefix="dataTestIdPrefix"
          :size="avatarSize"
          :avatars="avatars"
          :disabled="!editable"
        />
      </slot>
    </template>

    <LswAssigneePickerMenuCard @invitePeople="invitePeople" @addPeople="addPeople">
      <template v-for="(_, slot) of $slots" #[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
    </LswAssigneePickerMenuCard>
  </WidgetMenu>

  <LscSlotSwitch :name="dialogName">
    <template #UserAddYourTeamDialog>
      <UserAddYourTeamDialog v-model="isDialogOpen" panelSource="assignee_picker" />
    </template>
    <template #ProjectAddPeopleDialog>
      <ProjectAddPeopleDialog v-model="isDialogOpen" :project="{ id: projectId }" />
    </template>
  </LscSlotSwitch>
</template>
