<script setup>
import * as TOKENS from '@teamwork/lightspeed-design-tokens';
import { sanitizeUrl, useI18n } from '@/util';

const props = defineProps({
  update: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

function getTypeDetails(type) {
  switch (type) {
    case 'announcement':
      return { icon: 'lsi-announcement', color: TOKENS.LsdsAColorDecorative2 };
    case 'beta':
      return { icon: 'lsi-beta', color: TOKENS.LsdsAColorDecorative6 };
    case 'bug':
      return { icon: 'lsi-bug', color: TOKENS.LsdsAColorDecorative4 };
    case 'enhancement':
      return { icon: 'lsi-maintenance', color: TOKENS.LsdsAColorDecorative1 };
    case 'feature':
      return { icon: 'lsi-feature', color: TOKENS.LsdsAColorDecorative7 };
    case 'fix':
      return { icon: 'lsi-bug', color: TOKENS.LsdsAColorDecorative4 };
    case 'maintenance':
      return { icon: 'lsi-maintenance', color: TOKENS.LsdsAColorDecorative1 };
    case 'tips':
      return { icon: 'lsi-tips', color: TOKENS.LsdsAColorDecorative5 };
    case 'webinar':
      return { icon: 'lsi-announcement', color: TOKENS.LsdsAColorDecorative1 };
    default:
      return { icon: 'lsi-announcement', color: TOKENS.LsdsAColorDecorative1 };
  }
}

function getLinkText(linkText) {
  if (linkText) {
    return linkText;
  }
  // If no link text on returned item, set a random link text from below list
  const linkTextOptions = [t('Check it out'), t('Have a look'), t('Tell me more'), t('Learn more'), t('To our blog')];
  return linkTextOptions[Math.floor(Math.random() * linkTextOptions.length)];
}
const typeDetails = getTypeDetails(props.update.type);
</script>

<template>
  <div class="flex flex-col rounded-md p-6">
    <div class="mb-4 flex flex-row items-center">
      <LscLabel size="lg" :color="typeDetails.color" variant="flag" :prependIcon="typeDetails.icon">
        {{ update.type }}
      </LscLabel>
      <div class="ml-auto px-2 text-subtle">
        {{ update.updateTime?.toRelative() }}
      </div>
    </div>
    <span class="mb-2 font-semibold">
      {{ update.title }}
    </span>
    <span class="mb-2">{{ update.text }}</span>
    <LscLink :href="sanitizeUrl(update.link)" target="_blank">
      {{ getLinkText(update.linkText) }}
    </LscLink>
  </div>
</template>
