<script setup>
import { LscTabVariants } from './constants';
import { provideLscTabs } from './useLscTabs';

const props = defineProps({
  /**
   * The variant of the tabs.
   * @type {PropType<typeof LscTabVariants[number]>}
   */
  variant: {
    type: String,
    default: 'primary',
    validator: (value) => LscTabVariants.includes(value),
  },
  /**
   * Whether the tabs should be stretched to fill the container.
   * Only use this when the tab count is 5 or less.
   * @type {Boolean}
   */
  justified: {
    type: Boolean,
    default: false,
  },
});

provideLscTabs({
  variant: computed(() => props.variant),
  justified: computed(() => props.justified),
});

const lscTabsVariantStyleConfig = tv({
  base: 'inline-flex items-center',
  variants: {
    variant: {
      secondary: 'gap-1',
      segmented: 'gap-1 rounded-md bg-surface-default p-0.5',
    },
  },
});

const classes = computed(() => lscTabsVariantStyleConfig(props));
</script>
<template>
  <nav class="LscTabs" role="tablist" :class="classes">
    <slot />
  </nav>
</template>
