<script setup>
import { useSavedfilterActions } from '@/api';
import { useI18n } from '@/util';
import FilterMenuOptions from './FilterMenuOptions.vue';
import FilterSavedFilters from './FilterSavedFilters.vue';
import FilterSavedFiltersAddOrEdit from './FilterSavedFiltersAddOrEdit.vue';
import FilterShareDialog from './FilterShareDialog.vue';
import { useFilter } from './useFilter';

const props = defineProps({
  allowSaveFilters: {
    type: Boolean,
    default: true,
  },
  projectId: {
    type: Number,
    default: 0,
  },
  section: {
    type: String,
    default: undefined,
  },
  hasQuickFilters: {
    type: Boolean,
    default: false,
  },
  savedFilterParams: {
    type: Object,
    default: undefined,
  },
});

const { t } = useI18n();
const toast = useLsToast();
const { activeFilter, count, clear, chips } = useFilter();
const isShareFilterDialogOpen = shallowRef(false);

const { updateSavedfilter, shareSavedfilter } = useSavedfilterActions();
const displaySavedFilters = shallowRef(false);
let shareFilter = null;

// Reference to the saved filter that is currently being edited or created
const targetSavedFilter = shallowRef(null);

// We need this check as some filters contibute to the 'count' but don't have a chip.
const chipsLength = computed(() => chips.value.length);

async function openShareFilterModal(filter) {
  isShareFilterDialogOpen.value = true;
  shareFilter = filter;
}

async function shareFilterToUser(shareFilterData) {
  await shareSavedfilter(shareFilterData.filterID, {
    includeDetails: 0,
    shareToUsers: shareFilterData.userIDs,
  });
  isShareFilterDialogOpen.value = false;
  toast.success(t('Shared Filter sent successfully'));
}

async function shareFilterTitle(shareTitleData) {
  await shareSavedfilter(shareTitleData.filterID, {
    savedFilter: {
      displayOrder: 0,
      includesSort: false,
      isProjectSpecific: false,
      projectId: 0,
      section: props.section,
      sharedState: shareTitleData.shareTitle ? 0 : 1,
    },
    updateDefault: true,
  });
}

function createFilter() {
  targetSavedFilter.value = {
    projectId: props.projectId,
    section: props.section,
    parameters: activeFilter.value.parameters,
  };
}

async function updateFilter() {
  await updateSavedfilter({
    id: activeFilter.value?.id,
    parameters: activeFilter.value.parameters,
  });
  toast.success(t('Filter updated'));
}
</script>

<template>
  <WidgetMenu :eager="true" v-bind="$attrs" preventParentMenuClose>
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <LscSheet
      class="flex flex-col items-stretch !overflow-x-hidden !px-5 !pb-5 !pt-4"
      :class="
        hasQuickFilters ? '!w-[--filter-dropdown-wrapper-width-quick-filter]' : '!w-[--filter-dropdown-wrapper-width]'
      "
    >
      <div class="mb-2 flex items-center justify-between">
        <div class="text-body-1 font-semibold text-default">
          <template v-if="count">
            <div class="flex gap-5">
              <div :class="{ 'w-72': hasQuickFilters }">
                <template v-if="targetSavedFilter">
                  {{ targetSavedFilter.id ? t('Update saved filter') : t('Create saved filter') }}
                </template>
                <template v-else-if="displaySavedFilters">
                  {{ t('Saved filters') }}
                </template>
                <template v-else>{{ t('Selected filters') }}:</template>
              </div>
              <div v-if="hasQuickFilters && chipsLength === 0" class="flex flex-col gap-3">
                {{ t('Quick filters') }}:
              </div>
            </div>
          </template>
          <template v-else-if="displaySavedFilters">
            <template v-if="targetSavedFilter">
              {{ targetSavedFilter.id ? t('Update saved filter') : t('Create saved filter') }}
            </template>
            <template v-else>
              {{ t('Saved filters') }}
            </template>
          </template>
          <template v-else>
            <div class="flex gap-5">
              <div :class="{ 'w-72': hasQuickFilters }">{{ t('Choose filters') }}:</div>
              <div v-if="hasQuickFilters" class="flex flex-col gap-3">{{ t('Quick filters') }}:</div>
            </div>
          </template>
        </div>
        <LscButton
          v-if="!displaySavedFilters && !targetSavedFilter && count"
          variant="plain-secondary"
          @click.stop="clear"
        >
          {{ t('Clear all') }}
        </LscButton>
        <slot name="menuHeader" />
      </div>

      <FilterMenuOptions
        v-show="!displaySavedFilters && !targetSavedFilter"
        :allowSaveFilters="allowSaveFilters"
        :hasQuickFilters="hasQuickFilters"
        @clear="clear"
        @update="updateFilter"
        @save="createFilter"
        @showSavedFilters="displaySavedFilters = true"
      >
        <template #menuFilters><slot name="menuFilters" /></template>
        <template #menuQuickFilters><slot name="menuQuickFilters" /></template>
        <template #menuOptions><slot name="menuOptions" /></template>
      </FilterMenuOptions>

      <KeepAlive>
        <FilterSavedFilters
          v-if="allowSaveFilters && displaySavedFilters"
          v-show="!targetSavedFilter"
          :projectId="projectId"
          :section="section"
          @back="displaySavedFilters = false"
          @clear="clear"
          @edit="targetSavedFilter = $event"
          @share="openShareFilterModal"
        >
          <template #prepend="{ meta }"><slot name="beforeSavedFiltersList" :meta="meta" /></template>
        </FilterSavedFilters>
      </KeepAlive>

      <FilterSavedFiltersAddOrEdit
        v-if="allowSaveFilters && targetSavedFilter"
        :projectId="projectId"
        :savedFilter="targetSavedFilter"
        @cancel="targetSavedFilter = null"
        @save="
          activeFilter = $event;
          targetSavedFilter = null;
        "
      />
    </LscSheet>
  </WidgetMenu>
  <FilterShareDialog
    v-if="isShareFilterDialogOpen"
    v-model="isShareFilterDialogOpen"
    :shareFilter="shareFilter"
    @close="isShareFilterDialogOpen = false"
    @shareFilterToUser="shareFilterToUser"
    @shareFilterTitle="shareFilterTitle"
  />
</template>
